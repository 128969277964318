import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import { CartHelper, GetCourseDetail, GetUserLogin } from "../../services";
import { API_URL } from "../../../config";
export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailerror: "",
      passworderror: "",
      jwt: "",
      username: "",
      category: [],
    };
  }
  handleChangeUser(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    let data = { email: this.state.email, password: this.state.password };
    let user;
    try {
      user = await GetUserLogin.getUserLogin(data);
      await GetUserLogin.authenticate({ ...user, email: this.state.email });
    } catch (error) {
      NotificationManager.error(
        error?.response?.data?.message || "Please check your login Details",
        "Login Failed"
      );
    }
  };
  async componentDidMount() {
    let email = localStorage.getItem("email");
    let firstName = localStorage.getItem("firstName");
    let lastName = localStorage.getItem("lastName");
    let jwt = sessionStorage.getItem("token");
    let category = await GetCourseDetail.getAllCategory();
    this.setState({
      username: { firstName, lastName },
      jwt: jwt,
      category: category ?? [],
    });
    localStorage.setItem("category", JSON.stringify(category));
  }
  handleLogout = async (event) => {
    event.preventDefault();
    await GetUserLogin.logout();
  };
  render() {
    let { email, password } = this.state;
    const { username, jwt } = this.state;
    let itemTotal = CartHelper.itemTotal();
    return (
      <div>
        {/* Header Starts */}
        <header className="header_wrap fixed-top light_skin main_menu_uppercase main_menu_weight_500 transparent_header ">
          <div className="container">
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand" href="/">
                <img
                  className="logo_light"
                  src="/assets/images/logo/logo_white.png"
                  alt="Innercord Logo"
                />
                <img
                  className="logo_dark"
                  src="/assets/images/logo/logo_dark.png"
                  alt="Innercord Logo"
                />
                <img
                  className="logo_default"
                  src="/assets/images/logo_dark.png"
                  alt="Innercord Logo"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ion-android-menu" />
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li>
                    <a className="nav-link" href="/">
                      Home
                    </a>
                  </li>
                  {/* <li>
                    <a className="nav-link" href="/memberships">
                      Memberships
                    </a>
                  </li> */}

                  <li className="nav-item dropdown">
                    <a className="nav-link" href="/programs">
                      Programs
                    </a>
                    <ul
                      className="dropdown-menu dropdown-menu-dark"
                      aria-labelledby="navbarDarkDropdownMenuLink"
                    >
                      {this.state.category.map((e) => {
                        if (e.type.toString() === "program") {
                          return (
                            <li>
                              <a
                                className="dropdown-item"
                                href={`/programs?isActive=${e.id}`}
                              >
                                {e.name}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link" href="/consultations">
                      Consultations
                    </a>
                    <ul
                      className="dropdown-menu dropdown-menu-dark"
                      aria-labelledby="navbarDarkDropdownMenuLink"
                    >
                      {this.state.category.map((e) => {
                        if (e.type.toString() === "consultation") {
                          return (
                            <li>
                              <a
                                className="dropdown-item"
                                href={`/consultations?isActive=${e.id}`}
                              >
                                {e.name}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </li>
                  {/* <li>
                    <a className="nav-link" href="/teachers">
                      teachers
                    </a>
                  </li> */}
                  <li>
                    <a className="nav-link" href="/blogs">
                      Blogs
                    </a>
                  </li>

                  {/* <li className="dropdown">
              <a className="dropdown-toggle nav-link" href="#" data-toggle="dropdown">Workshops</a>
              <div className="dropdown-menu">
                <ul>
                  <li><a className="dropdown-item nav-link nav_item" href="/recorded">Recorded</a></li>
                  <li><a className="dropdown-item nav-link nav_item" href="/live-sessions">Live Sessions</a></li>
                </ul>
              </div>
            </li>
            <li className="dropdown">
              <a className="dropdown-toggle nav-link" href="/" data-toggle="dropdown">Masterclass <sup classname="text-primary">Free</sup></a>
              <div className="dropdown-menu">
                <ul>
                  <li><a className="dropdown-item nav-link nav_item" href="/recorded-free">Recorded <sup classname="text-primary">Free</sup></a></li>
                  <li><a className="dropdown-item nav-link nav_item" href="/live-sessions-free">Live Sessions <sup classname="text-primary">Free</sup></a></li>
                </ul>
              </div>
            </li> */}

                  {/* <li>
                    <a className="nav-link" href="/blogs">Blog</a>
                  </li> */}

                  <li>
                    <a className="nav-link" href="/about">
                      About Us
                    </a>
                  </li>
                </ul>
              </div>

              <ul className="navbar-nav attr-nav align-items-center">
                <li>
                  <button
                    className="btn btn-account"
                    data-toggle="modal"
                    data-target="#loginModal"
                    type="button"
                    style={jwt ? { display: "none" } : { display: "block" }}
                  >
                    <i class="fa fa-user fa-fw"></i> Login
                  </button>

                  <div
                    className="dropdown"
                    style={jwt ? { display: "block" } : { display: "none" }}
                  >
                    <button
                      className="btn btn-account dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {"Hi, " + username.firstName}
                    </button>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="/my-courses">
                        My Courses
                      </a>
                      <a className="dropdown-item" href="/profile">
                        My Profile
                      </a>
                      <a className="dropdown-item" href="/change-password">
                        Change Password
                      </a>
                      <span
                        className="dropdown-item"
                        onClick={this.handleLogout}
                      >
                        Logout
                      </span>
                    </div>
                  </div>
                </li>

                {itemTotal >= 1 ? (
                  <a href="/cart" className="cart-icon">
                    <i class="fa fa-shopping-cart"></i> <sup>{itemTotal}</sup>{" "}
                  </a>
                ) : (
                  ""
                )}
              </ul>
            </nav>
          </div>
        </header>

        <div
          className="modal fade"
          id="loginModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <img
                  className="modal-logo"
                  src="/assets/images/logo/logo_dark.png"
                  alt="Innercord Logo"
                />
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="d-flex flex-column ">
                  <form>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email1"
                        placeholder="Your email address..."
                        name="email"
                        value={email}
                        onChange={(e) => this.handleChangeUser(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="password1"
                        placeholder="Your password..."
                        name="password"
                        value={password}
                        onChange={(e) => this.handleChangeUser(e)}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-default w-100"
                      onClick={this.handleSubmit}
                    >
                      Login
                    </button>
                    {/* <div className="d-inline-flex  flex-column w-100 justify-content-center align-items-center mt-1 p-0">
                      <div className="google-btn m-0 p-0">
                        <div className="google-icon-wrapper m-0 p-0">
                          <a href={`${API_URL}/v1/auth/google`}>
                            <img
                              className="google-icon"
                              src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                            />
                          </a>
                        </div>
                      </div>
                      <p class="btn-text">
                        <b>Sign in with google</b>
                      </p>
                    </div> */}

                    <div className="d-inline-flex flex-column w-100 justify-content-center align-items-center mt-3 p-0">
                      <a
                        className="btn"
                        href={`${API_URL}/v1/auth/google`}
                        role="button"
                        style={{
                          textTransform: "none",
                          boxShadow: ".3s",
                          padding: "12px 42px 12px 42px",
                          border: "none",
                          borderRadius: "3px",
                          boxShadow:
                            "0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25)",
                          color: "#757575",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        <img
                          width="20px"
                          style={{ marginBottom: "3px", marginRight: "5px" }}
                          alt="Google sign-in"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                        />
                        Login with Google
                      </a>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-center text-center">
                <div className="signup-section">
                  Not a member yet?
                  <a href="/register" className="text-success font-weight-bold">
                    Sign Up
                  </a>
                  <br></br>
                  <a
                    href="/forgot-password"
                    className="text-secondary font-weight-bold"
                  >
                    Forgot Password?{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Header Ends */}
      </div>
    );
  }
}
