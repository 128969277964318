import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

export default class About extends Component {
    render() {
        return (
            <div>
              
  {/* --------------------- About Page Starts --------------------------*/}
 
  <MetaTags>
    <title>About Us | Innercord - Europe</title>
    <meta name="description" content="Innercord plays an integral role in bringing a positive change in your life. You learn the art of balancing, sustaining and transforming your life with us." />
  </MetaTags>
  
<section className="breadcrumb-bg lead-hide">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-sm-12 text-center">
        <div className="page-title">
          <h1>About Us</h1>
          <p className="col-sm-6 mx-auto">Allow us to strike an innercord with you. We will help you strike an inner-balance with yourself.</p>
        </div>
      </div>
    </div>
  </div>
</section>


{/* START SECTION ABOUT */}
<section className="pt-0">

  <div className="container">

      <div className="row align-items-center abt-blocks abt-banner">
         
         <img src="/assets/images/banners/abt-header.jpg"/>
      
      
      <div className="col-md-7 animation pt-2" data-animation="fadeInUp" data-animation-delay="0.3s">
        <div className="heading_s1 ">
          <h2>Our Passion</h2>
        </div>

        <p className="col-sm-10 p-0"> Innercord plays an integral role in bringing a positive change in your life. You can learn the act of balancing, sustaining and transforming your life with us. </p>
        <p className="col-sm-10 p-0">With interactive workshops, we also give expert consultation in the fields of Ayurveda, Vedic Astrology, Yoga, Mindfulness, and Meditation. </p>
        <p className="col-sm-10 p-0"> We are driven by the passion to create a sustainable balance between healthy living and the ever-evolving world.</p>
       
   
      </div>

</div>




    <div className="row align-items-center abt-blocks">

    <div className="col-md-6 animation" data-animation="fadeInUp" data-animation-delay="0.2s">
          <img src="/assets/images/banners/abt-team.jpg" alt="about_img11" />
      </div>

    <div className="col-md-6 animation" data-animation="fadeInUp" data-animation-delay="0.3s">
        <div className="heading_s1 mt-4">
         
        <h2>Our Mentors</h2>
        </div>

        <p>With an aim to restore the balance of living in an ever-busy world, our mentors bring forth not just infinite wisdom, but also guidance on how each practice or routine can be applied in your daily life.</p>
        <p>They do extensive research on a regular basis to evolve their own ways of inner health and wellness. Thus, creating a path for you to mindful living. </p>
       <p>Every workshop is designed in an interactive and simple manner to make the covered subjects easy to understand, even to beginners. </p>
         <p>These mentors believe in creating strong bonds and developing inner connections to make your wisdom sustain for a lifetime. </p>
          
   
      </div>

      
      
    </div>



    <div className="row align-items-center abt-blocks mb-0">
      
      <div className="col-md-6 animation" data-animation="fadeInUp" data-animation-delay="0.3s">
        <div className="heading_s1">
        
        <h2>Our Guiding Principles</h2>
        </div>
        <p>Our three guiding principles are Learn. Sustain. Transform. </p>
        <p>In the age of exhaustible physical products, timeless knowledge is what we offer. We set our sights on learning, sustaining and transforming your inner self. </p>
        <p>Our guiding principles help us to achieve this balance between your inner voice and the influences that help you connect with the outside world. </p>
        <p>Innercord aims to bring back the light of wholesome living on you with the help of our mentors and respective principles. </p>
      </div>

      <div className="col-md-6 animation" data-animation="fadeInUp" data-animation-delay="0.2s">
             <img src="/assets/images/banners/abt-cult.jpg" alt="about_img11" />
      </div>
    </div>







  </div>
</section>
{/* END SECTION ABOUT */}




 
  {/* --------------------- About Page Ends --------------------------*/}
    
            </div>
        )
    }
}
