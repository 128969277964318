import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import { GetUserLogin } from "../../../../services";
import { API_URL } from "../../../../../config";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      formErrors: {
        email: "",
        password: "",
      },
    };
  }
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "invalid email address";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "minimum 6 characaters required" : "";
        break;
      default:
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let { email, password } = this.state;
    let data = { email: email, password: password };
    if (formValid(this.state)) {
      let user = await GetUserLogin.getUserLogin(data);
      if (user) {
        await GetUserLogin.authenticateByCart({ ...user, email });
      }
    } else {
      NotificationManager.error("Please check your Login", "Input Error");
    }
  };
  render() {
    let { email, password, formErrors } = this.state;
    return (
      <div>
        {/* --------------------- About Page Starts --------------------------*/}

        {/* START SECTION BREADCRUMB */}
        <section className="breadcrumb-bg lead-hide">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title">
                  <h1>Sign In</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION BREADCRUMB */}

        {/* START SECTION ABOUT */}
        <section className="pt-3">
          <div className="container">
            <div className="col-sm-4 mx-auto form-main-wrap">
              <div className="field_form icon_form">
                <form onSubmit={this.handleSubmit} noValidate>
                  <h6 className="mb-4">Please fill the details below !</h6>

                  <div className="form-group">
                    <label>Email</label>
                    <div className="form-input">
                      <span>
                        <i className="ti-email" />
                      </span>
                      <input
                        required="required"
                        placeholder="Email"
                        id="email"
                        className="form-control"
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                      />
                    </div>
                    {formErrors.email.length > 0 && (
                      <span className="errorMessage">{formErrors.email}</span>
                    )}
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <div className="form-input">
                      <span>
                        <i className="ti-lock" />
                      </span>
                      <input
                        placeholder="Password"
                        id="subject"
                        className="form-control"
                        name="password"
                        type="password"
                        value={password}
                        onChange={this.handleChange}
                      />
                    </div>
                    {formErrors.password.length > 0 && (
                      <span className="errorMessage">
                        {formErrors.password}
                      </span>
                    )}
                  </div>

                  <button
                    type="submit"
                    title="Submit Your Message!"
                    className="btn btn-default w-100"
                    id="submitButton"
                    name="submit"
                    value="Submit"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </button>
                  {/* <div className="d-inline-flex  flex-column w-100 justify-content-center align-items-center mt-1 p-0">
                    <div className="google-btn m-0 p-0">
                      <div className="google-icon-wrapper m-0 p-0">
                        <a href={`${API_URL}/v1/auth/google`}>
                          <img
                            className="google-icon"
                            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                          />
                        </a>
                      </div>
                    </div>
                    <p class="btn-text">
                      <b>Sign in with google</b>
                    </p>
                  </div> */}

                  {/* <button
                    type="button"
                    style={{
                      boxShadow: ".3s",
                      padding: "12px 16px 12px 42px",
                      border: "none",
                      borderRadius: "3px",
                      boxShadow:
                        "0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25)",
                      color: "#757575",
                      fontSize: "14px",
                      fontWeight: 500,
                      backgroundImage:
                        "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=)",
                      backgroundColor: "white",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "12px 11px",
                      textAlign: "center",
                    }}
                  >
                    Sign in with Google
                  </button> */}
                  {/* <div class="row">
                    <div class="col-md-3">
                      <a
                        class="btn btn-outline-primary"
                        href="/users/googleauth"
                        role="button"
                        style={{ textTransform: "none" }}
                      >
                        <img
                          width="20px"
                          style={{ marginBottom: "3px", marginRight: "5px" }}
                          alt="Google sign-in"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                        />
                        Login with Google
                      </a>
                    </div>
                  </div> */}

                  <div className="d-inline-flex flex-column w-100 justify-content-center align-items-center mt-3 p-0">
                    <a
                      className="btn"
                      href={`${API_URL}/v1/auth/google`}
                      role="button"
                      style={{
                        textTransform: "none",
                        boxShadow: ".3s",
                        padding: "12px 42px 12px 42px",
                        border: "none",
                        borderRadius: "3px",
                        boxShadow:
                          "0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25)",
                        color: "#757575",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      <img
                        width="20px"
                        style={{ marginBottom: "3px", marginRight: "5px" }}
                        alt="Google sign-in"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                      />
                      Login with Google
                    </a>
                  </div>

                  <div className="signup-section mt-4">
                    Not a member yet?{" "}
                    <a href="/register" class="text-success font-weight-bold">
                      {" "}
                      Sign Up
                    </a>
                  </div>

                  <div className="col-lg-12 text-center">
                    <div id="alert-msg" className="alert-msg text-center" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION ABOUT */}

        {/* --------------------- About Page Ends --------------------------*/}
      </div>
    );
  }
}
