import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { GetMasterClassDetails } from "../../../../services";

export default class Mentors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: true,
    };
  }
  async componentDidMount() {
    try {
      let list = await GetMasterClassDetails.getAllMentors();
      console.log(list);
      this.setState({ list: list, loading: false });
    } catch (error) {}
  }
  render() {
    return (
      <div>
        <MetaTags>
          <title>Our Mentors | Innercord</title>
          <meta
            name="description"
            content="Get direct access to our experienced and ‘ready to help’ mentors to assist you in learning Ayurveda, Vedic Astrology, Yoga and Meditation."
          />
        </MetaTags>

        {/* --------------------- Contact Page Starts --------------------------*/}

        {/* START SECTION BREADCRUMB */}

        <section className="breadcrumb-bg lead-hide">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title">
                  <h1>Our Mentors</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION BREADCRUMB */}

        <div className="container">
          <img
            className="rounded-corner"
            src="/assets/images/banners/teachers.png"
            alt="teacher"
          />
        </div>

        <h5 className="col-sm-8 mx-auto mt-4 text-center teach-txt">
          " Get direct access to the experienced and ‘ ready to help’ mentors to
          assist you in learning Ayurveda, Vedic Astrology, Yoga and Meditation.
          Getting good health along with creating inner happiness has been made
          simpler by them. "
        </h5>

        {/* START SECTION CONTACT */}
        {this.state.loading ? (
          <div
            className="d-inline-flex w-100 align-items-center justify-content-center"
            style={{ minHeight: "20em" }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {this.state.list.map((teacher, index) => {
              if (index % 2 == 0)
                return (
                  <section className="">
                    <div className="container">
                      <div className="row teachers-row justify-content-between">
                        <div className="col-lg-5 col-md-6 col-sm-8 teachers-col">
                          <div className="single-teacher-details mt-50 text-center">
                            <div className="teacher-image">
                              <img src={teacher.teacherImage} alt="teacher" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 teachers-col">
                          <div className="teacher-details-content mt-45">
                            <h4 className="teacher-name">
                              {teacher?.firstName} {teacher?.lastName}
                            </h4>
                            {/* <span className="designation">Senior Trainer</span>
                            <span className="department">
                              MBA Marketing, MA Psychology{" "}
                            </span> */}
                            <p>{teacher?.teacherDesc}</p>
                            {/* <ul class="list_none social_icons radius_social">
      <li><a href="#" target="_blank" rel="noopener noreferrer">
        <i class="fab fa-facebook-f"></i></a>
      </li>
      
      <li>
        <a href="#" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i></a>
        </li>
     </ul>
    <ul className="teacher-contact">
      <li><strong>Email:</strong> <a href="#">alok@example.com</a></li>
    </ul> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                );
              else
                return (
                  <section className="bg-light">
                    <div className="container">
                      <div className="row teachers-row justify-content-between">
                        <div className="col-lg-6 teachers-col">
                          <div className="teacher-details-content mt-45">
                            <h4 className="teacher-name">
                              {teacher?.firstName} {teacher?.lastName}
                            </h4>
                            {/* <span className="designation">Senior Trainer</span>
                            <span className="department">
                              Masters in Obstetrics & Gynaecology in Ayurveda{" "}
                            </span> */}
                            <p>{teacher?.teacherDesc}</p>
                            {/* <p>
                              She has been changing the lives of people through
                              her extensive knowledge of Ayurveda,Yoga, Organic
                              Healing for the past 6 years.{" "}
                            </p> */}
                            {/* <ul class="list_none social_icons radius_social">
    <li><a href="#" target="_blank">
      <i class="fab fa-facebook-f"></i></a>
    </li>
    
    <li>
      <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
      </li>
   </ul>
  <ul className="teacher-contact">
    <li><strong>Email:</strong> <a href="#">shruti@example.com</a></li>
  </ul> */}
                          </div>
                        </div>

                        <div className="col-lg-5 col-md-6 col-sm-8 teachers-col">
                          <div className="single-teacher-details mt-50 text-center">
                            <div className="teacher-image">
                              <img src={teacher.teacherImage} alt="teacher" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                );
            })}
          </>
        )}
        {/* END SECTION CONTACT */}

        {/* --------------------- Contact Page Starts --------------------------*/}
      </div>
    );
  }
}

{
  /*



 <section className="">
              <div className="container">
                <div className="row teachers-row justify-content-between">
                  <div className="col-lg-5 col-md-6 col-sm-8 teachers-col">
                    <div className="single-teacher-details mt-50 text-center">
                      <div className="teacher-image">
                        <img
                          src="/assets/images/people/akanksha.jpg"
                          alt="teacher"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 teachers-col">
                    <div className="teacher-details-content mt-45">
                      <h4 className="teacher-name">Akanksha Parashar</h4>
                      <span className="designation">Senior Trainer</span>
                      <span className="department">
                        MBA Marketing, MA Psychology{" "}
                      </span>
                      <p>
                        When she first did the Sudarshan Kriya (S.K.Y) technique
                        and experienced immense peace and joy within, she wanted
                        to spread this joy with everyone. For the last 7 year
                        since then, she has been helping people to relieve
                        stress and create happier lives.{" "}
                      </p>
                      <p>
                        She wants to transform the lives of people with her
                        consultation & knowledge based workshops on Yoga,
                        Mindful Meditation, Health and wellness.{" "}
                      </p>
                       <ul class="list_none social_icons radius_social">
        <li><a href="#" target="_blank">
          <i class="fab fa-facebook-f"></i></a>
        </li>
        
        <li>
          <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
          </li>
       </ul>
      <ul className="teacher-contact">
        <li><strong>Email:</strong> <a href="#">akanksha@example.com</a></li>
      </ul> 
      </div>
      </div>
    </div>
  </div>
</section>
*/
}
