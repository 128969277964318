import CircularProgress from "@material-ui/core/CircularProgress";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MyCourses } from "../../../../services";

export default class My_Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: true,
      page: 1,
      rowsPerPage: 10,
    };
  }
  async componentDidMount() {
    try {
      let { data, pagination } = await MyCourses.getUserWorkshop(
        this.state.page,
        this.state.rowsPerPage
      );
      this.setState({
        list: data || [],
        loading: false,
        page: pagination.page,
      });
    } catch (error) {}
  }
  handleChangePage(event, newPage) {
    this.setState({ page: newPage, loading: true });
    this.handleCallPagination(newPage);
  }

  async handleCallPagination(newPage) {
    try {
      let { data, pagination } = await MyCourses.getUserWorkshop(
        newPage,
        this.state.rowsPerPage
      );
      this.setState({
        list: data || [],
        loading: false,
        page: pagination.page,
      });
    } catch (error) {}
  }

  render() {
    const { list } = this.state;
    return (
      <div>
        <div>
          <section className="breadcrumb-bg lead-hide">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-sm-12 text-center">
                  <div className="page-title page-title-custom">
                    <h1>My Courses</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {this.state.loading ? (
            <div
              className="d-inline-flex w-100 align-items-center justify-content-center"
              style={{ minHeight: "20em" }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div className="container">
              <div className="yg-classes-section pt-4">
                <div className="container">
                  <div className="row">
                    {this.state.list.length === 0 ? (
                      <h5>Currently there are no Workshops</h5>
                    ) : (
                      <>
                        {list.map(({ course: row }, index) => (
                          <div className="col-md-4 col-sm-12" key={index}>
                            <div className="yg-classes-wrap">
                              <span className="category-wrap">
                                <i
                                  className="fa fa-video"
                                  aria-hidden="true"
                                ></i>{" "}
                                Recorded
                              </span>
                              <Link
                                to={{
                                  pathname: `/course-view/${row.id}`,
                                  query: row.id,
                                }}
                              >
                                <img
                                  src={row.thumbnail}
                                  className="img-fluid"
                                  alt={row.title}
                                />
                                <div className="yg-classes-bottom-content">
                                  <div className="yg-classes-title">
                                    <h2>{row.title}</h2>
                                  </div>
                                  <p>{row.description}</p>
                                  Start Learning{" "}
                                  <i
                                    className="fa fa-angle-double-right"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
