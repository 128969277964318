import React, { Component } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CardPay from './CardPay'


const stripePromise = loadStripe('pk_live_51HDU1QAJ8SCDPxTCCAtpZFlkQUTyABaDEwsYWjP1YleHUIqg0FnRjzbe3VhvbysqhVu8ewTCfZPqfv3enSmrClYu00c24g7O40');
export default class StripeCardpayment extends Component {
    render() {
        return (
            <div>
                <Elements stripe={stripePromise} >
                    <CardPay />
                </Elements>
            </div>
        )
    }
}
