import React, { Component } from 'react'
import { GetMasterClassDetails } from '../../../../services';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
export default class Live_Sessions_Free extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [], live: []
    };
  }
  async componentDidMount() {
    let list = await GetMasterClassDetails.getAllMasterLiveSession();
    this.setState({ live: list })
    
  }
  render() {
    const { live } = this.state;
    return (
      <div>


        {/* --------------------- Workshop Page Starts --------------------------*/}

        {/* START SECTION BREADCRUMB */}
        <section className="breadcrumb-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title">
                  <h1>Live Session <span className="text-primary">Masterclass</span></h1>
                  <p className="col-sm-6 mx-auto">Allow us to strike an innercord with you. We will help you strike an inner-balance with yourself.</p>
                </div>

              </div>
            </div>
          </div>
        </section>
        {/* END SECTION BREADCRUMB */}




        <div className="yg-classes-section pt-3">
          <div className="container">

            {
              live.length ? <div className="row">
                {live === undefined ? <CircularProgress /> :
                  live.map((row, index) => (
                    <div className="col-md-6 col-sm-12" key={index}>
                      <Link to={{
                        pathname: `/course-details-free/${row.slug}/${row.id}`,
                        state: row
                      }}>
                        <div className="yg-classes-wrap">
                          <span className="category-wrap"><i class="fa fa-dot-circle"></i> Free Live Session Masterclass</span>
                          <span className="date-wrap"><i class="fa fa-calendar" aria-hidden="true"></i> {moment(row.date).format('LL')}
                          </span>
                          <img src={row.thumbnailUrl} className="img-fluid" alt="thumbnail" />
                          <div className="yg-classes-bottom-content">
                            <div className="yg-classes-title">
                              <h2>{row.courseTitle}</h2>
                            </div>
                            <p>{row.description}</p>
                            <p className="rd-txt"> Reserve My Spot <i class="fa fa-angle-double-right" aria-hidden="true"></i></p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                }

              </div>
                :

                <div className="col-sm-3 mx-auto">
                  <hr></hr>
                  <img src="assets/images/banners/coming_soon.png" className="w-100"></img>
                </div>
            }
          </div>

        </div>





        {/* --------------------- Workshop Page Ends --------------------------*/}

      </div>
    )
  }
}
