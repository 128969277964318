import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import {
  GetCourseDetail,
  GetMasterClassDetails,
  GetUserLogin,
  MyCourses,
} from "../../../../services";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      blogsList: [],
      category: [],
      email: "",
      modalState: false,
      loading: true,
      banners: [],
      showNewsLetterModal:
        new URLSearchParams(this.props.location.search).get("newsLetter") ==
        "true"
          ? true
          : false,
    };
    this.handleShow = this.handleShow.bind(this);
  }
  handleChange = (e) => {
    this.setState({ email: e.target.value });
  };
  async getblogList() {
    let list = await MyCourses.getAllBlogsList();
    if (list) {
      this.setState({ blogsList: list });
    }
  }
  async componentDidMount() {
    try {
      this.getblogList();
      let banners = await GetCourseDetail.getAllBanners();
      let { data: all } = await GetMasterClassDetails.getAllHomePageCources();
      // console.log(list, live, liveMaster);

      this.setState({
        list: all,
        loading: false,
        banners,
      });

      setTimeout(() => this.setState({ modalState: true }), 4000);
    } catch (error) {
      console.log(error);
    }
  }
  handleShow() {
    this.setState({ modalState: !this.state.modalState });
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    let { email } = this.state;
    const data = {
      email,
      type: "magazine",
    };
    console.log(data);
    let user = await GetUserLogin.getNewSubscriber(data);
    if (user) {
      NotificationManager.success(
        "You successfully subscribed to our newsletter",
        "Thank You"
      );
      setTimeout(() => this.setState({ showNewsLetterModal: false }), 1500);
    }
  };
  render() {
    const { blogsList, list, loading } = this.state;
    return (
      <div>
        <MetaTags>
          <title>
            Learn Ayurveda & Vedic Astrology - Innercord Courses Europe
          </title>
          <meta
            name="description"
            content="Innercord offers an array of Ayurveda & Vedic Astrology Courses to establish an inner connection among your wisdom, passion and creativity."
          />
        </MetaTags>

        <div className="home-banner">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-sm-5 d-flex align-items-center">
                <div className="text-content">
                  <h5>Transform yourself through</h5>
                  <div class="title_icon"></div>
                  <h1>Learn | Sustain | Transform</h1>
                  <p className="mt-4">
                    We offer an array of Ayurveda, astrology and other health
                    and mind management workshops all centered around connecting
                    you to the Innercord of your wisdom, creativity and passion.
                  </p>

                  <a href="#top-hit" class="btn btn-default btn-radius mt-4">
                    Start Learning
                  </a>
                </div>
              </div>

              <div className="col-sm-6 rs-banner">
                <img
                  src="/assets/images/banners/bg-1.jpg"
                  alt="Learn Ayurveda & Astrology"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="yg-classes-section crs-back" id="top-hit">
          <div className="container">
            <div
              className="col-xl-6 col-lg-8 col-md-10 mx-auto text-center animation"
              data-animation="fadeInUp"
              data-animation-delay="0.2s"
            >
              <div className="heading_s2 text-center mb-5">
                <h3>Our Workshops</h3>
                <div class="title_icon"></div>
                <p>
                  Learn the balance of body, mind and nature together with
                  Innercord
                </p>
              </div>
            </div>

            <div className="row mt-4 justify-content-center">
              {loading ? (
                <div className="progressbar">
                  <CircularProgress />
                </div>
              ) : (
                list.map((row, index) => (
                  <div className="col-md-4 col-sm-12" key={index}>
                    <Link
                      to={{
                        pathname: `/course-details/${row.slug}/${row.id}`,
                        state: row,
                      }}
                    >
                      <div className="yg-classes-wrap">
                        {row.isLive && (
                          <span className="category-wrap">
                            <i className="fa fa-dot-circle"></i>&nbsp;
                            {row.isFree && "Free "}
                            Live Session
                          </span>
                        )}
                        {!row.isLive && (
                          <span className="category-wrap">
                            <i class="fa fa-video" aria-hidden="true"></i>{" "}
                            &nbsp;
                            {row.isFree && "Free "}
                            Recorded
                          </span>
                        )}
                        {row.dateTime && (
                          <span className="date-wrap master-dt">
                            <i class="fa fa-calendar" aria-hidden="true"></i>{" "}
                            {moment(row.dateTime).format("LL")}
                          </span>
                        )}
                        <div className="card-img-wrap">
                          <img
                            src={row.thumbnail}
                            className="img-fluid"
                            alt="thumbnail"
                          />
                        </div>
                        <div className="yg-classes-bottom-content">
                          <div className="yg-classes-title">
                            <h2>{row.courseTitle}</h2>
                          </div>
                          <p>{row.description}</p>
                          <p className="rd-txt">
                            Read More{" "}
                            <i
                              class="fa fa-angle-double-right"
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="container">
            <div className="text-center">
              <a href="/programs" class="btn btn-default btn-radius mt-4">
                More Programs{" "}
                <i class="fa fa-angle-double-right pl-2" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Live Course */}

        {/* START SECTION CALL TO ACTION */}
        <section className="overlay_bg_70 back-master">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-8 col-md-8 col-sm-10 text_white animation"
                data-animation="fadeInUp"
                data-animation-delay="0.2s"
              >
                <div className="heading_s3 mb-3">
                  {/* <span className="pb-3 d-block">Improve Your  Emotional & Physical Well-Being at Home </span> */}
                  <h2>
                    Right now things are hard But consulting a doctor doesn't
                    have to be
                  </h2>
                </div>
                <p>
                  Get an expert advice on your health and various other aspects
                  of your life through our Ayurveda and Astrology consultation.{" "}
                </p>
                <a
                  href="/consultations"
                  className="btn btn-default btn-radius mt-4"
                >
                  {" "}
                  <i class="fa fa-video fa-fw"></i> Consult Now
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION CALL TO ACTION */}

        {/* Instagram Feed */}

        {/* <section>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8 col-md-10 text-center animation" data-animation="fadeInUp" data-animation-delay="0.2s">
                <div className="heading_s2 text-center">
                  <h3>Instagram <span className="text-primary">Feed</span></h3>
                  <div class="title_icon"></div>
                </div>
              </div>
            </div>
            <div className="iframe-box">

              <iframe src="https://cdn.lightwidget.com/widgets/da317018ca275472adf227ff30120398.html" scrolling="no" allowTransparency="true" className="lightwidget-widget" style={{ width: '100%', border: 0, overflow: 'hidden' }} />

            </div>

          </div>

        </section>
 */}

        {/* Instagram Feed */}

        <section>
          <div className="container">
            <div className="row">
              <div
                className="col-md-12 text-center animation"
                data-animation="fadeInUp"
                data-animation-delay="0.2s"
              >
                <div className="heading_s2 text-center mb-5">
                  <h3>Why Innercord</h3>
                  <div class="title_icon"></div>
                  <p>
                    In the time of physical products which exhausts easily,
                    Innercord offers timeless knowledge to create healthy living
                    and inner happiness through
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 column-td-half">
                <div class="icon-box d-flex align-items-center">
                  <div className="icon-element icon-element-bg-1 flex-shrink-0">
                    <i className="icon ion-ios-videocam"></i>
                  </div>
                  <div class="info-content">
                    <h4 class="info__title">Interactive Workshops </h4>
                  </div>
                </div>
              </div>

              {/* <div class="col-lg-3 column-td-half">
      <a href="#" class="icon-box d-flex align-items-center">
         <div class="icon-element icon-element-bg-1 flex-shrink-0"><i class="la la-globe"></i></div>
         <div class="info-content">
            <h4 class="info__title">Well Researched Online Courses </h4>
         </div>
      </a>
   </div> */}

              <div className="col-lg-3 column-td-half">
                <div class="icon-box d-flex align-items-center">
                  <div className="icon-element icon-element-bg-1 flex-shrink-0">
                    <i className="icon ion-ios-people"></i>
                  </div>
                  <div className="info-content">
                    <h4 className="info__title">Experienced Mentors </h4>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 column-td-half">
                <div class="icon-box d-flex align-items-center">
                  <div className="icon-element icon-element-bg-1 flex-shrink-0">
                    <i className="ion-heart"></i>
                  </div>
                  <div class="info-content">
                    <h4 class="info__title">Health & Wellness Tips</h4>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 column-td-half">
                <div class="icon-box d-flex align-items-center">
                  <div className="icon-element icon-element-bg-1 flex-shrink-0">
                    <i className="ion-ios-medkit"></i>
                  </div>
                  <div class="info-content">
                    <h4 class="info__title">Organic Healing</h4>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 column-td-half">
                <div class="icon-box d-flex align-items-center">
                  <div className="icon-element icon-element-bg-1 flex-shrink-0">
                    <i className="ion-ios-flask"></i>
                  </div>
                  <div class="info-content">
                    <h4 class="info__title">Vedic Home Remedies</h4>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 column-td-half">
                <div class="icon-box d-flex align-items-center">
                  <div className="icon-element icon-element-bg-1 flex-shrink-0">
                    <i className="ion-ios-body"></i>
                  </div>
                  <div class="info-content">
                    <h4 class="info__title">Yoga and Meditation</h4>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 column-td-half">
                <div class="icon-box d-flex align-items-center">
                  <div className="icon-element icon-element-bg-1 flex-shrink-0">
                    <i className="ion-ios-lightbulb"></i>
                  </div>
                  <div class="info-content">
                    <h4 class="info__title">Mindful Living</h4>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 column-td-half">
                <div class="icon-box d-flex align-items-center">
                  <div className="icon-element icon-element-bg-1 flex-shrink-0">
                    <i className="ion-ios-medical"></i>
                  </div>
                  <div class="info-content">
                    <h4 class="info__title">
                      Astrology & Ayurveda Consultation{" "}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <h5 class="col-sm-11 mx-auto mt-4 text-center teach-txt fs-15">
              When uncertainties are at their peak, health is deteriorating, and
              happiness is not coming from within. We are working to make
              sustainable development in one's world by helping you learn Vedic
              Astrology, Ayurveda, Mindfulness, Yoga and Meditation.
            </h5>
          </div>
        </section>

        <hr />

        <section>
          <div className="container vid_wrapper">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div>
                  <h3>Holistic Lifestyle For Healing & Wellbeing Tips</h3>
                  <div className="title_icon">
                    <i className="flat-lotus"></i>
                  </div>
                </div>
                <p>
                  If you don’t take care of your body, where are you going to
                  live?
                </p>
                <p>
                  Have you ever wanted to change something about your life? Or
                  felt that you start to make progress but always find yourself
                  failing to make it last long?
                </p>
                <p>
                  <b>Watch the video till the end to find out!</b>
                </p>
              </div>
              <div className="col-md-7">
                <div>
                  <iframe
                    width="100%"
                    height="450"
                    src="https://www.youtube.com/embed/gwVLsdgOQCY"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-primary">
          <div className="container">
            <div className="row">
              <div
                className="col-md-12 text-center animation"
                data-animation="fadeInUp"
                data-animation-delay="0.2s"
              >
                <div className="heading_s2 text-center">
                  <h3>Our Testimonials</h3>
                  <div class="title_icon"></div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div
                className="col-md-12 animation"
                data-animation="fadeInUp"
                data-animation-delay="0.3s"
              >
                <div
                  className="testimonial_slider testimonial_section testimonial_style3 carousel_slider owl-carousel owl-theme"
                  data-margin={15}
                  data-nav="true"
                  data-dots="false"
                  data-loop="true"
                  data-autoplay="true"
                  data-items={1}
                  data-center="true"
                  data-autoheight="true"
                >
                  <div className="testimonial_box">
                    <div className="testi_desc">
                      <p>
                        " I was skeptical about joining this course, but once I
                        did, it made me want to learn more and more. It gives
                        you a better understanding and acceptance of who you are
                        and also on how you can enhance your life with certain
                        simple remedies. "
                      </p>
                    </div>
                    <div className="testi_meta">
                      <div className="testimonial_img">
                        <img
                          src="assets/images/client_img1.jpg"
                          alt="Happy client"
                        />
                      </div>
                      <div className="testi_user">
                        <h5>Rick Seldernik</h5>
                        <span>Physiotherapist ,Netherlands</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial_box">
                    <div className="testi_desc">
                      <p>
                        " I recommend this course to people who are looking for
                        deeper understanding of life and are committed to invest
                        their energy to understand the complex role planets are
                        playing in our life. It is all about the Law of
                        Conservation of Energy and vedic astrology explains it
                        purely and objectively. "
                      </p>
                    </div>
                    <div className="testi_meta">
                      <div className="testimonial_img">
                        <img
                          src="assets/images/client_img2.jpg"
                          alt="Happy client"
                        />
                      </div>
                      <div className="testi_user">
                        <h5>Erika Roth</h5>
                        <span>Research Coordinator ,Netherlands</span>
                      </div>
                    </div>
                  </div>

                  <div className="testimonial_box">
                    <div className="testi_desc">
                      <p>
                        "The Innercord Jyothish program has been put together
                        with a lot of care, patience and practicality. Alok
                        Khandewal style of teaching is so unique. He was able to
                        translate the incredible depth of Planets, Rashi,
                        Houses, Nakshetras, Yogas, Transits, & the integration
                        of their combined influence in a way, that I never
                        expected. The program left me with a deep sense of peace
                        and potential about my own life. "
                      </p>
                    </div>
                    <div className="testi_meta">
                      <div className="testimonial_img">
                        <img
                          src="assets/images/client_img3.jpg"
                          alt="Happy client"
                        />
                      </div>
                      <div className="testi_user">
                        <h5>Vani Pavadai</h5>
                        <span>1st Vaastu Archarya ,South Africa</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION TESTIMONIAL */}

        <div className="bg_default section-sm">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 text_white">
                <div
                  className="heading_s3 mb-3 animation animated fadeInUp"
                  data-animation="fadeInUp"
                  data-animation-delay="0.2s"
                  style={{ animationDelay: "0.2s", opacity: 1 }}
                >
                  <h3>Subscribe Our Newsletter</h3>
                </div>
                <p
                  className="m-lg-0 animation animated fadeInUp"
                  data-animation="fadeInUp"
                  data-animation-delay="0.03s"
                  style={{ animationDelay: "0.03s", opacity: 1 }}
                >
                  Subscribe to our newsletters now and stay up-to-date with our
                  new course, promotions and exclusive offers.
                </p>
              </div>
              <div className="col-lg-6">
                <div
                  className="newsletter_form2 animation animated fadeInUp"
                  data-animation="fadeInUp"
                  data-animation-delay="0.3s"
                  style={{ animationDelay: "0.3s", opacity: 1 }}
                >
                  <form>
                    <input
                      type="text"
                      className="form-control"
                      required
                      placeholder="Enter Email Address"
                      value={this.state.email}
                      onChange={(e) => this.handleChange(e)}
                    />
                    <button
                      type="submit"
                      title="Subscribe"
                      className="btn btn-default btn-radius"
                      name="submit"
                      value="Submit"
                      onClick={this.handleSubmit}
                    >
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={this.state.showNewsLetterModal}
          onClose={() => this.setState({ showNewsLetterModal: false })}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              position: "absolute",
              top: "30%",
              left: "30%",
              width: 600,
              backgroundColor: "#fff",
              boxShadow: "#000",
            }}
          >
            <div className="modal-content">
              <button
                type="button"
                class="close btn-cls"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  this.setState({
                    showNewsLetterModal: !this.state.showNewsLetterModal,
                  })
                }
              >
                <span aria-hidden="true">×</span>
              </button>

              <div className="modal-body">
                <div className="newletter-title">
                  <h2>Subscribe our newsletter !</h2>
                </div>
                <div className="box-content newleter-content">
                  <label>
                    Subscribe to our newsletters now and stay up-to-date with
                    our new course, promotions and exclusive offers.
                  </label>
                  <div className="mt-3">
                    <form name="subscribe" id="subscribe_popup">
                      <div>
                        <input
                          required=""
                          placeholder="Email"
                          id="email"
                          class="form-control"
                          type="email"
                          name="email"
                          value={this.state.email}
                          onChange={(e) => this.handleChange(e)}
                        ></input>
                        <button
                          className="btn btn-default w-100 mt-4"
                          onClick={this.handleSubmit}
                        >
                          <span>Submit</span>
                        </button>{" "}
                      </div>
                    </form>
                  </div>
                  {/* /#frm_subscribe */}
                </div>
                {/* /.box-content */}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

/**
 *
 *
 *
 *         <div
          id="myModal"
          className={
            "subscribe-modal modal fade" +
            (this.state.modalState ? " show d-block" : " d-none")
          }
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-newsletter">
            <div className="modal-content">
              <button
                type="button"
                class="close btn-cls"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.handleShow}
              >
                <span aria-hidden="true">×</span>
              </button>

              <div className="modal-body">
                <div className="newletter-title">
                  <h2>Subscribe our newsletter !</h2>
                </div>
                <div className="box-content newleter-content">
                  <label>
                    Subscribe to our newsletters now and stay up-to-date with
                    our new course, promotions and exclusive offers.
                  </label>
                  <div className="mt-3">
                    <form name="subscribe" id="subscribe_popup">
                      <div>
                        <input
                          required=""
                          placeholder="Email"
                          id="email"
                          class="form-control"
                          type="email"
                          name="email"
                          value={this.state.email}
                          onChange={(e) => this.handleChange(e)}
                        ></input>
                        <a
                          className="btn btn-default w-100 mt-4"
                          onClick={this.handleSubmit}
                        >
                          <span>Submit</span>
                        </a>{" "}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
 */
