import React, { Component } from "react";
import { GetUserLogin } from "../../../../services";
import { NotificationManager } from "react-notifications";
export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {},
      firstName: "",
      lastName: "",
      phone: "",
      address: "",
      email: "",
    };
  }
  async componentDidMount() {
    let user = await GetUserLogin.getUserProfile();
    if (user) {
      this.setState({ list: user });
    }
  }
  handleChangeUser(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      list: {
        ...this.state.list,
        [name]: value,
      },
    });
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const { id, firstName, lastName, phone, address, email, gender } =
      this.state.list;
    const data = {
      firstName: firstName,
      lastName: lastName,
      // phone: phone,
      address: address,
      // email: email,
      gender: gender,
    };
    try {
      let user = await GetUserLogin.getUserUpdate(data);
      NotificationManager.success("Successfully Update", "Profile");
    } catch (error) {
      NotificationManager.error("Please check your Field", "Input Error");
    }
  };

  render() {
    const { list } = this.state;
    console.log("About -> render -> list", list);
    return (
      <div>
        {/* --------------------- About Page Starts --------------------------*/}

        {/* START SECTION BREADCRUMB */}
        <section className="breadcrumb-bg lead-hide">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title ">
                  <h1>My Profile</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION BREADCRUMB */}

        {/* START SECTION ABOUT */}
        <section className="pt-4">
          <div className="container">
            <div className="col-sm-8 mx-auto form-main-wrap">
              <div className="field_form icon_form">
                <form onSubmit={this.handleSubmit} noValidate>
                  <h6 className="mb-4">Please fill the details below !</h6>

                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label>First Name</label>
                      <div className="form-input">
                        <span>
                          <i className="ti-user" />
                        </span>
                        <input
                          id="first-name"
                          className="form-control"
                          name="firstName"
                          type="text"
                          value={list.firstName}
                          onChange={(e) => this.handleChangeUser(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group col-lg-6">
                      <label>Last Name</label>
                      <div className="form-input">
                        <span>
                          <i className="ti-user" />
                        </span>
                        <input
                          id="first-name"
                          className="form-control"
                          name="lastName"
                          type="text"
                          value={list.lastName}
                          onChange={(e) => this.handleChangeUser(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group col-lg-6">
                      <label>Contact</label>
                      <div className="form-input">
                        <span>
                          <i class="ti-mobile"></i>
                        </span>
                        <input
                          className="form-control"
                          name="phone"
                          type="text"
                          value={list.phone}
                          readOnly
                          onChange={(e) => this.handleChangeUser(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group col-lg-6">
                      <label>Email</label>
                      <div className="form-input">
                        <span>
                          <i className="ti-email" />
                        </span>
                        <input
                          readOnly
                          className="form-control"
                          name="email"
                          type="email"
                          value={list.email}
                          onChange={(e) => this.handleChangeUser(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group col-lg-6">
                      <label>Gender</label>
                      <div className="chek-form">
                        <div className="custome-radio form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="exampleRadios1"
                            defaultValue="m"
                            onChange={(e) => this.handleChangeUser(e)}
                            checked={list.gender === "m"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios1"
                          >
                            Male
                          </label>
                        </div>
                        <div className="custome-radio form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="exampleRadios2"
                            defaultValue="f"
                            onChange={(e) => this.handleChangeUser(e)}
                            checked={list.gender === "f"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                            Female
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-lg-12">
                      <label>Address</label>
                      <div class="form-input">
                        <span>
                          <i class="ti-comments"></i>
                        </span>
                        <textarea
                          id="description"
                          class="form-control"
                          name="address"
                          rows="5"
                          value={list.address}
                          onChange={(e) => this.handleChangeUser(e)}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    title="Submit Your Message!"
                    className="btn btn-default w-100"
                    id="submitButton"
                    name="submit"
                    value="Submit"
                    onClick={this.handleSubmit}
                  >
                    Update Details
                  </button>

                  <div className="col-lg-12 text-center">
                    <div id="alert-msg" className="alert-msg text-center" />
                  </div>
                </form>

                <div className="text-center mt-3">
                  <h6 style={{ fontSize: 13, cursor: "pointer" }}>
                    Delete My Account
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION ABOUT */}

        {/* --------------------- About Page Ends --------------------------*/}
      </div>
    );
  }
}
