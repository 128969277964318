import React, { Component } from 'react'

export default class Workshop_Enrolled extends Component {
  
    render() {
        return (
            <div>



                {/* --------------------- Contact Page Starts --------------------------*/}

                {/* START SECTION BREADCRUMB */}
                <section className="breadcrumb-bg mt-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 text-center">
                                <div className="page-title">

                                    <i class="fa fa-check tick-circle" aria-hidden="true"></i>

                                    <h1>Workshop Enrolled</h1>
                                    <p className="col-sm-6 mx-auto">Congratulations! You have successfully enrolled for <br></br> <b>Free Live Session Workshop.</b></p>
                                </div>


                                {/* <a href="/my-courses" class="btn btn-default btn-radius mt-4">Go to my courses</a> */}

                            </div>
                        </div>
                    </div>
                </section>
                {/* END SECTION BREADCRUMB */}


                {/* START SECTION CONTACT */}
                <section className="pt-5">
                    <div className="container">
                        <div className="row">

                        </div>
                    </div>
                </section>
                {/* END SECTION CONTACT */}


                {/* --------------------- Contact Page Starts --------------------------*/}



            </div>
        )
    }
}
