import parse from "html-react-parser";
import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { CartHelper, GetCourseDetail } from "../../../../../services";
import { CircularProgress } from "@material-ui/core";

export default class Consultant_Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: "",
      countryList: "",
      locationName: "",
      loading: true,
    };
    this.handleCart = this.handleCart.bind(this);
  }
  handleCart(list) {
    let data = {
      id: list.id,
      courseTitle: list.title,
      thumbnailUrl: list.thumbnail,
      price: list.price,
      indianPrice: list.indianPrice,
      symbol: list?.price?.symbol,
      price: list?.price?.amount,
    };
    CartHelper.addItem(data);
  }
  async componentDidMount() {
    /*geting country*/
    let countryList = await GetCourseDetail.getCountryLisByIp();
    let countryName;
    if (countryList) {
      const myString = `${countryList}`;
      countryName = myString
        .split("\n")
        .filter((el) => el.startsWith("loc"))
        .join("\n");
      // Split on new line filter on the condition that element starts with ip or uag and join
      if (countryName === "loc=IN") {
        this.setState({ locationName: "India" });
      }
    }

    if (countryList) {
      this.setState({ countryList: countryList });
    }
    /*end*/

    window.scrollTo(0, 0);
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    if (this.props.location.state.price) {
      this.setState({ list: this.props.location.state, loading: false });
    } else {
      let data = await GetCourseDetail.getCourseDetailById(id, countryName);
      this.setState({ list: data, loading: false });
    }
  }
  render() {
    let { list, loading } = this.state;
    return (
      <div className="">
        <MetaTags>
          <title>{list.metaTitle}</title>
          <meta name="description" content={list.metaDesc} />
        </MetaTags>
        {loading ? (
          <div
            style={{ minHeight: "35em" }}
            className="d-inline-flex align-items-center w-100 justify-content-center"
          >
            <CircularProgress />
          </div>
        ) : list ? (
          <div className="">
            <section className="breadcrumb-bg lead-hide">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-sm-12 text-center">
                    <div className="page-title page-title-custom">
                      <h1>Consultations</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="course-details-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card border-0 mb-5">
                      <div className="card-body">
                        <div className="doctor-widget mb-4">
                          <div className="doc-info-left d-flex align-items-center">
                            <div className="doctor-img img-L">
                              <img
                                src={list.thumbnail}
                                className="img-fluid"
                                alt={list.title}
                              />
                            </div>
                            <div className="doc-info-cont">
                              <h4 className="doc-name">
                                <a href="">{list.title}</a>
                              </h4>
                              <p className="doc-speciality">
                                {list.description}
                              </p>
                              <div className="clinic-details">
                                <p className="doc-location mb-0">
                                  <i className="fas fa-map-marker-alt" />{" "}
                                  Amsterdam, Netherlands
                                </p>
                                <p className="doc-location mb-0">
                                  <i class="fa fa-clock"></i>{" "}
                                  {list.sessionDuration} Session
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="doc-info-right">
                            <div className="course-content-strip bg-white text-center w-100">
                              <div class="course-content-wrap ">
                                {/*  Price */}
                                <div className="strip-blocks p-0 d-block">
                                  <div>
                                    <h5>
                                      Price:{" "}
                                      <span>
                                        {list?.isFree
                                          ? `Free`
                                          : `${list?.price?.symbol ?? ""}${
                                              list?.price?.amount ??
                                              "Not Available"
                                            }`}
                                      </span>
                                    </h5>
                                    <p className="lt-txt">
                                      {list.indianPrice ? (
                                        <span>*Limited Period Only</span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                </div>

                                {/* Price */}

                                <div className="strip-blocks p-0 d-block mt-4">
                                  <button
                                    className="btn btn-default btn-radius"
                                    onClick={this.handleCart(list)}
                                  >
                                    <Link to="/cart">Book Now</Link>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr></hr>

                        <h5 className="font-weight-bold">Description</h5>

                        <div className="course-abt">
                          {/* Course Details */}
                          {parse(list.about || "")}
                          {/* Course Details */}
                        </div>
                      </div>
                    </div>

                    {/* Booking Card Ends */}
                  </div>
                </div>

                {/* END SECTION ACCORDIONS */}
              </div>
            </section>

            <div className="course-content-strip bg-white mx-auto mt-0">
              <div class="col-sm-10 mx-auto cd-pricing row course-content-wrap m-0 justify-content-center">
                {/*  Price */}
                <div className="strip-blocks">
                  <div>
                    <h5>
                      Price:{" "}
                      <span>
                        {list?.isFree
                          ? `Free`
                          : `${list?.price?.symbol ?? ""}${
                              list?.price?.amount ?? "Not Available"
                            }`}
                      </span>
                    </h5>
                    <p className="lt-txt">
                      {list.indianPrice ? (
                        <span>*Limited Period Only</span>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </div>

                {/* Price */}

                <div className="strip-blocks border-right-0">
                  <button
                    className="btn btn-default btn-radius"
                    onClick={this.handleCart(list)}
                  >
                    <Link to="/cart">Book Now</Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
