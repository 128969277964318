import React, { Component } from "react";
import { CartHelper, GetCourseDetail } from "../../../../services";
export default class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isloaded: false,
      countryList: "",
      locationName: "",
    };
  }
  async componentDidMount() {
    let countryList = await GetCourseDetail.getCountryLisByIp();
    sessionStorage.removeItem("coupon");
    if (countryList) {
      const myString = `${countryList}`;
      let countryName = myString
        .split("\n")
        .filter((el) => el.startsWith("loc"))
        .join("\n");
      // Split on new line filter on the condition that element starts with ip or uag and join
      if (countryName.toString() === "loc=IN") {
        this.setState({ locationName: "India" });
      }
    }
    /*end*/

    let cart = CartHelper.getCart();
    if (cart) {
      this.setState({ list: cart });
    }
  }
  async handleDeleteRow(id) {
    CartHelper.removeItem(id);
  }

  render() {
    const { list, locationName } = this.state;
    return (
      <div>
        {/* --------------------- About Page Starts --------------------------*/}

        {/* START SECTION BREADCRUMB */}
        <section className="breadcrumb-bg lead-hide">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title">
                  <h1>Your Cart</h1>
                  <p className="col-sm-6 mx-auto">
                    Your current items in your shopping bag
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION BREADCRUMB */}

        {/* START SECTION CART */}
        <section className="pt-5">
          {list.length ? (
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive shop_cart_table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="product-thumbnail">Product Image</th>
                          <th className="product-name">Product</th>
                          <th className="product-price">Price</th>
                          <th className="product-subtotal">Total</th>
                          <th className="product-remove">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((row, i) => (
                          <tr key={i}>
                            <td className="product-thumbnail">
                              <img
                                src={row.thumbnailUrl}
                                alt="product1"
                                height="65"
                              />
                            </td>
                            <td className="product-name" data-title="Product">
                              {row.courseTitle}
                            </td>
                            <td className="product-price" data-title="Price">
                              <span>
                                {row.symbol}
                                {row.price}
                              </span>
                            </td>
                            <td className="product-subtotal" data-title="Total">
                              <span>
                                {row.symbol}
                                {row.price}
                              </span>
                            </td>
                            <td className="product-remove" data-title="Remove">
                              <a onClick={(e) => this.handleDeleteRow(row.id)}>
                                <i class="fas fa-trash-alt"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="text-right mt-4">
                <div
                  className="btn btn-default btn-sm"
                  onClick={async () => {
                    if (!sessionStorage.getItem("token"))
                      return (window.location.href = "/login");
                    let countryList = await GetCourseDetail.getCountryLisByIp();
                    let countryName;
                    if (countryList) {
                      const myString = `${countryList}`;
                      countryName = myString
                        .split("\n")
                        .filter((el) => el.startsWith("loc"))
                        .join("\n");
                    }

                    const ids = list.map((cart) => cart.id);
                    const data = await GetCourseDetail.getStripeLink(
                      ids,
                      countryName
                    );
                    window.open(data.link, "_blank");
                  }}
                >
                  Proceed to Checkout
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <h5 className="text-danger">
                Oops! You have not selected any course{" "}
              </h5>
            </div>
          )}
        </section>
        {/* END SECTION CART */}

        {/* --------------------- About Page Ends --------------------------*/}
      </div>
    );
  }
}
