import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const getCouponValue = async (name,courseIds) => {
    var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    if (sessionStorage.getItem('token') && sessionStorage.getItem('token') !== 'undefined') {
        headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    }
    try {
        let result = await api.get(Apis.GetCouponValue+name+"&courseId="+courseIds,{
            headers: headers
        });
        if (result.error) {
            NotificationManager.error(result.error);
            return null;
        }
        return result.data;
    } catch (error) {
        return null;
    }
};

const getPaymentDetails = async (data) => {
    var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    if (sessionStorage.getItem('token') && sessionStorage.getItem('token') !== 'undefined') {
        headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    }
    try {
        let result = await api.post(Apis.GetPaymentDetails,data,{
            headers: headers
        });
        if (result.error) {
            NotificationManager.error(result.error);
            return null;
        }
        return result.data;
    } catch (error) {
        return null;
    }
};

const getPaytmDetails = async (data) => {
    var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    if (sessionStorage.getItem('token') && sessionStorage.getItem('token') !== 'undefined') {
        headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    }
    try {
        let result = await api.post(Apis.GetPytmPaymentDetails,data,{
            headers: headers
        });
        if (result.error) {
            NotificationManager.error(result.error);
            return null;
        }
        return result.data;
    } catch (error) {
        return null;
    }
};

const getPaymentSecret = async (data) => {
    var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    if (sessionStorage.getItem('token') && sessionStorage.getItem('token') !== 'undefined') {
        headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    }
    try {
        let result = await api.post(Apis.GetPaymentSecret,data,{
            headers: headers
        });
        if (result.error) {
            NotificationManager.error(result.error);
            return null;
        }
        return result.data;
    } catch (error) {
        return null;
    }
};

const getPaymentSuccess = async (data) => {
    var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    if (sessionStorage.getItem('token') && sessionStorage.getItem('token') !== 'undefined') {
        headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    }
    try {
        let result = await api.post(Apis.GetPaymentSuccess,data,{
            headers: headers
        });
        if (result.error) {
            NotificationManager.error(result.error);
            return null;
        }
        return result.data;
    } catch (error) {
        return null;
    }
};

const getPytmPaymentDetails = async (course) => {
    // let courseIds =  course.courseIds.join(",")
    var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    if (sessionStorage.getItem('token') && sessionStorage.getItem('token') !== 'undefined') {
        headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    }
    try {
        let result = await api.post(Apis.GetPytmPaymentDetails, {
            userId: course.userId,
            courseIds: course.courseIds,
            amount: course.amount,
        }, {
            headers: headers
        });
        if (result.error) {
            NotificationManager.error(result.error);
            return null;
        }
        return result.data;
    } catch (error) {
        return null;
    }
};

export default {
    getCouponValue,
    getPaymentDetails,
    getPaymentSecret,
    getPaytmDetails,
    getPytmPaymentDetails,
    getPaymentSuccess
};