import CircularProgress from "@material-ui/core/CircularProgress";
import parse from "html-react-parser";
import moment from "moment";
import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { CartHelper, GetCourseDetail } from "../../../../services";

export default class Course_Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: "",
      countryList: "",
      locationName: "",
      comboPrice: [],
      loading: true,
    };
    this.handleCart = this.handleCart.bind(this);
  }
  async handleCart(list) {
    try {
      let data = {
        id: list.id,
        courseTitle: list.title,
        thumbnailUrl: list.thumbnail,
        price: list.price.amount,
        currenct: list.price,
        indianPrice: list.indianPrice,
        symbol: list.price.symbol,
      };
      if (!(await GetCourseDetail.checkCourseAllready(list.id))) {
        await CartHelper.addItem(data);
      }
      window.location.href = "/cart";
    } catch (error) {}
  }
  handleComboCart = async (list, comboPrice) => {
    var p = {
      id: list.id,
      courseTitle: list.courseTitle,
      thumbnailUrl: list.thumbnailUrl,
      price: list.price,
      indianPrice: list.indianPrice,
    };

    var combo = {
      id: comboPrice.course.id,
      courseTitle: comboPrice.course.courseTitle,
      thumbnailUrl: comboPrice.course.thumbnailUrl,
      price: comboPrice.course.price,
      indianPrice: comboPrice.course.indianPrice,
    };
    try {
      console.log(GetCourseDetail.checkCourseAllready(list.id));
      GetCourseDetail.checkCourseAllready(list.id);
    } catch (error) {}

    CartHelper.addItem(p);
    CartHelper.addItem(combo);
    window.location.href = "/cart";
  };
  async getComboPrice() {
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    let list = await GetCourseDetail.getComboCourseDetail(id);
    if (list) {
      this.setState({ comboPrice: list });
    }
  }
  async componentDidMount() {
    // this.getComboPrice();
    let countryList = await GetCourseDetail.getCountryLisByIp();
    let countryName;
    if (countryList) {
      const myString = `${countryList}`;
      countryName = myString
        .split("\n")
        .filter((el) => el.startsWith("loc"))
        .join("\n");
      if (countryName === "loc=IN") {
        this.setState({ locationName: "India" });
      }
    }

    if (countryList) {
      this.setState({ countryList: countryList });
    }

    window.scrollTo(0, 0);
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    if (id) {
      let list = await GetCourseDetail.getPublicCourseById(id, countryName);
      if (list) {
        this.setState({ list: list, loading: false });
      }
    } else {
      let list = await GetCourseDetail.getPublicCourseById(id, countryName);
      if (list) {
        this.setState({ list: list, loading: false });
      }
    }
    // if (this.props.location.state) {
    //   this.setState({ list: this.props.location.state });
    // }
    // else {
    //   let data = await GetCourseDetail.getCourseDetailById(id);
    //   console.log(data);
    //   // this.setState({ list: data });
    // }
  }
  render() {
    let { list, locationName, comboPrice, loading } = this.state;
    return (
      <div className="bg-color">
        {loading ? (
          <div
            className="d-inline-flex align-items-center justify-content-center w-100"
            style={{ height: "60vh" }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {list ? (
              <div className="bd-back">
                <MetaTags>
                  <title>{list.metaTitle || ""}</title>
                  <meta name="description" content={list.metaDesc || ""} />
                </MetaTags>
                <section className="breadcrumb-bg lead-hide">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-sm-12 text-center">
                        <div className="page-title">
                          <h1>{list.metaTitle || ""}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section class="course-details-wrap">
                  <div className="container">
                    <div className="col-sm-10 mx-auto">
                      {/* Course Image */}

                      <div className="course-detail-img">
                        <span className="date-wrap">
                          <i class="fa fa-calendar" aria-hidden="true"></i>{" "}
                          {list.dateTime
                            ? moment(list.dateTime).format("LL")
                            : ""}
                        </span>
                        <img src={list?.thumbnail} alt={list?.title} />
                      </div>
                    </div>
                  </div>

                  <div className="course-content-strip">
                    <div class="col-sm-10 mx-auto cd-pricing row course-content-wrap m-0 justify-content-center">
                      <div className="strip-blocks">
                        <h5>
                          <i className="fa fa-video-camera text-primary"></i>{" "}
                          Session Count : {list.sessionCount}
                        </h5>
                      </div>

                      <div className="strip-blocks">
                        <h5>
                          <i className="fa fa-clock text-primary"></i> Session
                          Duration : {list.duration}
                        </h5>
                      </div>

                      <div className="strip-blocks border-right-0">
                        <h5>
                          <i class="fa fa-calendar" aria-hidden="true"></i>{" "}
                          {list?.dateTime
                            ? moment(list.dateTime).format("LL")
                            : ""}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="course-content-strip bg-white mobile-price-cr">
                    <div class="col-sm-10 mx-auto cd-pricing row course-content-wrap m-0 justify-content-center">
                      <div className="strip-blocks">
                        <div>
                          <h5>
                            Price:
                            <span>
                              {list?.isFree
                                ? `Free`
                                : `${list?.price?.symbol ?? ""}${
                                    list?.price?.amount ?? "Not Available"
                                  }`}
                            </span>
                          </h5>
                          <p className="lt-txt">
                            {list?.indianPrice ? (
                              <span>*Limited Period Only</span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="strip-blocks border-right-0 ">
                        <button
                          className="btn btn-default btn-radius mt-0"
                          onClick={() => this.handleCart(list)}
                        >
                          Join the program
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="col-sm-10 mx-auto course-content-wrap">
                      <p className="list-desc">{list.description}</p>
                      <div className="course-abt">
                        {parse(list.about || "")}
                      </div>
                    </div>

                    <div className="course-content-strip bg-white ">
                      <div class="col-sm-10 mx-auto cd-pricing row course-content-wrap m-0 justify-content-center">
                        <div className="strip-blocks">
                          <div>
                            <h5>
                              Price:
                              <span>
                                {list?.isFree
                                  ? `Free`
                                  : `${list?.price?.symbol ?? ""}${
                                      list?.price?.amount ?? "Not Available"
                                    }`}
                              </span>
                            </h5>
                            <p className="lt-txt">
                              {list.indianPrice ? (
                                <span>*Limited Period Only</span>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="strip-blocks border-right-0">
                          <button
                            className="btn btn-default btn-radius"
                            onClick={() => this.handleCart(list)}
                          >
                            Join the program
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="pt-4 pb-3">
                  <div className="container">
                    <div className="col-sm-11 mx-auto">
                      <div class="heading_s2 text-center pb-4">
                        <h3>Your Takeaways</h3>
                        <div class="title_icon"></div>
                      </div>

                      <div className="row">
                        <div className="col-lg-4 col-sm-6">
                          <div
                            className="icon_box  text-center icon_box_style1 animation"
                            data-animation="fadeInUp"
                            data-animation-delay="0.2s"
                          >
                            <div className="box_icon">
                              <i class="icon ion-ios-videocam-outline"></i>
                            </div>
                            <div className="intro_desc">
                              <h5>Explainer Videos</h5>
                              <p>
                                Simple and effective videos to aid your
                                understanding.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                          <div
                            className="icon_box  text-center icon_box_style1 animation"
                            data-animation="fadeInUp"
                            data-animation-delay="0.3s"
                          >
                            <div className="box_icon">
                              <i className="icon ion-ios-filing-outline"></i>
                            </div>
                            <div className="intro_desc">
                              <h5>Resources</h5>
                              <p>
                                Specially-curated material to rekindle your
                                curiosity.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                          <div
                            className="icon_box  text-center icon_box_style1 animation"
                            data-animation="fadeInUp"
                            data-animation-delay="0.4s"
                          >
                            <div className="box_icon">
                              <i className="icon ion-ios-people-outline"></i>
                            </div>
                            <div className="intro_desc">
                              <h5>Group Discussions</h5>
                              <p>
                                Group interpretations of lessons for better
                                understanding.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                          <div
                            className="icon_box  text-center icon_box_style1 animation"
                            data-animation="fadeInUp"
                            data-animation-delay="0.2s"
                          >
                            <div className="box_icon">
                              <i class="icon ion-android-star-outline"></i>
                            </div>
                            <div className="intro_desc">
                              <h5>Quizzes </h5>
                              <p>
                                Simple question-answer module for better
                                learning.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                          <div
                            className="icon_box  text-center icon_box_style1 animation"
                            data-animation="fadeInUp"
                            data-animation-delay="0.3s"
                          >
                            <div className="box_icon">
                              <i class="icon ion-ios-chatboxes-outline"></i>
                            </div>
                            <div className="intro_desc">
                              <h5>Teacher Support</h5>
                              <p>
                                Easy access to your course teacher for any
                                support.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                          <div
                            className="icon_box  text-center icon_box_style1 animation"
                            data-animation="fadeInUp"
                            data-animation-delay="0.4s"
                          >
                            <div className="box_icon">
                              <i className="icon ion-ios-book-outline"></i>
                            </div>
                            <div className="intro_desc">
                              <h5>Practical Assignments</h5>
                              <p>
                                Effective assignments to fast-track your
                                progress.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {list.teacher ? (
                    <div className="container mt-5 teacher-wrap-ft">
                      <div className="col-sm-11 mx-auto">
                        <div class="heading_s2 text-center pb-4">
                          <h3>Your Teacher</h3>
                          <div class="title_icon"></div>
                        </div>

                        <div className="shadow py-4 px-3 mx-auto row align-items-center ">
                          <div
                            className="col-md-7 animation "
                            data-animation="fadeInUp"
                            data-animation-delay="0.3s"
                          >
                            <h5 className="font-weight-bold">
                              {`${list?.teacher?.firstName || ""} ${
                                list?.teacher?.lastName || ""
                              }`}
                            </h5>
                            <p> {list?.teacher?.email}</p>
                            <p> {list?.teacher?.teacherDesc}</p>
                          </div>
                          {list?.teacher?.teacherImage && (
                            <div
                              className="col-md-5 animation"
                              data-animation="fadeInUp"
                              data-animation-delay="0.2s"
                            >
                              <img
                                src={list?.teacher?.teacherImage}
                                className="w-100"
                                alt="about_img11"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="course-content-strip bg-white ">
                    <div class="col-sm-10 mx-auto cd-pricing row course-content-wrap m-0 justify-content-center">
                      <div className="strip-blocks">
                        <div>
                          <h5>
                            Price:
                            <span>
                              {list?.isFree
                                ? `Free`
                                : `${list?.price?.symbol ?? ""}${
                                    list?.price?.amount ?? "Not Available"
                                  }`}
                            </span>
                          </h5>
                          <p className="lt-txt">
                            {list?.indianPrice ? (
                              <span>*Limited Period Only</span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="strip-blocks border-right-0">
                        <button
                          className="btn btn-default btn-radius"
                          onClick={() => this.handleCart(list)}
                        >
                          Join the program
                        </button>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="combo-wrapper">
                  <div className="container ">
                    <div className="col-sm-10 mx-auto">
                      {comboPrice.length ? (
                        <div className="row combo-card justify-content-center">
                          <div className="col-sm-3">
                            <img
                              src={list?.thumbnail}
                              alt="image"
                              className="w-100"
                            />
                            <h6>{list?.title}</h6>
                          </div>

                          <div className="col-sm-1 d-flex align-items-center mb-4 mt-3">
                            <i class="fa fa-plus"></i>
                          </div>

                          {comboPrice
                            ? comboPrice.map((row, index) => (
                                <div className="col-sm-3" key={index}>
                                  <img
                                    src={row.course.thumbnailUrl}
                                    alt="image"
                                    className="w-100"
                                  />
                                  <h6>{row.course.title}</h6>
                                </div>
                              ))
                            : ""}

                          <div className="course-content-strip bg-white ">
                            <div class="course-content-wrap text-center"></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    );
  }
}
