import React from 'react';
import { ElementsConsumer, IdealBankElement } from '@stripe/react-stripe-js';
import { NotificationManager } from 'react-notifications';
import { CartHelper, GetUserLogin, Coupon } from '../../../../../../services';

import IdealBankSection from '../IdealBankSection';

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountholdername: '', user: '', cartList: [], totalPrice: '', couponValue: '', loading: false
        }
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    async componentDidMount() {
        

        let email = localStorage.getItem('email');
        let user = await GetUserLogin.getUserByEmail(email);

        let cart = await CartHelper.getCart();
        if (user && cart) {
            const total = cart.reduce((prev, next) => prev + next.price, 0);
            this.setState({ totalPrice: total, user: user, cartList: cart })
        }
    }
    handleChangeIdealBank = async (event) => {
        event.preventDefault();
        this.setState({ loading: true})
        let { totalPrice } = this.state;

        let grandtotal = totalPrice
        let couponPrice = parseInt(sessionStorage.getItem('coupon'))
        if (couponPrice) {
            let discount = couponPrice//(Math.round((total * couponPrice) / 100));
            grandtotal = (Math.round(totalPrice - discount));   
        }



        let ids = [];
        this.state.cartList.map((data) => {
            ids.push(data.id)
        })
        let data = {
            userId: this.state.user.userId,
            courseIds: ids,
            currency: "eur",
            paymentMethod: "ideal",
            amount: grandtotal * 100
        }
        if (data) {
            let secretkey = await Coupon.getPaymentSecret(data);
            const { stripe, elements } = this.props
            const idealBank = elements.getElement(IdealBankElement);

            if (!stripe || !elements) {
                return;
            }
            setTimeout(async () => {
                this.setState({ loading: false})
                const { error } = await stripe.confirmIdealPayment(secretkey, {
                    payment_method: {
                        ideal: idealBank,
                        billing_details: {
                            name: this.state.accountholdername,
                        },
                    },
                    return_url: 'https://www.innercord.com/success',
                });

                if (error) {
                    console.log("CheckoutForm -> error", error)
                }
            }, 3000)
        } else {
            NotificationManager.error("Check Input Field", "Input");
        }

    };

    render() {
        const { stripe } = this.props;
        let { loading } = this.state;
        return (
            <form className="w-100" onSubmit={this.handleChangeIdealBank} disabled={loading}>
                <div class="form-row m-0">
                    <label>
                        Name
                        <input
                            value={this.state.name}
                            name="accountholdername"
                            placeholder="Account Holder Name"
                            onChange={(e) => this.handleChange(e)}
                            required
                        />
                    </label>
                </div>
                <div className="form-row w-100 m-0 mt-2">
                    <IdealBankSection />
                </div>
                <button className="btn btn-primary w-100 mt-3" type="submit" disabled={!stripe}>
                    {loading && <i className="fa fa-refresh fa-spin"></i>}
                    {loading && <span>  Pay Now </span>}
                    {!loading && <span> Pay Now  </span>}
                </button>
            </form>
        );
    }
}

export default function InjectedCheckoutForm() {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    );
}