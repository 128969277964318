import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

export default class Terms extends Component {
    render() {
        return (
            <div>

<MetaTags>
  <title>Terms & Condition | Innercord  Europe </title>
  <meta name="description" content="These Terms of Use govern your access to and use of our website, including any content, functionality and services offered on or through our website" />
</MetaTags>
              
  {/* --------------------- Contact Page Starts --------------------------*/}

    {/* START SECTION BREADCRUMB */}
    <section className="breadcrumb-bg lead-hide">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-sm-12 text-center">
          <div className="page-title">
            <h1>Terms & Contiditions</h1>
          </div>
         
        </div>
      </div>
    </div>
  </section>
  {/* END SECTION BREADCRUMB */}


  {/* START SECTION CONTACT */}
  <section  className="pt-5">
    <div className="container">
      

        <div className="text-block">
          <h5>I. Acceptance of the Terms of Use</h5>
          <p>Welcome to the website of Innercord and on behalf of itself and its affiliates and licensors, licensees or service providers ("Organization”, also referred to as “us” or “we”). These Terms of Use govern your access to and use of our website, including any content, functionality and services offered on or through our website, and the treatment of any personal data that you choose to submit.</p>
         <p>Each time you use this website, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at https://www.innercord.com/privacy-policy, incorporated herein by reference. If you do not agree to these Terms of Use or the Privacy Policy, please do not access or use the website.</p>
        </div>

        <div className="text-block">
          <h5>II. Changes to the Terms of Use</h5>
          <p>We may revise and update these Terms of Use from time to time in our sole discretion. All future changes are effective and binding when they are posted.</p>
         <p>If you use the website following the posting of revised Terms of Use you accept and agree to the changes. It is your responsibility to check this page from time to time so you are aware of any changes, as they are binding on you if you choose to continue to access or use the website.</p>
        </div>


        <div className="text-block">
          <h5>III. Accessing the Website and Account Security</h5>
          <p>We reserve the right to close or amend this website, and withdraw any service or material we provide on the website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the website, or the entire website, to users, including registered users.</p>   
          <p>You are responsible for:</p>
          <ul className="ml-5 mb-3">
            <li>Making all arrangements necessary for you to have access to the website.</li>
            <li>Ensuring that all persons who access the website through your internet connection are aware of these Terms of Use and comply with them.</li>
          </ul>
          <p>To access the website or some of the resources it offers, including course registrations, you may be asked to provide certain registration details including personal information. It is a condition of your use of the website that all the information you provide on the website is correct, current and complete. You agree that all information you provide us to register with this website or otherwise, including but not limited to through the use of any interactive features on the website, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>
          <p>If you choose, or are provided with, a user name, password or other identifier, or any other piece of information (“User Data”) as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this website or portions of it using your User Data. You agree to notify us immediately of any unauthorized access to or use of your User Data or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your User Data or other personal information.</p>
          <p>We have the right to disable any User Data , whether chosen by you or provided by us, at any time in our sole discretion if, in our opinion, you have violated any provision of these Terms of Use or we become aware of a potential breach of security through the use of your User Data.</p>
        </div>



        <div className="text-block">
          <h5>IV. Intellectual Property Rights</h5>
         <p>The website and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Organization, its affiliates, its licensors or other providers of such material and are protected by national and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</p>
         <p>These Terms of Use permit you to use the website for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our website, except as follows:</p>
         <ul className="ml-5 mb-3">
            <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
            <li>You may store files that are automatically cached by your web browser for display enhancement purposes.</li>
            <li>You may print or download one copy of a reasonable number of pages of the website for your own personal, non-commercial use and not for further reproduction, publication or distribution.</li>
            <li>If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</li>
            <li>If we provide social media with certain features, you may take such actions as are enabled by such features.</li>
          </ul>

          <p>You must not:</p>
            <ul className="ml-5 mb-3">
              <li>Modify copies of any materials from this site.</li>
              <li>Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.</li>
              <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.</li>
            </ul>

            <p>You must not access or use for any commercial purposes any part of the website or any services or materials available through the website.</p>
            <p>If you wish to make any use of material on the website other than that set out in this section, please address your request to: info@innercord.com</p>
            <p>If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the website in breach of the Terms of Use, your right to use the website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the website or any content on the site is transferred to you, and all rights not expressly granted are reserved by the Organization. Any use of the website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>
            
        </div>



        <div className="text-block">
          <h5>V. Prohibited Uses</h5>
          <p>You may access and use the website only for lawful purposes and in accordance with these Terms of Use. You agree not to access or use the website:</p>
        
          <ul className="ml-5 mb-3">
             <li>In any way that violates any applicable national, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from other countries).</li>
            <li>For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</li>
            <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards set out in these Terms of Use.</li>
            <li>To transmit, or procure the sending of, any advertising or promotional material without our prior written consent including any "junk mail," "chain letter" or "spam" or any other similar solicitation.</li>
            <li>To impersonate or attempt to impersonate the Organization, an Organization employee, another user or any other person or entity (including, without limitation, by using e-mail addresses associated with any of the foregoing).</li>
            <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the website, or which, as determined by us, may harm the Organization or users of the website or expose them to liability.</li>
          </ul>

          <p>Additionally, you agree not to:</p>

          <ul className="ml-5 mb-3">
            <li>Use the website in any manner that could reasonably be expected to potentially disable, overburden, damage, or impair the site or interfere with any other party's use of the website, including their ability to engage in real time activities through the website.</li>
            <li>Use any robot, spider or other automatic device, process or means to access the website for any purpose, including monitoring or copying any of the material on the website.</li>
            <li>Use any manual process to monitor or copy any of the material on the website or for any other unauthorized purpose without our prior written consent.</li>
            <li>Use any device, software or routine that interferes with the proper working of the website.</li>
            <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li>
            <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the website, the server on which the website is stored, or any server, computer or database connected to the website.</li>
            <li>Attack the website via a denial-of-service attack or a distributed denial-of-service attack.</li>
            <li>Otherwise attempt to interfere with the proper working of the website.</li>
          </ul>
        </div>



        <div className="text-block">
          <h5>VI. User Contributions</h5>
           <p>The website may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards and other interactive features (collectively, "Interactive Services") that allow users to post, submit, publish, display or transmit to other users or other persons (hereinafter, "post") content or materials (collectively, "User Contributions") on or through the website.</p>
           <p>All User Contributions must comply with the Content Standards set out in these Terms of Use.</p>
           <p>Any User Contributions you post to the site will be considered non-confidential and non-proprietary. By providing any User Contributions on the website, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material.</p>
           <p>You represent and warrant that:</p> 
           <ul className="ml-5 mb-3">
             <li>You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns.</li>
             <li>All of your User Contributions do and will comply with these Terms of Use.</li> 
             <li>You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Organization, have full responsibility for such content, including its legality, reliability, accuracy and appropriateness.</li> 
             <li>You understand and acknowledge that we are not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by you or any other user of the website.</li> 
          
          </ul>
        </div>

        <div className="text-block">
          <h5>VII. Monitoring and Enforcement; Termination</h5>
          <p>We have the right to:</p>
          <ul className="ml-5 mb-3">
                <li>Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</li>
                <li>Take any action with respect to any User Contributions that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contributions violate the Terms of Use, including the Content Standards, infringe any intellectual property right or other right of any person or entity, threaten the personal safety of users of the website or the public or could create liability for the Organization.</li>
                <li>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>
                <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the website.</li>
                <li>Terminate or suspend your access to all or part of the website for any violation of these Terms of Use.</li>
          </ul>

          <p>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the website.</p>
        </div>

        <div className="text-block">
          <h5>VIII. Waiver and Indemnification in the event of Investigations</h5>
         <p>You agree to hold harmless the Organization from any claims resulting from any action taken by or on behalf of the Organization  during or as a result of its investigations and from any actions taken as a consequence of investigations by either the organization/such parties or law enforcement authorities with respect to your User Contributions, and agree to waive any claims against the Organization resulting from any such actions where your User Contributions are found to be in violation of these Terms of Use.</p>
        <p>However, we do not undertake to review all material before it is posted on the website, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party or for performance or nonperformance of the activities described in this section.</p>
        </div>


        <div className="text-block">
          <h5>IX. Content Standards</h5>
          <p>User Contributions and use of Interactive Services must in their entirety comply with all applicable local, national and international laws and regulations. Without limiting the foregoing, they must not:</p>
           
          <ul className="ml-5 mb-3">
               <li>Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.</li>
               <li>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
               <li>Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.</li>
               <li>Violate the legal rights of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy.</li>
               <li>Be likely to deceive any person.</li>
               <li>Promote any illegal activity, or advocate, promote or assist any unlawful act.</li>
               <li>Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.</li>
               <li>Impersonate any person, or misrepresent your identity or affiliation with any person or organization.</li>
               <li>Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.</li>
               <li>Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</li>
          </ul>
       
        </div>


        <div className="text-block">
          <h5>X. No Reliance on Information Posted</h5>
         <p>Information presented on or through the website is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of any information on the website. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the website, or by anyone who may be informed of any of its contents.</p>
         <p>This website may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Organization, are solely the opinions and the responsibility of the person or entity providing those materials. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</p>
        </div>


        <div className="text-block">
          <h5>XI. Changes to the Website</h5>
          <p>We may update the content on this website from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the website may be out of date at any given time, and we are under no obligation to update such material.</p>
        </div>


        <div className="text-block">
          <h5>XII. Information About You and Your Visits to the Website</h5>
         <p>All information we collect on this website is subject to our Privacy Policy. By using the website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>
        </div>

        <div className="text-block">
          <h5>XIII. Linking to the Website and Social Media Features</h5>
         <p>You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express written consent.</p>
         <p>This website may provide certain social media features that enable you to:</p>
         
         <ul className="ml-5 mb-3">
          <li>Link from your own or certain third-party websites to certain content on this website. </li>
          <li>end e-mails or other communications with certain content, or links to certain content, on this website.</li>
          <li>Cause limited portions of content on this website to be displayed or appear to be displayed on your own or certain third-party websites.
You may use these features solely as they are provided by us, and solely with respect to the content they are displayed with and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:</li>
          <li>Establish a link from any website that is not owned by you.</li>
          <li>Cause the website or portions of it to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site.</li>
          <li>Link to any part of the website other than the homepage.</li>
          <li>Otherwise take any action with respect to the materials on this website that is inconsistent with any other provision of these Terms of Use.</li>
        </ul>

        <p>You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice.</p>
         <p>We may disable all or any social media features and any links at any time without notice in our discretion.</p>
        </div>

        <div className="text-block">
          <h5>XIV. Links from the Website</h5>
         <p>If the website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third party websites linked to this website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</p>
        </div>

        <div className="text-block">
          <h5>XV. Disclaimer of Warranties and Liability</h5>
          <p>You acknowledge and understand that we cannot and do not guarantee that files available for downloading from the internet or the website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checks to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data.</p>
          <p>We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the website or any services or items obtained through the website or to your downloading of any material posted on it, or on any website linked to it.</p>
          <p>Your use of the website is at your own risk, and its content and any services or items obtained through the website are provided on an "as is" and "as available" basis.</p>
          <p>The Organization hereby disclaims any warranty, whether express or implied, statutory or otherwise, including but not limited to any warranty of merchantability, non-infringement and fitness for particular purpose, completeness, security, reliability, quality, accuracy or uninterrupted or error-free availability of the website, its content or any services or items obtained through the website, that defects will be corrected, that the Organization’s website or the server that makes it available are free of viruses or other harmful components or that the website or any services or items obtained through the website will otherwise meet your needs or expectations.</p>
          <p>The foregoing does not affect any warranties that cannot be excluded or limited under applicable law.</p>  
        </div>

        <div className="text-block">
          <h5>XVI. Limitation on Liability</h5>
         <p>In no event will the Organization, or its employees, agents, officers or directors be liable for damages of any kind, under any legal theory, arising out of or in connection with your use, or inability to use, the website, any websites linked to it, any content on the website or such other websites or any services or items obtained through the website or such other websites, including any direct, indirect, special, incidental, consequential or punitive damages, including but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable.</p>
        <p>The foregoing does not affect any liability that cannot be excluded or limited under applicable law.</p>
        </div>


        <div className="text-block">
          <h5>XVII. Indemnification</h5>
          <p>You agree to defend, indemnify and hold harmless the Organization, as well as its respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, investigations, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the website, including, but not limited to, your User Contributions, any use of the website's content, services and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the website.</p>
          
        </div>


        <div className="text-block">
          <h5>Governing Law and Jurisdiction</h5>
           <p>All matters relating to the website and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of India without giving effect to any choice or conflict of law provision or rule (whether of India or any other jurisdiction).</p>
          <p>Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the website shall be instituted exclusively in India, although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country if appropriate. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
        </div>


        <div className="text-block">
          <h5>Waiver and Severability</h5>
           <p>No waiver of by the Organization of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Organization to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
          <p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>
        </div>

        <div className="text-block">
          <h5>Entire Agreement</h5>
           <p>The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and the Organization with respect to the website and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the website.</p>
        </div>


        <div className="text-block">
          <h5>Your Comments and Concerns</h5>
           <p>TThis website is operated by Innercord.All feedback, comments, requests for technical support and other communications relating to the website should be directed to: info@innercord.com. Thank you for visiting the website.</p>
        </div>

        



    </div>
  </section>
  {/* END SECTION CONTACT */}
 

  {/* --------------------- Contact Page Starts --------------------------*/}

            </div>
        )
    }
}
