import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GetCourseDetail } from "../../../../services";

export default class ConsultationsCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programList: [],
      loading: true,
      locationName: "",
      isArchived: false,
    };
  }
  async componentDidMount() {
    try {
      let { data: list } = await GetCourseDetail.getAllCoursePublicByCategoryId(
        this.props.id
      );
      this.setState({ programList: list, loading: false });
    } catch (error) {}
    this.setState({ loading: false });
  }
  render() {
    const { programList, loading, isArchived } = this.state;
    const updatedProgramList = programList.filter(
      (program) => program.isArchived === isArchived
    );
    return (
      <>
        <div className="d-inline-flex justify-content-center align-items-center w-100">
          <FormControlLabel
            style={{ padding: 0, margin: 0 }}
            control={
              <Switch
                checked={this.state.isArchived}
                color="primary"
                onChange={() =>
                  this.setState({
                    isArchived: !this.state.isArchived,
                  })
                }
                name="checkedA"
              />
            }
            label="Show Archived"
          />
        </div>
        <div className="row">
          {loading ? (
            <div className="d-inline-flex align-items-center w-100 justify-content-center">
              <CircularProgress />
            </div>
          ) : updatedProgramList.length ? (
            updatedProgramList.map((row, index) => (
              <div className="col-md-4 col-sm-12 mb-4" key={index}>
                <Link
                  to={{
                    pathname: `/consultant/${row.slug}/${row.id}`,
                    state: row,
                  }}
                >
                  <div className="yg-classes-wrap">
                    {row.isLive && (
                      <span className="category-wrap">
                        <i className="fa fa-dot-circle"></i>&nbsp;
                        {row.isFree && "Free "}
                        Live Session
                      </span>
                    )}
                    {!row.isLive && (
                      <span className="category-wrap">
                        <i class="fa fa-video" aria-hidden="true"></i> &nbsp;
                        {row.isFree && "Free "}
                        Recorded
                      </span>
                    )}
                    {new Date(row.dateTime).getTime() >
                      new Date().getTime() && (
                      <span className="date-wrap">
                        <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                        {moment(row.dateTime).format("LL")}
                      </span>
                    )}
                    <div className="consult-wrap">
                      <img
                        src={row.thumbnail}
                        className="img-fluid"
                        alt="thumbnail"
                      />
                    </div>
                    <div className="yg-classes-bottom-content">
                      <div className="yg-classes-title">
                        <h2>{row.title}</h2>
                      </div>
                      <p>{row.description}</p>
                      {/* <p className="rd-txt">
                      {" "}
                      Reserve My Spot{" "}
                      <i
                        class="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </p> */}
                      {/* <span className="price">
                        Price: {row?.price?.symbol}
                        {row?.price?.amount}
                      </span> */}
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <h6>No Data Avalaible</h6>
          )}
        </div>
      </>
    );
  }
}
