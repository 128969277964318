import React, { Component } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';


const stripePromise = loadStripe('pk_live_51HDU1QAJ8SCDPxTCCAtpZFlkQUTyABaDEwsYWjP1YleHUIqg0FnRjzbe3VhvbysqhVu8ewTCfZPqfv3enSmrClYu00c24g7O40');
export default class Stripepayment extends Component {
    render() {
        return (
            <div>
                <Elements stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            </div>
        )
    }
}
