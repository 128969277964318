import React, { Component } from "react";
import { GetUserLogin } from "../../../../services";
import { NotificationManager } from "react-notifications";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};
export default class Forgot_Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      formErrors: {
        email: "",
      },
    };
  }
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "invalid email address";
        break;
      default:
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let { email } = this.state;
    if (formValid(this.state)) {
      let user = await GetUserLogin.getUserResetPassword({
        email,
        host: window.location.origin,
      });
      NotificationManager.success(user?.message, "Success");
    } else {
      NotificationManager.error("Please check your Login", "Input Error");
    }
  };
  render() {
    let { email, password, formErrors } = this.state;
    return (
      <div>
        {/* --------------------- About Page Starts --------------------------*/}

        {/* START SECTION BREADCRUMB */}
        <section className="breadcrumb-bg lead-hide">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title">
                  <h1>Forgot Password</h1>
                  <p className="col-sm-6 mx-auto">
                    Please fill the form below to reset your password !
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION BREADCRUMB */}

        {/* START SECTION ABOUT */}
        <section className="pt-5">
          <div className="container">
            <div className="col-sm-4 mx-auto form-main-wrap">
              <div className="field_form icon_form">
                <form onSubmit={this.handleSubmit} noValidate>
                  <h6 className="mb-4">Please enter your registered email !</h6>

                  <div className="form-group">
                    <label>Email</label>
                    <div className="form-input">
                      <span>
                        <i className="ti-email" />
                      </span>
                      <input
                        required="required"
                        placeholder="Email"
                        id="email"
                        className="form-control"
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                      />
                    </div>
                    {formErrors.email.length > 0 && (
                      <span className="errorMessage">{formErrors.email}</span>
                    )}
                  </div>

                  <button
                    type="submit"
                    title="Submit Your Message!"
                    className="btn btn-default w-100"
                    id="submitButton"
                    name="submit"
                    value="Submit"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </button>

                  <div className="col-lg-12 text-center">
                    <div id="alert-msg" className="alert-msg text-center" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION ABOUT */}

        {/* --------------------- About Page Ends --------------------------*/}
      </div>
    );
  }
}
