import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

export default class Contact extends Component {
    render() {
        return (
            <div>

        <MetaTags>
          <title>Contact Us - Innercord Europe</title>
          <meta name="description" content="Connect Us To Connect With You. Contact us at info@innercord.com" />
        </MetaTags>
              
  {/* --------------------- Contact Page Starts --------------------------*/}

    {/* START SECTION BREADCRUMB */}
    <section className="breadcrumb-bg lead-hide">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-sm-12 text-center">
          <div className="page-title">
            <h1>Contact Us</h1>
            <p className="col-sm-6 mx-auto">We want to provide you with direct access to the right people on our team who can support you.</p>
          </div>
         
        </div>
      </div>
    </div>
  </section>
  {/* END SECTION BREADCRUMB */}


  {/* START SECTION CONTACT */}
  <section  className="pt-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 animation" data-animation="fadeInUp" data-animation-delay="0.2s">
          <div className="heading_s2">
            <h3>Connect us to connect with you</h3>
          </div>
          <p className="mb-0">Do you have any questions, feel free to email us at </p>
          <ul className="contact_info contact_info_style2 list_none">
            <li>
              <span className="ti-email" />
                <b>info@innercord.com</b>
            </li>
           
          </ul>
        </div>
        <div className="col-lg-8 col-md-6 mt-4 mt-lg-0 animation" data-animation="fadeInUp" data-animation-delay="0.3s">
          <div className="field_form icon_form">
            <form method="post" name="enq">
              <div className="row">
                <div className="form-group col-lg-6">
                  <div className="form-input">
                    <span>
                      <i className="ti-user" />
                    </span>
                    <input required="required" placeholder="Enter Name *" id="first-name" className="form-control" name="name" type="text" />
                  </div>
                </div>
                <div className="form-group col-lg-6">
                  <div className="form-input">
                    <span>
                      <i className="ti-email" />
                    </span>
                    <input required="required" placeholder="Enter Email *" id="email" className="form-control" name="email" type="email" />
                  </div>
                </div>
                <div className="form-group col-12">
                  <div className="form-input">
                    <span>
                      <i className="ti-folder" />
                    </span>
                    <input placeholder="Enter Subject" id="subject" className="form-control" name="subject" type="text" />
                  </div>
                </div>
                <div className="form-group col-lg-12">
                  <div className="form-input">
                    <span>
                      <i className="ti-comments" />
                    </span>
                    <textarea required="required" placeholder="Message *" id="description" className="form-control" name="message" rows={5} defaultValue={""} />	
                  </div>
                </div>
                <div className="col-lg-12">
                  <button type="submit" title="Submit Your Message!" className="btn btn-default" id="submitButton" name="submit" value="Submit">Submit</button>
                </div>
                <div className="col-lg-12 text-center">
                  <div id="alert-msg" className="alert-msg text-center" />
                </div>
              </div>
            </form>		
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* END SECTION CONTACT */}
 

  {/* --------------------- Contact Page Starts --------------------------*/}

            </div>
        )
    }
}
