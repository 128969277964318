import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../../NotFound";
import Footer from "../footer";
import Header from "../header";
import PrivateRoute from "../PrivateRoute";
import About from "../web/views/about";
import Blog_View from "../web/views/blog-view";
import Blogs from "../web/views/blogs";
import Cart from "../web/views/cart";
import Checkout from "../web/views/checkout";
import Consulatations from "../web/views/consultations";
import Contact from "../web/views/contact";
import Cookie from "../web/views/cookie";
import Course_Details from "../web/views/course-details";
import Course_Details_Free from "../web/views/course-details-free";
import Course_View from "../web/views/course-view";
import Email_Sent from "../web/views/email-sent";
import Email_Verified from "../web/views/email-verified";
import Home from "../web/views/home";
import Live_Sessions from "../web/views/live-sessions";
import Live_Sessions_Free from "../web/views/live-sessions-free";
import Workshop_Enrolled from "../web/views/masterclass-enrolled";
import Mentors from "../web/views/mentors";
import My_Courses from "../web/views/my-courses";
import Privacy from "../web/views/privacy";
import Profile from "../web/views/profile";
import Programs from "../web/views/programs";
import Recorded from "../web/views/recorded";
import Recorded_Free from "../web/views/recorded-free";
import Register from "../web/views/register";
import Terms from "../web/views/terms";
import Accountverified from "./views/account-verify";
import Failed from "./views/checkout/failed";
import Successfully from "./views/checkout/success";
import Consultant_Details from "./views/consultations/detail";
import Forgot_Password from "./views/forgot-password";
import GoogleLogin from "./views/google-login";
import Login from "./views/login";
import Otp from "./views/verify";
import ChnagePassword from "./views/change-password";
export default class rootRoutes extends Component {
  render() {
    let id = window.location.pathname.split("/").pop();
    if (this.props.history.location.pathname === `/course-view/${id}`) {
      return (
        <Switch>
          <Route path="/course-view" component={Course_View} />
          <Route component={NotFound} />
        </Switch>
      );
    } else {
      return (
        <div>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/course-details/:slug/:id"
              component={Course_Details}
            />
            <Route
              exact
              path="/consultant/:slug/:id"
              component={Consultant_Details}
            />
            <Route
              exact
              path="/course-details-free/:slug/:id"
              component={Course_Details_Free}
            />
            <Route exact path="/recorded" component={Recorded} />
            <Route exact path="/live-sessions" component={Live_Sessions} />
            <Route exact path="/recorded-free" component={Recorded_Free} />
            <Route exact path="/google-login" component={GoogleLogin} />
            <Route
              exact
              path="/live-sessions-free"
              component={Live_Sessions_Free}
            />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/about" component={About} />
            <Route exact path="/cart" component={Cart} />
            <PrivateRoute exact path="/my-courses" component={My_Courses} />
            <PrivateRoute
              exact
              path="/course-view/:id"
              component={Course_View}
            />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={Forgot_Password} />
            <Route exact path="/reset/:one/:two" component={Otp} />
            <Route exact path="/account-verified" component={Accountverified} />
            <Route exact path="/register" component={Register} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/change-password" component={ChnagePassword} />
            <PrivateRoute path="/checkout" component={Checkout} />
            <Route path="/payment/success" component={Successfully} />
            <Route path="/payment/failed" component={Failed} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/terms&conditions" component={Terms} />
            <Route exact path="/cookie-policy" component={Cookie} />
            <Route exact path="/email-sent" component={Email_Sent} />
            <Route exact path="/email-verified" component={Email_Verified} />
            <Route
              exact
              path="/workshop-enrolled"
              component={Workshop_Enrolled}
            />
            <Route exact path="/blogs" component={Blogs} />
            <Route exact path="/mentors" component={Mentors} />
            <Route exact path="/blog-view/:name/:id" component={Blog_View} />
            <Route exact path="/programs" component={Programs} />
            <Route exact path="/consultations" component={Consulatations} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </div>
      );
    }
  }
}
