import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { GetUserLogin } from '../../../../services';
import parse from 'html-react-parser';
import moment from 'moment';
export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {},
    };
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    var url = window.location.href.replace(/\/$/, '');  /* remove optional end / */
    var lastSeg = url.substr(url.lastIndexOf('/') + 1)

    let user = await GetUserLogin.getBlogById(lastSeg);
    if (user) {
      this.setState({ list: user })
    }
  }
  render() {
    let { list } = this.state;
    console.log(list)
    return (
      <div>

        <MetaTags>
          <title>{list.metaTitle}</title>
          <meta name="description" content={list.metaDescription} />
        </MetaTags>

        {/* --------------------- About Page Starts --------------------------*/}

     
     <section className="blog-view">
       <div className="container">      
           <div className="col-sm-10 mx-auto">
             
             <img className="blg-img" src={list.imageUrl} alt={list.title} />
         <div className="bg-white-blog">
           
             <h5 className="blg-title">{list.title ? list.title : ''}</h5>
             <div className="blg-date-wrap">
                <i className="far fa-calendar" /> {moment(list.blogDate).format('LL')}
                </div>

                <p className="blg-desc">{list.details}</p>

             <div className="course-abt-blog">
             {
                      list.description ? 
                      <span>{parse(list.description)}.</span>
                      :''
                    }
                    </div>

             
             
            </div>

            </div>

         
         </div>

         <section className="pt-0 pb-0">
              <div className="container">
                <div className="text-center">
            <hr className="mt-5" />
            <a href="/blogs" className="text-center text-primary">View all blogs <i className="fa fa-chevron-right ml-2" /></a>
          </div>


              </div>


       



            </section>
     </section>




     {/* <div classname="col-sm-11 mx-auto">
            <section className="pt-5">
              <div className="container">
                <div className="text-center">
            <hr className="mt-5" />
            <a href="/blogs" className="text-center text-primary">View all blogs <i className="fa fa-chevron-right ml-2" /></a>
          </div>


              </div>


       



            </section>
          </div> */}


       


       






        {/* --------------------- About Page Ends --------------------------*/}

      </div>
    )
  }
}
