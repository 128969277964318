import api from "../../ApiConfig";
import { Apis } from "../../../config";
import { NotificationManager } from "react-notifications";

const createCommentByUser = async (id, data) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.post(Apis.CreateCommentByUser + `${id}`, data, {
      headers: headers,
    });
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getAllCommentByUser = async (id) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.get(
      Apis.GetAllCommentByUser + `${id}?page=1&limit=10000`,
      {
        headers: headers,
      }
    );
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};
export default {
  createCommentByUser,
  getAllCommentByUser,
};
