import React, { Component } from 'react';
import rootRoutes from './components/web/rootRoutes';
import NotFound from './NotFound';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
export default class App extends Component {
	render() {
		return (
			<div className="App">
				<BrowserRouter>
					<NotificationContainer />
					<Switch>
						<Route path='/' component={rootRoutes} />
						<Route component={NotFound} />
					</Switch>
				</BrowserRouter>
			</div>
		);
	}
}
