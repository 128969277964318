const API_URL = "";

const Apis = {
  //Authentication api
  GetUserLogin: `${API_URL}/v1/auth/login`,
  GetUserRegsiter: `${API_URL}/v1/auth/register`,
  GetUserByEmail: `${API_URL}/api/user/getUserByEmailId?email=`,
  GetUserUpdate: `${API_URL}/api/user/updateUser`,
  GetUserResetPassword: `${API_URL}/v1/auth/reset-password`,
  GetUserUpdatePassword: `${API_URL}/v1/auth/reset`,

  //profile
  GetCurrentUser: `${API_URL}/v1/user/me`,
  GetUpdateUser: `${API_URL}/v1/user/me`,
  GetUpdatePassword: `${API_URL}/v1/user/change-password`,

  //Banners
  GetAllBanners: `${API_URL}/v1/banner/top`,

  //cource
  GetAllWorkshopsHome: `${API_URL}/v1/course/list/top-latest`,
  GetAllWorkshop: `${API_URL}/v1/course/list/1`,
  GetAllLiveSession: `${API_URL}/v1/course/list/2`,
  GetAllList: `${API_URL}/v1/course/list/3`,
  GetAllProgramList: `${API_URL}/v1/course/type/program`,
  GetCourseDetailById: `${API_URL}/v1/course/one/`,
  GetComboCourseDetail: `${API_URL}/v1/course/one/`,
  GetpublicCourseByIdDetail: `${API_URL}/v1/course/single/`,

  public: {
    getAllByCategoryId: `/v1/course/list/`, // categoryId
    GetAllConsultant: `${API_URL}/v1/course/type/consultation`,
    GetSingleCourseById: `${API_URL}/v1/course/one/`,
  },
  GetStripeLink: `${API_URL}/v1/payment/checkout`,
  checkOrderAllreadyBeforeCart: `${API_URL}/v1/user-course/check-purchased/`,
  GetAllCategory: `${API_URL}/v1/category`,
  GetCourseById: `${API_URL}/v1/course/single/`,
  GetCourseByIdCustomer: `${API_URL}/v1/curriculum/customer/`,
  GetPdfLink: `${API_URL}/v1/curriculum/customer/pdf/`,
  //master class
  GetAllRecorded: `${API_URL}/v1/course/list/3`,
  GetAllMasterLiveSession: `${API_URL}/v1/course/list/3`,
  GetEnrollementOfMasterClass: `${API_URL}/api/course/enrollementOfMasterClass`,

  //Get All Mentors
  GetAllMentors: `${API_URL}/v1/user/public/teachers`,
  //home Page Cources

  GetAllPublicCources: `${API_URL}/v1/course/list/top-latest?limit=9`,

  //coupon
  GetCouponValue: `${API_URL}/api/coupon/getCouponByName?name=`,
  GetPaymentDetails: `${API_URL}/api/payment/checkout`,
  GetPaymentSuccess: `${API_URL}/api/payment/success`,
  GetPytmPaymentDetails: `${API_URL}/api/payment/submitPaymentDetail?`,

  //my course
  GetUserWorkshop: `${API_URL}/v1/user-course/`,
  GetUserLiveSession: `${API_URL}/api/course/getAllCoursesForUser?courseType=`,
  GetPaymentSecret: `${API_URL}/api/payment/getSecret`,
  // GetCourseById: `${API_URL}/api/course/getCourseById?id=`,
  GetCurriculamVideoUrl: `${API_URL}/api/course/getCurriculamVideoUrl?id=`,
  GetCountryLisByIp: `https://www.cloudflare.com/cdn-cgi/trace`,
  GetComboOffers: `${API_URL}/api/course/getCourseComboDetails`,

  //comment
  CreateCommentByUser: `${API_URL}/v1/discussion/`,
  GetAllCommentByUser: `${API_URL}/v1/discussion/`,

  //blogs
  GetAllBlogsList: `${API_URL}/getAllBlog`,
  GetNewSubscriber: `${API_URL}/v1/marketing/send/file`,
  GetBlogById: `${API_URL}/getBlogById?id=`,
};
export { API_URL, Apis };
