import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { GetCourseDetail } from "../../../../services";
import ConsultationsCategory from "./ConsultationsCategory";
export default class Consultations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      category: JSON.parse(localStorage.getItem("category")) || [],
      countryList: "",
      locationName: "",
      loading: true,
      isArchived: false,
    };
  }
  async componentDidMount() {
    let countryList = await GetCourseDetail.getCountryLisByIp();
    let countryName;
    if (countryList) {
      const myString = `${countryList}`;
      countryName = myString
        .split("\n")
        .filter((el) => el.startsWith("loc"))
        .join("\n");
      if (countryName === "loc=IN") {
        this.setState({ locationName: "India" });
      }
    }
    let { data: list } = await GetCourseDetail.getAllConsultant(countryName);
    this.setState({ list: list, loading: false });
    if (countryList) {
      this.setState({ countryList: countryList });
    }
  }
  render() {
    const { list, locationName, loading, isArchived } = this.state;
    const updatedList = list.filter(
      (program) => program.isArchived === isArchived
    );
    return (
      <div>
        <MetaTags>
          <title>Personal Consultations for Health | Innercord - Europe</title>
          <meta
            name="description"
            content="Book a one-on-one consultation session with our doctors and astro-experts to get solutions in life through Mindfulness, Yoga, Ayurveda, and Vedic Astrology."
          />
        </MetaTags>
        <section className="breadcrumb-bg lead-hide">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title page-title-custom">
                  <h1>Consultations</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {loading ? (
          <div className="d-inline-flex align-items-center w-100 justify-content-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="yg-classes-section pt-3">
            <div className="container">
              <div className="tab-style-custom">
                <div className="text-center">
                  <ul className="nav nav-tabs " role="tablist">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          !new URLSearchParams(window.location.search).get(
                            "isActive"
                          )
                            ? "active"
                            : ""
                        }`}
                        id="home0-tab"
                        data-toggle="tab"
                        href="#home0"
                        role="tab"
                        aria-controls="home0"
                        aria-selected="true"
                      >
                        All Consultations
                      </a>
                    </li>
                    {this.state.category.map((c, i) => {
                      if (c.type === "consultation") {
                        return (
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                c.id.toString() ==
                                new URLSearchParams(window.location.search)
                                  .get("isActive")
                                  ?.toString()
                                  ? "active"
                                  : ""
                              }`}
                              id={`home${c.id}-tab`}
                              data-toggle="tab"
                              href={`#home${c.id}`}
                              role="tab"
                              aria-controls={`home${c.id}`}
                              aria-selected="false"
                            >
                              {c.name}
                            </a>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>

                <div className="tab-content shop_info_tab pr-tabs">
                  <div
                    className={`tab-pane  ${
                      !new URLSearchParams(window.location.search).get(
                        "isActive"
                      )
                        ? "active"
                        : ""
                    }
                      `}
                    id="home0"
                    role="tabpanel"
                    aria-labelledby="home0-tab"
                  >
                    <div className="d-inline-flex justify-content-center align-items-center w-100 pt-0">
                      <FormControlLabel
                        style={{ padding: 0, margin: 0 }}
                        control={
                          <Switch
                            checked={this.state.isArchived}
                            color="primary"
                            onChange={() =>
                              this.setState({
                                isArchived: !this.state.isArchived,
                              })
                            }
                            name="checkedA"
                          />
                        }
                        label="Show Archived"
                      />
                    </div>
                    {this.state.list.length > 0 ? (
                      <div className="row">
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          updatedList.map((row, index) => (
                            <div className="col-md-4 col-sm-12" key={index}>
                              <Link
                                to={{
                                  pathname: `/consultant/${row.slug}/${row.id}`,
                                  state: row,
                                }}
                              >
                                <div className="yg-classes-wrap">
                                  {row?.isLive && (
                                    <span className="category-wrap">
                                      <i className="fa fa-dot-circle"></i> Live
                                      Session
                                    </span>
                                  )}
                                  {new Date(row?.dateTime).getTime() >
                                    new Date().getTime() && (
                                    <span className="date-wrap">
                                      <i
                                        className="fa fa-calendar"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {moment(row?.dateTime).format("LL")}
                                    </span>
                                  )}
                                  <div className="consult-wrap">
                                    <img
                                      src={row?.thumbnail}
                                      className="img-fluid"
                                      alt="thumbnail"
                                    />
                                  </div>
                                  <div className="yg-classes-bottom-content">
                                    <div className="yg-classes-title">
                                      <h2>{row?.title}</h2>
                                    </div>
                                    <p>{row?.description}</p>
                                    {/* <span className="price">
                                      Price: {row?.price?.symbol}
                                      {row?.price?.amount}
                                    </span> */}
                                    {/* <p className="rd-txt">
                                    {" "}
                                    Reserve My Spot{" "}
                                    <i
                                      className="fa fa-angle-double-right"
                                      aria-hidden="true"
                                    ></i>
                                  </p> */}
                                    <p></p>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ))
                        )}
                      </div>
                    ) : (
                      "No Data Avalaible"
                    )}
                  </div>

                  {this.state.category.map((c, i) => {
                    if (c.type === "consultation") {
                      return (
                        <div
                          className={`tab-pane ${
                            c.id.toString() ==
                            new URLSearchParams(window.location.search)
                              .get("isActive")
                              ?.toString()
                              ? "active"
                              : ""
                          }`}
                          id={`home${c.id}`}
                          role="tabpanel"
                          aria-labelledby={`home${c?.id}-tab`}
                        >
                          <ConsultationsCategory id={c?.id} />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
