import React, { Component } from "react";
import { GetUserLogin } from "../../../../services";
import { NotificationManager } from "react-notifications";
export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  }
  handleChangeUser(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    const data = {
      old: oldPassword,
      new: newPassword,
    };
    if (newPassword.toString() !== confirmNewPassword.toString()) {
      NotificationManager.error(
        "password and newpassword not match",
        "Input Error"
      );
      return;
    }
    let user = await GetUserLogin.getUpdatePassword(data);
    window.location.reload();
  };

  render() {
    return (
      <div>
        {/* --------------------- About Page Starts --------------------------*/}

        {/* START SECTION BREADCRUMB */}
        <section className="breadcrumb-bg lead-hide">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title ">
                  <h1>Update Password</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION BREADCRUMB */}

        {/* START SECTION ABOUT */}
        <section className="pt-4">
          <div className="container">
            <div className="col-sm-8 mx-auto form-main-wrap">
              <div className="field_form icon_form">
                <form onSubmit={this.handleSubmit} noValidate>
                  <h6 className="mb-4">Please fill the details below !</h6>

                  <div className="row">
                    <div className="form-group col-lg-12">
                      <div className="row">
                        <div className="form-group col-lg-6">
                          <label>Old Password</label>
                          <div className="form-input">
                            <span>
                              <i className="ti-user" />
                            </span>
                            <input
                              id="first-name"
                              className="form-control"
                              name="oldPassword"
                              type="password"
                              value={this.state.oldPassword}
                              onChange={(e) => this.handleChangeUser(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-6">
                      <label>New Password</label>
                      <div className="form-input">
                        <span>
                          <i className="ti-user" />
                        </span>
                        <input
                          id="first-name"
                          className="form-control"
                          name="newPassword"
                          type="password"
                          value={this.state.newPassword}
                          onChange={(e) => this.handleChangeUser(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group col-lg-6">
                      <label>Confim New Password</label>
                      <div className="form-input">
                        <span>
                          <i className="ti-user" />
                        </span>
                        <input
                          id="first-name"
                          className="form-control"
                          name="confirmNewPassword"
                          type="password"
                          value={this.state.confirmNewPassword}
                          onChange={(e) => this.handleChangeUser(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    title="Submit Your Message!"
                    className="btn btn-default w-100"
                    id="submitButton"
                    name="submit"
                    value="Submit"
                    onClick={this.handleSubmit}
                  >
                    Update Password
                  </button>

                  <div className="col-lg-12 text-center">
                    <div id="alert-msg" className="alert-msg text-center" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION ABOUT */}

        {/* --------------------- About Page Ends --------------------------*/}
      </div>
    );
  }
}
