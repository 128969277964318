import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import { GetUserLogin } from "../../../../services";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const phoneRegex = RegExp(/^\+(?:[0-9] ?){6,14}[0-9]$/);

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};
export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      isChecked: false,
      newsFeedEnable: false,
      formErrors: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        mobile: "",
      },
    };
  }
  handleCheck() {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }
  handleFeedEnable() {
    this.setState({
      newsFeedEnable: !this.state.newsFeedEnable,
    });
  }
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "firstName":
        formErrors.firstName =
          value.length < 3 ? "minimum 3 characaters required" : "";
        break;
      case "lastName":
        formErrors.lastName =
          value.length < 3 ? "minimum 3 characaters required" : "";
        break;
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "invalid email address";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "minimum 6 characaters required" : "";
        break;
      case "mobile":
        formErrors.mobile = phoneRegex.test(value)
          ? ""
          : "Enter valid mobile number with country code";
        break;
      default:
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let {
      firstName,
      lastName,
      email,
      password,
      gender,
      newsFeedEnable,
      mobile,
    } = this.state;
    let data = {
      phone: mobile,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      gender: gender.toString().includes("F") ? "f" : "m",
      newsletter: newsFeedEnable ? true : false,
    };
    if (formValid(this.state)) {
      let list = await GetUserLogin.getUserRegister(data);
      if (list) NotificationManager.success("Succesfully Registered");
      window.location.href = "/email-sent";
    } else {
      NotificationManager.error("Please check your Register", "Input Error");
    }
  };
  render() {
    let { mobile, email, password, firstName, lastName, formErrors } =
      this.state;
    return (
      <div>
        <section className="breadcrumb-bg lead-hide">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title">
                  <h1>Sign Up</h1>
                  <p className="col-sm-6 mx-auto">
                    Sign Up with innercord for full benefits !
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-5">
          <div className="container">
            <div className="col-sm-8 mx-auto form-main-wrap">
              <div className="field_form icon_form">
                <form onSubmit={this.handleSubmit} noValidate>
                  <h6 className="mb-4">Please fill the details below !</h6>

                  <div className="row">
                    <div className="form-group col-sm-6">
                      <label>First Name</label>
                      <div className="form-input">
                        <span>
                          <i className="ti-user" />
                        </span>
                        <input
                          required="required"
                          placeholder="First Name "
                          id="first-name"
                          className={
                            formErrors.firstName.length > 0
                              ? "form-control"
                              : "form-control"
                          }
                          name="firstName"
                          type="text"
                          value={firstName}
                          onChange={this.handleChange}
                        />
                      </div>
                      {formErrors.firstName.length > 0 && (
                        <span className="errorMessage">
                          {formErrors.firstName}
                        </span>
                      )}
                    </div>

                    <div className="form-group col-sm-6">
                      <label>Last Name</label>
                      <div className="form-input">
                        <span>
                          <i className="ti-user" />
                        </span>
                        <input
                          required="required"
                          placeholder="Last Name "
                          id="first-name"
                          className="form-control"
                          name="lastName"
                          type="text"
                          value={lastName}
                          onChange={this.handleChange}
                        />
                      </div>
                      {formErrors.lastName.length > 0 && (
                        <span className="errorMessage">
                          {formErrors.lastName}
                        </span>
                      )}
                    </div>

                    <div className="form-group col-sm-6">
                      <label>Email</label>
                      <div className="form-input">
                        <span>
                          <i className="ti-email" />
                        </span>
                        <input
                          required="required"
                          placeholder="Email"
                          id="email"
                          className="form-control"
                          type="email"
                          name="email"
                          value={email}
                          onChange={this.handleChange}
                        />
                      </div>
                      {formErrors.email.length > 0 && (
                        <span className="errorMessage">{formErrors.email}</span>
                      )}
                    </div>

                    <div className="form-group col-sm-6">
                      <label>Mobile Number</label>
                      <div className="form-input">
                        <span>
                          <i className="ti-email" />
                        </span>
                        <input
                          placeholder="Mobile number with international code"
                          id="subject"
                          className="form-control"
                          name="mobile"
                          type="tel"
                          value={mobile}
                          onChange={this.handleChange}
                        />
                      </div>
                      {formErrors.mobile.length > 0 && (
                        <span className="errorMessage">
                          {formErrors.mobile}
                        </span>
                      )}
                    </div>

                    <div className="form-group col-sm-6">
                      <label>Password</label>
                      <div className="form-input">
                        <span>
                          <i className="ti-lock" />
                        </span>
                        <input
                          placeholder="Password"
                          id="subject"
                          className="form-control"
                          name="password"
                          type="password"
                          value={password}
                          onChange={this.handleChange}
                        />
                      </div>
                      {formErrors.password.length > 0 && (
                        <span className="errorMessage">
                          {formErrors.password}
                        </span>
                      )}
                    </div>

                    <div className="form-group col-md-12 mt-3">
                      <label>Gender</label>
                      <div className="chek-form">
                        <div className="custome-radio form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="exampleRadios1"
                            defaultValue="Male"
                            onChange={this.handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios1"
                          >
                            Male
                          </label>
                        </div>
                        <div className="custome-radio form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="exampleRadios2"
                            defaultValue="Female"
                            onChange={this.handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                            Female
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-12 mt-3">
                      <div className="chek-form">
                        <div className="custome-checkbox">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="checkbox"
                            id="exampleCheckbox1"
                            onChange={(e) => this.handleFeedEnable()}
                            defaultChecked={this.state.newsFeedEnable}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheckbox1"
                          >
                            I want to receive <b>Innercord Newsletters </b>{" "}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-12 mb-4">
                      <div className="chek-form">
                        <div className="custome-checkbox">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="checkbox"
                            id="exampleCheckbox2"
                            onChange={(e) => this.handleCheck()}
                            defaultChecked={this.state.isChecked}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheckbox2"
                          >
                            I agree to{" "}
                            <a href="/privacy-policy" className="text_default">
                              Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a
                              href="/terms&conditions"
                              className="text_default"
                            >
                              Terms and Conditions{" "}
                            </a>
                            <sup
                              style={{ fontSize: 20, top: 0, color: "#3657e9" }}
                            >
                              *
                            </sup>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    className={
                      !this.state.isChecked
                        ? "btn btn-default btn-disabled w-100"
                        : "btn btn-default w-100"
                    }
                    onClick={this.handleSubmit}
                    disabled={!this.state.isChecked}
                  >
                    Submit
                  </button>

                  <div className="col-lg-12 text-center">
                    <div id="alert-msg" className="alert-msg text-center" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION ABOUT */}

        {/* --------------------- About Page Ends --------------------------*/}
      </div>
    );
  }
}
