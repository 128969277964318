import React, { Component } from 'react'

export default class Accountverified extends Component {
    render() {
        return (
            <div>



            {/* --------------------- Contact Page Starts --------------------------*/}

            {/* START SECTION BREADCRUMB */}
            <section className="breadcrumb-bg mt-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12 text-center">
                            <div className="page-title">

                                <i class="fa fa-check tick-circle" aria-hidden="true"></i>

                             <h1>Password Changed </h1>

                                <p className="col-sm-6 mx-auto">Your password has been changed successfully. <br/> Please login to your account continue.</p>
                            </div>


                            <a href="/login" class="btn btn-default btn-radius mt-4">Login To Account</a>

                        </div>
                    </div>
                </div>
            </section>
            {/* END SECTION BREADCRUMB */}


            {/* START SECTION CONTACT */}
            <section className="pt-5">
                <div className="container">
                    <div className="row">

                    </div>
                </div>
            </section>
            {/* END SECTION CONTACT */}


            {/* --------------------- Contact Page Starts --------------------------*/}



        </div>
        )
    }
}
