import api from "../../ApiConfig";
import { Apis } from "../../../config";
import { NotificationManager } from "react-notifications";

const getUserWorkshop = async (page, limit) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.get(`${Apis.GetUserWorkshop}?limit=1000`, {
      headers: headers,
    });
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getUserLiveSession = async (id) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.get(Apis.GetUserLiveSession + id, {
      headers: headers,
    });
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getAllBlogsList = async () => {
  try {
    let result = await api.post(Apis.GetAllBlogsList);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

export default {
  getUserWorkshop,
  getUserLiveSession,
  getAllBlogsList,
};
