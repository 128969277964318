import React, { Component } from "react";
import { GetUserLogin } from "../../../../services";
import { NotificationManager } from "react-notifications";

export default class Otp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passworderror: "",
      redirectToReferrer: false,
      confirmPassword: "",
      confirmPasswordError: "",
    };
    this.handlePassword = this.handlePassword.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
  }
  handlePassword(p) {
    this.setState({ password: p.target.value });
  }
  handleConfirmPassword(p) {
    this.setState({ confirmPassword: p.target.value });
  }
  validate = () => {
    let passworderror = "";
    let confirmPasswordError = "";
    if (!this.state.password) {
      passworderror = "password cannot be blank";
    }
    if (!this.state.confirmPassword) {
      confirmPasswordError = "confirmPassword cannot be blank";
    }
    if (
      this.state.confirmPassword &&
      this.state.password &&
      this.state.confirmPassword != this.state.password
    ) {
      confirmPasswordError = "password is not match";
    }
    if (passworderror || confirmPasswordError) {
      this.setState({ passworderror: passworderror, confirmPasswordError });
      return false;
    }
    return true;
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = this.validate();
    console.log(this.props.match.params);
    if (isValid) {
      let data = {
        password: this.state.password,
        one: this.props.match.params.one,
        two: this.props.match.params.two,
      };
      let update = await GetUserLogin.getUserUpdatePassword(data);
      if (update) {
        this.props.history.push({
          pathname: "/account-verified",
        });
      } else {
        NotificationManager.error("Please check response ", "Error");
      }
    }
  };
  render() {
    return (
      <div>
        {/* END SECTION BREADCRUMB */}
        <div className="" sx={{ minHeight: "5em" }}></div>
        {/* START SECTION ABOUT */}
        <section className="pt-5 mt-5">
          <div className="container ">
            <div className="col-sm-4 mx-auto form-main-wrap mt-5">
              <div className="field_form icon_form">
                <form onSubmit={this.handleSubmit} noValidate>
                  <h6 className="mb-4">Enter your New Password</h6>
                  <form className="register-form">
                    <div className="form-group">
                      <input
                        type="password"
                        className={
                          this.state.passworderror
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={this.state.password}
                        onChange={this.handlePassword}
                        placeholder="Enter New Password"
                      />
                      {this.state.passworderror ? (
                        <div className="invalid-feedback text-left">
                          {this.state.passworderror}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className={
                          this.state.confirmPasswordError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={this.state.confirmPassword}
                        onChange={this.handleConfirmPassword}
                        placeholder="Confirm Password"
                      />
                      {this.state.confirmPasswordError ? (
                        <div className="invalid-feedback text-left">
                          {this.state.confirmPasswordError}
                        </div>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      title="Submit Your Message!"
                      className="btn btn-default w-100"
                      id="submitButton"
                      name="submit"
                      value="Submit"
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </button>
                  </form>

                  <div className="col-lg-12 text-center">
                    <div id="alert-msg" className="alert-msg text-center" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
