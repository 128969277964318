import api from "../../ApiConfig";
import { Apis } from "../../../config";
import { NotificationManager } from "react-notifications";

const getAllRecorded = async () => {
  try {
    let result = await api.post(Apis.GetAllRecorded);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getAllMasterLiveSession = async () => {
  try {
    let result = await api.get(Apis.GetAllMasterLiveSession);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getAllMentors = async () => {
  try {
    let result = await api.get(Apis.GetAllMentors);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getAllHomePageCources = async () => {
  try {
    let result = await api.get(Apis.GetAllPublicCources);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getEnrollementOfMasterClass = async (data) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  setTimeout(function () {
    sessionStorage.removeItem("token");
  }, 1000 * 60 * 60);
  try {
    let result = await api.post(Apis.GetEnrollementOfMasterClass, data, {
      headers: headers,
    });
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

export default {
  getAllRecorded,
  getAllMasterLiveSession,
  getEnrollementOfMasterClass,
  getAllMentors,
  getAllHomePageCources,
};
