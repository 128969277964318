import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { GetCourseDetail } from "../../../../services";
import ProgramsCategory from "./ProgramCategory";
import Pagination from "@material-ui/lab/Pagination";

export default class Programs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programList: [],
      category: JSON.parse(localStorage.getItem("category")) || [],
      loading: true,
      isArchived: false,
    };
  }
  async componentDidMount() {
    let countryList = await GetCourseDetail.getCountryLisByIp();
    let countryName;
    if (countryList) {
      const myString = `${countryList}`;
      countryName = myString
        .split("\n")
        .filter((el) => el.startsWith("loc"))
        .join("\n");
    }
    let { data: programList } = await GetCourseDetail.getAllProgramList(
      countryName
    );
    this.setState({
      programList: programList,
      loading: false,
    });
  }
  render() {
    const { programList, loading, isArchived } = this.state;
    const updatedProgramList = programList.filter(
      (program) => program.isArchived === isArchived
    );
    return (
      <div>
        <MetaTags>
          <title>Online Ayurveda & Astrology Workshops - Europe</title>
          <meta
            name="description"
            content="Innercord offers Ayurveda & Astrology workshops to deliver the extensive knowledge of organic healing and cosmic understanding."
          />
        </MetaTags>

        <section className="breadcrumb-bg lead-hide">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title page-title-custom">
                  <h1>Our Programs</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="yg-classes-section pt-3">
          <div className="container">
            <div className="tab-style-custom">
              <div className="text-center">
                <ul className="nav nav-tabs " role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        !new URLSearchParams(window.location.search).get(
                          "isActive"
                        )
                          ? "active"
                          : ""
                      }`}
                      data-toggle="tab"
                      href="#home888"
                      role="tab"
                    >
                      All Programs
                    </a>
                  </li>
                  {this.state.category.map((c, i) => {
                    if (c.type.toString() === "program") {
                      return (
                        <li className="nav-item" key={i}>
                          <a
                            className={`nav-link ${
                              c.id.toString() ==
                              new URLSearchParams(window.location.search)
                                .get("isActive")
                                ?.toString()
                                ? "active"
                                : ""
                            }`}
                            data-toggle="tab"
                            role="tab"
                            href={`#home${c.id}`}
                          >
                            {c.name}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>

              <div className="tab-content shop_info_tab pr-tabs">
                <div
                  className={`tab-pane  ${
                    !new URLSearchParams(window.location.search).get("isActive")
                      ? "active"
                      : ""
                  }
                      `}
                  id="home888"
                  role="tabpanel"
                >
                  <div className="d-inline-flex justify-content-center align-items-center w-100 pt-0">
                    <FormControlLabel
                      style={{ padding: 0, margin: 0 }}
                      control={
                        <Switch
                          checked={this.state.isArchived}
                          color="primary"
                          onChange={() =>
                            this.setState({
                              isArchived: !this.state.isArchived,
                            })
                          }
                          name="checkedA"
                        />
                      }
                      label="Show Archived"
                    />
                  </div>
                  <div className="row">
                    {loading ? (
                      <div
                        className="d-inline-flex w-100 align-items-center justify-content-center"
                        style={{ height: "30vh" }}
                      >
                        <CircularProgress />
                      </div>
                    ) : updatedProgramList.length > 0 ? (
                      updatedProgramList.map((row, index) => (
                        <div className="col-md-4 col-sm-12" key={index}>
                          <Link
                            to={{
                              pathname: `/course-details/${row.slug}/${row.id}`,
                              state: row,
                            }}
                          >
                            <div className="yg-classes-wrap">
                              <div className="d-inline-free">
                                {row.isLive && (
                                  <span className="category-wrap">
                                    <i className="fa fa-dot-circle"></i>&nbsp;
                                    {row.isFree && "Free "}
                                    Live Session
                                  </span>
                                )}
                                {!row.isLive && (
                                  <span className="category-wrap">
                                    <i
                                      class="fa fa-video"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    &nbsp;
                                    {row.isFree && "Free "}
                                    Recorded
                                  </span>
                                )}
                              </div>
                              <span className="date-wrap">
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                ></i>{" "}
                                {moment(row.dateTime).format("LL")}
                              </span>
                              <div className="consult-wrap">
                                <img
                                  src={row.thumbnail}
                                  className="img-fluid"
                                  alt="thumbnail"
                                />
                              </div>
                              <div className="yg-classes-bottom-content">
                                <div className="yg-classes-title">
                                  <h2>{row.title}</h2>
                                </div>
                                <p>{row.description}</p>
                                <p className="rd-txt">
                                  {" "}
                                  Reserve My Spot{" "}
                                  <i
                                    className="fa fa-angle-double-right"
                                    aria-hidden="true"
                                  ></i>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                    ) : (
                      "No Data Avalaible"
                    )}
                  </div>
                </div>
                {this.state.category.map((c, i) => {
                  if (c.type.toString() === "program") {
                    return (
                      <div
                        className={`tab-pane ${
                          c.id.toString() ==
                          new URLSearchParams(window.location.search)
                            .get("isActive")
                            ?.toString()
                            ? "active"
                            : ""
                        }`}
                        id={`home${c.id}`}
                        role="tabpanel"
                      >
                        <ProgramsCategory id={c.id} />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
