import React from "react";
import { GetUserLogin } from "../../../../services";

const GoogleLogin = async () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const firstName = queryParams.get("firstName");
  const lastName = queryParams.get("lastName");
  const email = queryParams.get("email");
  if (token) {
    await GetUserLogin.authenticate(
      { token, firstName, lastName, email },
      { reload: false }
    );
    window.location.href = "/my-courses";
    return;
  }
  window.location.href = "/login";

  return <React.Fragment>Validating Login</React.Fragment>;
};

export default GoogleLogin;
