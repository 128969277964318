import parse from "html-react-parser";
import moment from "moment";
import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { NotificationManager } from "react-notifications";
import {
  GetCourseDetail,
  GetMasterClassDetails,
  GetUserLogin,
} from "../../../../services";

export default class Course_Details_Free extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      loading: false,
      list: [],
    };
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);

    let data = await GetCourseDetail.getCourseDetailById(id);
    if (data) {
      this.setState({ list: data });
    }
  }
  handleLoginCheckMaster = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    let { list } = this.state;
    let data = {
      courseType: list.courseType,
      userId: this.state.user.userId,
      courseId: list.id,
    };
    if (data) {
      setTimeout(async () => {
        let success = await GetMasterClassDetails.getEnrollementOfMasterClass(
          data
        );
        if (success) {
          window.location.href = "/workshop-enrolled";
          this.setState({ loading: false });
        } else {
          this.setState({ loading: true });
        }
      }, 2000);
    } else {
      NotificationManager.error("check credential for User", "User");
    }
  };
  render() {
    let { list, loading } = this.state;
    return (
      <div className="bg-color">
        <MetaTags>
          <title>{list.metaTitle}</title>
          <meta name="description" content={list.metaDescription} />
        </MetaTags>

        <div className="bd-back">
          {/* Workshop Details  */}

          {/* START SECTION BREADCRUMB */}
          <section className="breadcrumb-bg lead-hide">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-sm-12 text-center">
                  <div className="page-title ">
                    <h1>{list.title}</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* END SECTION BREADCRUMB */}

          {/* About The Class */}

          <section class="course-details-wrap">
            <img
              className="cd-back"
              src="/assets/images/banners/collage.png"
            ></img>

            <div className="container">
              <div className="col-sm-10 mx-auto">
                {/* Video Player */}
                {GetUserLogin.isAuthenticated() ? (
                  <video
                    style={
                      list.courseType === 3
                        ? { width: "100%", height: 500 }
                        : { display: "none" }
                    }
                    controls
                  >
                    <source
                      src={list.recordedMasterClassVideoLink}
                      type="video/mp4"
                    ></source>
                  </video>
                ) : (
                  ""
                )}

                {/* Video Player */}

                {/* Course Image */}
                {GetUserLogin.isAuthenticated() ? (
                  <div className="course-detail-img">
                    {" "}
                    <img
                      src={list.thumbnail}
                      alt="image"
                      style={
                        list.courseType === 4
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    />
                  </div>
                ) : (
                  <div className="course-detail-img">
                    {/* <span className="category-wrap">
                        {
                          list.courseType === 3 ? <span> <i class="fa fa-dot-circle"></i> Recorded Masterclass</span> : <span><i class="fa fa-dot-circle"></i> Live Session Masterclass</span>
                        }
                      </span> */}

                    {list.courseType === 4 ? (
                      <span className="date-wrap master-dt">
                        <i class="fa fa-calendar" aria-hidden="true"></i>{" "}
                        {moment(list.dateTime).format("LL")}
                      </span>
                    ) : (
                      ""
                    )}

                    <img src={list.thumbnail} alt="image" />
                  </div>
                )}
              </div>
            </div>

            <div className="course-content-strip">
              <div class="col-sm-10 mx-auto cd-pricing row course-content-wrap m-0 justify-content-center">
                <div className="strip-blocks">
                  <h5>
                    <i className="fa fa-video-camera text-primary"></i> Session
                    Count : {list.sessionCount}
                  </h5>
                </div>

                <div className="strip-blocks">
                  <h5>
                    <i className="fa fa-clock text-primary"></i> Session
                    Duration : {list.sessionDuration}
                  </h5>
                </div>

                <div className="strip-blocks border-right-0">
                  <h5>
                    <i class="fa fa-calendar" aria-hidden="true"></i>{" "}
                    {moment(list.dateTime).format("LL")}
                  </h5>
                </div>
              </div>
            </div>

            {/* Course Image */}

            <div className="course-content-strip bg-white mobile-price-cr">
              <div class="col-sm-10 mx-auto cd-pricing row course-content-wrap m-0 justify-content-center">
                <div className="strip-blocks mb-0">
                  <h5>Price: FREE</h5>
                </div>

                <div className="strip-blocks border-right-0 ">
                  {list.courseType === 3 ? (
                    <button
                      data-toggle="modal"
                      data-target="#loginModal"
                      className="btn btn-default btn-radius"
                      style={
                        GetUserLogin.isAuthenticated()
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      Watch Masterclass Now
                    </button>
                  ) : GetUserLogin.isAuthenticated() ? (
                    <button
                      className="btn btn-default btn-sm"
                      data-target="#loginModal"
                      onClick={this.handleLoginCheckMaster}
                      disabled={loading}
                    >
                      {loading && <i className="fa fa-refresh fa-spin"></i>}
                      {loading && <span> Reserve My Spot Now </span>}
                      {!loading && <span> Reserve My Spot Now </span>}
                    </button>
                  ) : (
                    <button
                      className="btn btn-default btn-sm"
                      data-toggle="modal"
                      data-target="#loginModal"
                      display={loading}
                    >
                      {loading && <i className="fa fa-refresh fa-spin"></i>}
                      {loading && <span> Reserve My Spot Now </span>}
                      {!loading && <span> Reserve My Spot Now </span>}
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="container">
              <div className="col-sm-10 mx-auto course-content-wrap">
                {/* Course Description */}
                <p className="list-desc">{list.description}</p>
                {/* Course Description  */}

                {/* <div className="course-content-strip bg-white">
              <div class="col-sm-10 mx-auto cd-pricing row course-content-wrap m-0 justify-content-center">
                <div className="strip-blocks mb-0">
                  <h5>Price: FREE</h5>
                </div>


                <div className="strip-blocks border-right-0">
                  {
                    list.courseType === 3 ? <button data-toggle="modal" data-target="#loginModal" className="btn btn-default btn-radius" style={GetUserLogin.isAuthenticated() ? { display: 'none' } : { display: 'block' }}>Watch Masterclass Now</button> : GetUserLogin.isAuthenticated() ? <button className="btn btn-default btn-sm" data-target="#loginModal" onClick={this.handleLoginCheckMaster} disabled={loading}>
                      {loading && <i className="fa fa-refresh fa-spin"></i>}
                      {loading && <span>  Reserve My Spot Now </span>}
                      {!loading && <span> Reserve My Spot Now  </span>}

                    </button> : <button className="btn btn-default btn-sm" data-toggle="modal" data-target="#loginModal" display={loading}>
                        {loading && <i className="fa fa-refresh fa-spin"></i>}
                        {loading && <span>  Reserve My Spot Now </span>}
                        {!loading && <span> Reserve My Spot Now  </span>}
                      </button>
                  }

                </div>

              </div>
            </div> */}
                {/* Course Details */}
                {list.about ? (
                  <div className="course-abt">
                    {/* Course Details */}
                    {parse(list.about)}
                    {/* Course Details */}
                  </div>
                ) : null}
                {/* Course Details */}
              </div>

              {/* <div className="text-center mt-5">
                {
                  list.courseType === 3 ? <button data-toggle="modal" data-target="#loginModal" className="btn btn-default btn-radius mx-auto" style={GetUserLogin.isAuthenticated() ? { display: 'none' } : { display: 'block' }}>Watch Masterclass Now</button> : GetUserLogin.isAuthenticated() ? <button className="btn btn-default btn-sm" data-target="#loginModal" onClick={this.handleLoginCheckMaster} disabled={loading}>
                    {loading && <i className="fa fa-refresh fa-spin"></i>}
                    {loading && <span>  Reserve My Spot Now </span>}
                    {!loading && <span> Reserve My Spot Now  </span>}
                  </button> : <button className="btn btn-default btn-sm" data-toggle="modal" data-target="#loginModal" disabled={loading}>
                      {loading && <i className="fa fa-refresh fa-spin"></i>}
                      {loading && <span>  Reserve My Spot Now </span>}
                      {!loading && <span> Reserve My Spot Now  </span>}
                    </button>
                }
              </div> */}
              {/* END SECTION ACCORDIONS */}
            </div>
          </section>

          <section className="pt-4 pb-3">
            {list.user ? (
              <div className="container mt-5">
                <div className="col-sm-11 mx-auto">
                  <div class="heading_s2 text-center pb-4">
                    <h3>
                      Your <span class="text-primary">Teacher</span>
                    </h3>
                  </div>

                  <div className="shadow py-4 px-3 mx-auto row align-items-center ">
                    <div
                      className="col-md-7 animation "
                      data-animation="fadeInUp"
                      data-animation-delay="0.3s"
                    >
                      <h5 className="font-weight-bold">
                        {list.user.firstName + " " + list.user.lastName}
                      </h5>
                      <p> {list.user.teacherDescription}</p>
                    </div>

                    <div
                      className="col-md-5 animation"
                      data-animation="fadeInUp"
                      data-animation-delay="0.2s"
                    >
                      <img
                        src={list.user.teacherPhotoUrl}
                        className="w-100"
                        alt="about_img11"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="course-content-strip bg-white">
              <div class="col-sm-10 mx-auto cd-pricing row course-content-wrap m-0 justify-content-center">
                <div className="strip-blocks mb-0">
                  <h5>Price: FREE</h5>
                </div>

                <div className="strip-blocks border-right-0">
                  {list.courseType === 3 ? (
                    <button
                      data-toggle="modal"
                      data-target="#loginModal"
                      className="btn btn-default btn-radius"
                      style={
                        GetUserLogin.isAuthenticated()
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      Watch Masterclass Now
                    </button>
                  ) : GetUserLogin.isAuthenticated() ? (
                    <button
                      className="btn btn-default btn-sm"
                      data-target="#loginModal"
                      onClick={this.handleLoginCheckMaster}
                      disabled={loading}
                    >
                      {loading && <i className="fa fa-refresh fa-spin"></i>}
                      {loading && <span> Reserve My Spot Now </span>}
                      {!loading && <span> Reserve My Spot Now </span>}
                    </button>
                  ) : (
                    <button
                      className="btn btn-default btn-sm"
                      data-toggle="modal"
                      data-target="#loginModal"
                      display={loading}
                    >
                      {loading && <i className="fa fa-refresh fa-spin"></i>}
                      {loading && <span> Reserve My Spot Now </span>}
                      {!loading && <span> Reserve My Spot Now </span>}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* Workshop Details  */}
        </div>
      </div>
    );
  }
}
