import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import { CartHelper, Coupon, GetCourseDetail } from "../../../../services";
import Stripepayment from "./PaymentStripe";
import StripeCardpayment from "./PaymentStripe/Card";
export default class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPrice: "",
      name: "",
      user: "",
      cartList: [],
      couponcode: "",
      toggle: false,
      countryList: "",
      locationName: "",
      comboList: "",
    };

    if (window.performance) {
      if (performance.navigation.type == 1) {
        sessionStorage.removeItem("coupon");
      }
    }
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  async getComboOfferList() {
    let cart = await CartHelper.getCart();
    if (cart) {
      let data = {
        firstCourseId: cart[0].id,
        secondCourseId: cart[1] ? cart[1].id : null,
      };
      let comboList = await GetCourseDetail.getComboOffers(data);
      if (comboList) {
        this.setState({ comboList: comboList });
      }
    }
  }
  getPayTm = async (event) => {
    event.preventDefault();
    // console.log(this.state.cartList)
    let ids = [];
    this.state.cartList.map((data) => {
      ids.push(data.id);
    });
    let data = {
      userId: this.state.user.userId,
      courseIds: ids,
      amount: this.state.totalPrice,
    };
    try {
      let payment = await Coupon.getPaytmDetails(data);
      console.log();
      if (payment) {
        window.open(`${payment}`, "_blank");
      }
    } catch (err) {
      console.log(err);
    }
  };
  async componentDidMount() {
    // this.getComboOfferList();
    let countryList = await GetCourseDetail.getCountryLisByIp();
    if (countryList) {
      const myString = `${countryList}`;
      let countryName = myString
        .split("\n")
        .filter((el) => el.startsWith("loc"))
        .join("\n");
      if (countryName.toString() === "loc=IN") {
        this.setState({ locationName: "India" });
        sessionStorage.setItem("locationName", "India");
      }
    }
    let cart = await CartHelper.getCart();
    const total = cart.reduce((prev, next) => prev + next.price, 0);
    this.setState({ totalPrice: total, cartList: cart });
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    let { name, totalPrice, cartList } = this.state;
    try {
      let countryList = await GetCourseDetail.getCountryLisByIp();
      let countryName;
      if (countryList) {
        const myString = `${countryList}`;
        countryName = myString
          .split("\n")
          .filter((el) => el.startsWith("loc"))
          .join("\n");
      }
      const ids = cartList.map((cart) => cart.id);
      const data = await GetCourseDetail.getStripeLink(ids, countryName);
      console.log(data);
      console.log(ids, countryName);
    } catch (error) {}
  };
  handleToken = async (token) => {
    let ids = [];
    this.state.cartList.map((data) => {
      ids.push(data.id);
    });
    let data = {
      stripeToken: token.id,
      userId: this.state.user.userId,
      courseIds: ids,
      amount: this.state.totalPrice * 100,
      currency: "eur",
    };
    let payment = await Coupon.getPaymentDetails(data);
    if (payment) {
      NotificationManager.success("Successfully Payment", "Payment");
      setTimeout(async function () {
        await CartHelper.emptyCart();
      }, 1000);
    } else {
      NotificationManager.error("Payment is declined", "Payment");
      setTimeout(async function () {
        window.location.href = "/failed";
      }, 1000);
    }
  };
  handleRemove = () => {
    sessionStorage.removeItem("coupon");
    window.location.reload();
  };
  render() {
    const {
      totalPrice,
      cartList,
      couponcode,
      toggle,
      locationName,
      comboList,
    } = this.state;
    return (
      <div>
        <section className="breadcrumb-bg lead-hide">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title">
                  <h1>Checkout</h1>
                  {/* <p className="col-sm-6 mx-auto">Please enter your details to proceed with the payment</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-3">
          {cartList.length ? (
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <div className="table-responsive shop_cart_table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="product-thumbnail">Product Image</th>
                          <th className="product-name">Product</th>
                          <th className="product-price">Price</th>
                          <th className="product-subtotal">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartList.map((row, i) => (
                          <tr key={i}>
                            <td className="product-thumbnail">
                              <img
                                src={row.thumbnailUrl}
                                alt="product1"
                                height="65"
                              />
                            </td>
                            <td className="product-name" data-title="Product">
                              {row.courseTitle}
                            </td>
                            <td className="product-price" data-title="Price">
                              <span>
                                {row.symbol}
                                {row.price}
                              </span>
                            </td>
                            <td className="product-subtotal" data-title="Total">
                              <span>
                                {row.symbol}
                                {row.price}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="small_padding bg_gray">
                    <div className="heading_s2">
                      <h5>Cart Totals</h5>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        {comboList.id ? (
                          <tbody>
                            <tr>
                              <td className="cart_total_label">
                                Cart Subtotal
                              </td>
                              <td className="cart_total_amount">
                                {locationName === "India" ? (
                                  <span>
                                    <strike>₹{totalPrice}</strike>
                                  </span>
                                ) : (
                                  <span>
                                    <strike>€{totalPrice}</strike>
                                  </span>
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td className="cart_total_label">Combo Price</td>
                              <td className="cart_total_amount">
                                {locationName === "India" ? (
                                  <span>₹{comboList.indianPrice}</span>
                                ) : (
                                  <span>€{comboList.otherPrice}</span>
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td className="cart_total_label">Total</td>
                              <td className="cart_total_amount">
                                {locationName === "India" ? (
                                  <span>₹{comboList.indianPrice}</span>
                                ) : (
                                  <span>€{comboList.otherPrice}</span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="cart_total_label">
                                Cart Subtotal
                              </td>
                              <td className="cart_total_amount">
                                {locationName === "India" ? (
                                  <span>₹{totalPrice}</span>
                                ) : (
                                  <span>€{totalPrice}</span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="cart_total_label">Total</td>
                              <td className="cart_total_amount">
                                <strong>
                                  {locationName === "India" ? (
                                    <span>₹{totalPrice}</span>
                                  ) : (
                                    <span>€{totalPrice}</span>
                                  )}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>

                    {comboList.id ? (
                      ""
                    ) : (
                      <div className="coupon field_form input-group mt-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Coupon Code.."
                          name="name"
                          value={this.state.name}
                          onChange={(e) => this.handleChange(e)}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-default btn-sm"
                            type="submit"
                            onClick={this.handleSubmit}
                            style={
                              toggle
                                ? { display: "none" }
                                : { display: "block" }
                            }
                          >
                            Apply Coupon
                          </button>

                          <button
                            className="btn btn-danger btn-sm"
                            type="submit"
                            onClick={this.handleRemove}
                            style={
                              toggle
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            Remove Coupon
                          </button>
                        </div>
                      </div>
                    )}

                    <p
                      className="text-success mt-2"
                      style={
                        toggle ? { display: "block" } : { display: "none" }
                      }
                    >
                      {" "}
                      Coupon Applied Successfully{" "}
                    </p>

                    {
                      locationName === "India" ? (
                        <div class="mt-3">
                          <StripeCardpayment />
                        </div>
                      ) : (
                        <div className="tab-style1 payment-tab">
                          <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                id="home-tab"
                                data-toggle="tab"
                                href="#home"
                                role="tab"
                                aria-controls="home"
                                aria-selected="true"
                              >
                                Pay With Ideal
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab"
                                data-toggle="tab"
                                href="#profile"
                                role="tab"
                                aria-controls="profile"
                                aria-selected="false"
                              >
                                Pay With Card
                              </a>
                            </li>
                          </ul>

                          <div className="tab-content shop_info_tab">
                            <div
                              className="tab-pane fade active show"
                              id="home"
                              role="tabpanel"
                              aria-labelledby="home-tab"
                            >
                              {/* Ideal bank select */}
                              <div class="mt-3">
                                <Stripepayment />
                              </div>
                              {/* end select */}
                            </div>
                            <div
                              className="tab-pane fade"
                              id="profile"
                              role="tabpanel"
                              aria-labelledby="profile-tab"
                            >
                              <div class="mt-3">
                                <StripeCardpayment />
                              </div>
                              {/* <StripeCheckout
                              stripeKey="pk_live_51HDU1QAJ8SCDPxTCCAtpZFlkQUTyABaDEwsYWjP1YleHUIqg0FnRjzbe3VhvbysqhVu8ewTCfZPqfv3enSmrClYu00c24g7O40"
                              token={this.handleToken}
                              amount={totalPrice * 100}
                              currency="eur"
                            /> */}
                            </div>
                          </div>
                        </div>
                      )
                      /* //     :
                      //     <div className="">
                      //       <button className="btn btn-default w-100 mt-4 py-3" onClick={this.getPayTm}>Pay Now</button>
                      //     </div>
                      // } */
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <h4>Your Cart Is Empty !</h4>
            </div>
          )}
        </section>
      </div>
    );
  }
}
