import React, { Component } from 'react'

export default class Cookie extends Component {
    render() {
        return (
            <div>
              
  {/* --------------------- Contact Page Starts --------------------------*/}

    {/* START SECTION BREADCRUMB */}
    <section className="breadcrumb-bg lead-hide">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-sm-12 text-center">
          <div className="page-title">
            <h1>Cookie Policy</h1>
          </div>
         
        </div>
      </div>
    </div>
  </section>
  {/* END SECTION BREADCRUMB */}


  {/* START SECTION CONTACT */}
  <section  className="pt-5">
    <div className="container">
       
       <h6>BY CONTINUING TO USE THIS SITE AND SERVICES, YOU ARE AGREEING TO THE USE OF COOKIES AND SIMILAR TECHNOLOGIES FOR THE PURPOSES WE DESCRIBE IN THIS COOKIE POLICY. IF YOU DO NOT ACCEPT THE USE OF COOKIES AND SIMILAR TECHNOLOGIES, DO NOT USE THIS SITE.</h6>
       
        <div className="text-block mt-3">
          <p>This Site uses cookies to better the users experience while visiting the Site. Where applicable this Site uses a cookie control system allowing the user on their first visit to the Site to allow or disallow the use of cookies on their computer / device. This complies with recent legislation requirements for Sites to obtain explicit consent from users before leaving behind or reading files such as cookies on a user’s computer / device.</p>
          <p>This Cookie Policy sets out how we, use cookies and similar technologies on this Site. We may update this Cookie Policy from time to time in order to keep you fully informed about our latest practices involving cookies and similar technologies on this Site. You should check this Cookie Policy each time you visit our Site in order to find out whether our use of cookies and similar technologies has changed.</p>
        </div>

        <div className="text-block">
          <h5>ABOUT COOKIES</h5>
          <p>Cookies are data files that are sent between web servers and web browsers, processor memory or hard drives (clients) to recognize a particular user’s device when the user accesses a website. They are used for a range of different purposes, such as customizing a website for a particular user, helping a user navigate a website, improving that user’s website experience, and storing that user’s preferences and login information.</p>
          <p>Cookies can be classified as either ‘session’ or ‘persistent’ cookies. Session cookies are placed on your browser when you access a website and last for as long as you keep your browser open. They expire when you close your browser.</p>
          <p>Persistent cookies are placed on your computer when you access a website and expire at a fixed point in time or if you manually delete them from your browser, whichever occurs first.</p>
          <p>Cookies will be set either by our Site domain or by third party domains on our behalf. Cookies set by us on our Site domain are referred to as ‘first party’ cookies. Cookies set by third party domains, or set on or via our domain on behalf of third parties, are referred to as ‘third party’ cookies.</p>
         <p>Cookies do not usually contain personal information. Cookies may, however, be used in combination with other information to identify you.</p>
         <p>We use first party and third party cookies on our Site, and both session and persistent cookies If you require further information about cookies in general, please visit www.allaboutcookies.org</p>
        </div>


        <div className="text-block">
          <h5>Types of Cookies</h5>

          <div className="table-responsive">
                <table className="table text-left">

                <thead>

                <tr>
                    <th>Type of Cookie</th>
                    <th>What do they do?</th>
                    <th>Do these cookies collect my personal data / identify me?</th>
                  </tr>

                  </thead>
                <tbody>
                  
                  <tr>
                    <td>Essential</td>
                    <td>These cookies are essential to make our Site work correctly, they enable you to move around our Site and use our features. Examples include remembering previous actions (e.g. entered text) when navigating back to a page in the same session.</td>
                    <td>These cookies don’t identify you as an individual. If you do not accept these cookies, it may affect the performance of the Site, or parts of it.</td>
                  </tr>
                  <tr>
                    <td><span>Performance</span></td>
                    <td><span>These cookies help us understand how visitors interact with our Site by providing information about the areas visited, the time spent on the Site, and any issues encountered, such as error messages. This helps us improve the performance of our Site.<br />&nbsp;We might use information that we hold about you to show you relevant and "targeted advertising" through other companies' sites, like Google and Facebook.</span></td>
                    <td><span>These cookies don’t identify you as an individual. All data is collected and aggregated anonymously.</span></td>
                    
                  </tr>
                  <tr>
                    <td><span>Functionality</span></td>
                    <td><span>These cookies allow our Site to remember the choices you make (such as your user name, language or the region you are in) to provide a more personalized online experience. They can also enable visitors to watch videos, play games and engage with social tools, such as blogs, chatrooms </span><span>and</span><span> forums.</span></td>
                    <td><span>The information these cookies collect may include personally identifiable information that you have disclosed, such as your username or profile picture. We shall always be transparent with you about what information we collect, what we do with it and with whom we share it.<br />&nbsp;If you do not accept these cookies, it may affect the performance and functionality of the Site and may restrict access to content on the Site.</span></td>
                    
                  </tr>
                </tbody>
              </table>
            </div>

        </div>



        <div className="text-block">
          <h5>OTHER TRACKING TECHNOLOGIES</h5>
         <p>In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies on the Site to help customize the Site and improve your experience. A “web beacon” or “pixel tag” is tiny object or image embedded in a web page or email. They are used to track the number of users who have visited particular pages and viewed emails, and acquire other statistical data. They collect only a limited set of data, such as a cookie number, time and date of page or email view, and a description of the page or email on which they reside. Web beacons and pixel tags cannot be declined. However, you can limit their use by controlling the cookies that interact with them.</p>
        </div>



        <div className="text-block">
          <h5>CONTROL OF COOKIES</h5>
         <p>Most browsers are set to accept cookies by default. However, you can remove or reject cookies in your browser’s settings. Please be aware that such action could affect the availability and functionality of the Site.</p>
        </div>

        



    </div>
  </section>
  {/* END SECTION CONTACT */}
 

  {/* --------------------- Contact Page Starts --------------------------*/}

            </div>
        )
    }
}
