import React, { Component } from 'react'
import { MyCourses } from '../../../../services';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import moment from 'moment';
export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: ''
    };
  }
  async componentDidMount() {
    let list = await MyCourses.getAllBlogsList();
    if (list) {
      this.setState({ list: list })
    }
  }


  
  render() {
    const { list } = this.state;
    return (
      <div>
        {/* --------------------- About Page Starts --------------------------*/}
        <section className="breadcrumb-bg lead-hide">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title page-title-custom">
                  <h1>Blogs</h1>
                 
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* START SECTION ABOUT */}
        <section className="pt-2">

          <div className="container">

          <div className="tab-style-custom">
           
           <div className="text-center">
              <ul className="nav nav-tabs " role="tablist">
               

                <li className="nav-item">
                  <a className="nav-link active" id="home0-tab" data-toggle="tab" href="#home0" role="tab" aria-controls="home0" aria-selected="true">All Blogs</a>
                </li>

               {/* Loop-1 */}
                {
                  list ?
                    list.map((row, index) => (
                      row.categoryId.toString() === "1" ?
                        <li className="nav-item" key={index}>
                          <a className="nav-link" id="home1-tab" data-toggle="tab" href="#home1" role="tab" aria-controls="home1" aria-selected="false">Vaidic Astrology</a>
                        </li> : ''
                    )) : ''}
               

                {/* Loop-2 */}
                {
                  list ?
                    list.map((row, index) => (
                      row.categoryId.toString() === "2" ?
                <li className="nav-item">
                  <a className="nav-link" id="home2-tab" data-toggle="tab" href="#home2" role="tab" aria-controls="home2" aria-selected="false">Ayurveda</a>
                  </li> : ''
                    )) : ''}
               

                {/* Loop-3 */}
             
                <li className="nav-item">
                  <a className="nav-link" id="home3-tab" data-toggle="tab" href="#home3" role="tab" aria-controls="home3" aria-selected="false">Health & Wellness</a>
                  </li> 

                 {/* Loop-4 */} 
                 {
                  list ?
                    list.map((row, index) => (
                      row.categoryId.toString()  === "4" ?
                <li className="nav-item">
                  <a className="nav-link" id="home4-tab" data-toggle="tab" href="#home4" role="tab" aria-controls="home4" aria-selected="false">Recipies</a>
                  </li> : ''
                    )) : ''}
               

                {/* Loop-5 */}
                {
                  list ?
                    list.map((row, index) => (
                      row.categoryId.toString() === "5" ?
                <li className="nav-item">
                  <a className="nav-link" id="home5-tab" data-toggle="tab" href="#home5" role="tab" aria-controls="home5" aria-selected="false">Wisdom & Spirituality</a>
                  </li> : ''
                    )) : ''}
                
                 {/* Loop-6 */}
                 {
                  list ?
                    list.map((row, index) => (
                      row.categoryId === 6 ?
                <li className="nav-item">
                  <a className="nav-link" id="home6-tab" data-toggle="tab" href="#home6" role="tab" aria-controls="home6" aria-selected="false">Yoga & Meditation</a>
                  </li> : ''
                    )) : ''}
                
                 {/* Loop-7 */}
                 {
                  list ?
                    list.map((row, index) => (
                      row.categoryId === 7 ?
                <li className="nav-item">
                  <a className="nav-link" id="home7-tab" data-toggle="tab" href="#home7" role="tab" aria-controls="home7" aria-selected="false">Mindfulness</a>
                  </li> : ''
                    )) : ''}
                
                 {/* Loop-8 */}
                 {
                  list ?
                    list.map((row, index) => (
                      row.categoryId === 8 ?
                <li className="nav-item">
                  <a className="nav-link" id="home8-tab" data-toggle="tab" href="#home8" role="tab" aria-controls="home8" aria-selected="false">Articles</a>
                  </li> : ''
                    )) : ''}

              </ul>
              </div>

              <div className="tab-content shop_info_tab mt-5">


                 {/* Tab - Loop 0 */}

                 <div className="tab-pane fade active show" id="home0" role="tabpanel" aria-labelledby="home0-tab">
                  <div className="row ">
                    {
                      list ?
                        list.map((row, index) => (
                            <div className="col-lg-4 col-md-6" key={index}>
                              <Link to={{
                                pathname: `/blog-view/${row.title}/${row.id}`,
                                state: row
                              }}>
                                <div className="blog_post box_shadow4">
                                  <div className="blog_img">
                                    <img src={row.imageUrl} alt="blog_small_img1" />
                                  </div>
                                  <div className="blog_content">
                                    <h5 className="blog_title">{row.title}</h5>
                                    <span className="dt-blg"><i className="far fa-calendar"/> {moment(row.blogDate).format('LL')}</span>
                                    <p className="blog-desc">{parse(row.details)}</p>
                                  </div>
                                </div>
                              </Link>
                            </div> 
                        ))
                        : ''
                    }
                  </div>
                </div>
                {/* Tab - Loop 0 */}


                {/* Tab - Loop 1 */}

                <div className="tab-pane fade" id="home1" role="tabpanel" aria-labelledby="home1-tab">
                  <div className="row ">
                    {
                      list ?
                        list.map((row, index) => (
                          row.categoryId === 1 ?
                            <div className="col-lg-4 col-md-6" key={index}>
                              <Link to={{
                                pathname: `/blog-view/${row.title}/${row.id}`,
                                state: row
                              }}>
                                <div className="blog_post box_shadow4">
                                  <div className="blog_img">
                                    <img src={row.imageUrl} alt="blog_small_img1" />
                                  </div>
                                  <div className="blog_content">
                                    <h5 className="blog_title">{row.title}</h5>
                                    <span className="dt-blg"><i className="far fa-calendar"/> {moment(row.blogDate).format('LL')}</span>
                                    <p className="blog-desc">{parse(row.details)}</p>
                                  </div>
                                </div>
                              </Link>
                            </div> : ''
                        ))
                        : ''
                    }
                  </div>
                </div>
                {/* Tab - Loop 1 */}


                {/* Tab - Loop 2*/}

                <div className="tab-pane fade " id="home2" role="tabpanel" aria-labelledby="home2-tab">
                  <div className="row ">
                    {
                      list ?
                        list.map((row, index) => (
                          row.categoryId === 2 ?
                            <div className="col-lg-4 col-md-6" key={index}>
                              <Link to={{
                                pathname: `/blog-view/${row.title}/${row.id}`,
                                state: row
                              }}>
                                <div className="blog_post box_shadow4">
                                  <div className="blog_img">
                                    <img src={row.imageUrl} alt="blog_small_img1" />
                                  </div>
                                  <div className="blog_content">
                                    <h5 className="blog_title">{row.title}</h5>
                                    <span className="dt-blg"><i className="far fa-calendar"/> {moment(row.blogDate).format('LL')}</span>
                                    <p className="blog-desc">{parse(row.details)}</p>
                                  </div>
                                </div>
                              </Link>
                            </div> : ''
                        ))
                        : ''
                    }
                  </div>
                </div>
                {/* Tab - Loop 2 */}


                {/* Tab - Loop 3*/}

                <div className="tab-pane fade " id="home3" role="tabpanel" aria-labelledby="home3-tab">
                  <div className="row ">
                    {
                      list ?
                        list.map((row, index) => (
                          row.categoryId === 3 ?
                            <div className="col-lg-4 col-md-6" key={index}>
                              <Link to={{
                                pathname: `/blog-view/${row.title}/${row.id}`,
                                state: row
                              }}>
                                <div className="blog_post box_shadow4">
                                  <div className="blog_img">
                                    <img src={row.imageUrl} alt="blog_small_img1" />
                                  </div>
                                  <div className="blog_content">
                                    <h5 className="blog_title">{row.title}</h5>
                                    <span className="dt-blg"><i className="far fa-calendar"/> {moment(row.blogDate).format('LL')}</span>
                                    <p className="blog-desc">{parse(row.details)}</p>
                                  </div>
                                </div>
                              </Link>
                            </div> : ''
                        ))
                        : ''
                    }
                  </div>
                </div>
                {/* Tab - Loop 3 */}

                {/* Tab - Loop 4*/}

                <div className="tab-pane fade " id="home4" role="tabpanel" aria-labelledby="home4-tab">
                  <div className="row ">
                    {
                      list ?
                        list.map((row, index) => (
                          row.categoryId === 4 ?
                            <div className="col-lg-4 col-md-6" key={index}>
                              <Link to={{
                                pathname: `/blog-view/${row.title}/${row.id}`,
                                state: row
                              }}>
                                <div className="blog_post box_shadow4">
                                  <div className="blog_img">
                                    <img src={row.imageUrl} alt="blog_small_img1" />
                                  </div>
                                  <div className="blog_content">
                                    <h5 className="blog_title">{row.title}</h5>
                                    <span className="dt-blg"><i className="far fa-calendar"/> {moment(row.blogDate).format('LL')}</span>
                                    <p className="blog-desc">{parse(row.details)}</p>
                                  </div>
                                </div>
                              </Link>
                            </div> : ''
                        ))
                        : ''
                    }
                  </div>
                </div>
                {/* Tab - Loop 4 */}

                {/* Tab - Loop 5*/}

                <div className="tab-pane fade " id="home5" role="tabpanel" aria-labelledby="home5-tab">
                  <div className="row ">
                    {
                      list ?
                        list.map((row, index) => (
                          row.categoryId === 5 ?
                            <div className="col-lg-4 col-md-6" key={index}>
                              <Link to={{
                                pathname: `/blog-view/${row.title}/${row.id}`,
                                state: row
                              }}>
                                <div className="blog_post box_shadow4">
                                  <div className="blog_img">
                                    <img src={row.imageUrl} alt="blog_small_img1" />
                                  </div>
                                  <div className="blog_content">
                                    <h5 className="blog_title">{row.title}</h5>
                                    <span className="dt-blg"><i className="far fa-calendar"/> {moment(row.blogDate).format('LL')}</span>
                                    <p className="blog-desc">{parse(row.details)}</p>
                                  </div>
                                </div>
                              </Link>
                            </div> : ''
                        ))
                        : ''
                    }
                  </div>
                </div>
                {/* Tab - Loop 5 */}

                {/* Tab - Loop 6*/}

                <div className="tab-pane fade " id="home6" role="tabpanel" aria-labelledby="home6-tab">
                  <div className="row ">
                    {
                      list ?
                        list.map((row, index) => (
                          row.categoryId === 6 ?
                            <div className="col-lg-4 col-md-6" key={index}>
                              <Link to={{
                                pathname: `/blog-view/${row.title}/${row.id}`,
                                state: row
                              }}>
                                <div className="blog_post box_shadow4">
                                  <div className="blog_img">
                                    <img src={row.imageUrl} alt="blog_small_img1" />
                                  </div>
                                  <div className="blog_content">
                                    <h5 className="blog_title">{row.title}</h5>
                                    <span className="dt-blg"><i className="far fa-calendar"/> {moment(row.blogDate).format('LL')}</span>
                                    <p className="blog-desc">{parse(row.details)}</p>
                                  </div>
                                </div>
                              </Link>
                            </div> : ''
                        ))
                        : ''
                    }
                  </div>
                </div>
                {/* Tab - Loop 6 */}


                {/* Tab - Loop 7*/}

                <div className="tab-pane fade " id="home7" role="tabpanel" aria-labelledby="home7-tab">
                  <div className="row ">
                    {
                      list ?
                        list.map((row, index) => (
                          row.categoryId === 7 ?
                            <div className="col-lg-4 col-md-6" key={index}>
                              <Link to={{
                                pathname: `/blog-view/${row.title}/${row.id}`,
                                state: row
                              }}>
                                <div className="blog_post box_shadow4">
                                  <div className="blog_img">
                                    <img src={row.imageUrl} alt="blog_small_img1" />
                                  </div>
                                  <div className="blog_content">
                                    <h5 className="blog_title">{row.title}</h5>
                                    <span className="dt-blg"><i className="far fa-calendar"/> {moment(row.blogDate).format('LL')}</span>
                                    <p className="blog-desc">{parse(row.details)}</p>
                                  </div>
                                </div>
                              </Link>
                            </div> : ''
                        ))
                        : ''
                    }
                  </div>
                </div>
                {/* Tab - Loop 7 */}


                {/* Tab - Loop 8*/}

                <div className="tab-pane fade " id="home8" role="tabpanel" aria-labelledby="home8-tab">
                  <div className="row ">
                    {
                      list ?
                        list.map((row, index) => (
                          row.categoryId === 8 ?
                            <div className="col-lg-4 col-md-6" key={index}>
                              <Link to={{
                                pathname: `/blog-view/${row.title}/${row.id}`,
                                state: row
                              }}>
                                <div className="blog_post box_shadow4">
                                  <div className="blog_img">
                                    <img src={row.imageUrl} alt="blog_small_img1" />
                                  </div>
                                  <div className="blog_content">
                                    <h5 className="blog_title">{row.title}</h5>
                                    <span className="dt-blg"><i className="far fa-calendar"/> {moment(row.blogDate).format('LL')}</span>
                                    <p className="blog-desc">{parse(row.details)}</p>
                                  </div>
                                </div>
                              </Link>
                            </div> : ''
                        ))
                        : ''
                    }
                  </div>
                </div>
                {/* Tab - Loop 8 */}



              </div>
            </div>






                   </div>

        
        </section>
        {/* END SECTION ABOUT */}









        {/* --------------------- About Page Ends --------------------------*/}

      </div>
    )
  }
}
