import { NotificationManager } from "react-notifications";
import { Apis } from "../../../config";
import api from "../../ApiConfig";

const getAllWorkshop = async () => {
  try {
    let result = await api.get(Apis.GetAllWorkshop);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const GetAllWorkshopsHome = async () => {
  try {
    let result = await api.get(`${Apis.GetAllWorkshopsHome}?page=1&limit=9`);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getAllLiveSession = async () => {
  try {
    let result = await api.get(Apis.GetAllLiveSession);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getAllBanners = async () => {
  try {
    let result = await api.get(Apis.GetAllBanners);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getAllProgramList = async (countryName) => {
  try {
    let result = await api.get(
      `${Apis.GetAllProgramList}?${countryName}&page=1&limit=1000`
    );
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};
const getAllList = async () => {
  try {
    let result = await api.get(Apis.GetAllList);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getAllConsultant = async (countryName) => {
  try {
    let result = await api.get(
      `${Apis.public.GetAllConsultant}?${countryName}&page=1&limit=1000`
    );
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getPublicCourseById = async (id, countryName) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.get(
      Apis.public.GetSingleCourseById + `${id}?${countryName}`,
      {
        headers: headers,
      }
    );
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getCourseById = async (id) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.get(Apis.GetCourseById + `${id}`, {
      headers: headers,
    });
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getPdfByFileName = async (fileName) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.get(Apis.GetPdfLink + `${fileName}.pdf`, {
      headers: headers,
    });
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getCourseByCostmerId = async (id) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.get(Apis.GetCourseByIdCustomer + `${id}`, {
      headers: headers,
    });
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getCurriculamVideoUrl = async (id) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.get(Apis.GetCurriculamVideoUrl + `${id}`, {
      headers: headers,
    });
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getCourseDetailById = async (id, countryName) => {
  try {
    let result = await api.get(
      Apis.GetCourseDetailById + `${id}?${countryName}`
    );
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getCountryLisByIp = async () => {
  try {
    let result = await api.get(Apis.GetCountryLisByIp);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getComboOffers = async (data) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.post(Apis.GetComboOffers, data, {
      headers: headers,
    });
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getComboCourseDetail = async (id) => {
  try {
    let result = await api.post(Apis.GetComboCourseDetail + id);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getAllCategory = async () => {
  try {
    let result = await api.get(Apis.GetAllCategory);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getAllCoursePublicByCategoryId = async (id) => {
  try {
    let result = await api.get(
      `${Apis.public.getAllByCategoryId}${id}?page=1&limit=1000`
    );
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getAllProductById = async (id) => {
  try {
    let result = await api.get(`${Apis.GetCourseById}${id}`);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getStripeLink = async (ids, countryName) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.post(
      `${Apis.GetStripeLink}`,
      {
        courses: ids,
        country: countryName?.toString()?.replace("loc=", ""),
      },
      {
        headers: headers,
      }
    );
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};
const checkCourseAllready = async (id) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
    try {
      let { data } = await api.get(
        `${Apis.checkOrderAllreadyBeforeCart}${id}`,
        {
          headers: headers,
        }
      );
      return data?.exists;
    } catch (error) {
      return null;
    }
  }
  return false;
};

export default {
  getAllWorkshop,
  getAllLiveSession,
  getCourseById,
  getCurriculamVideoUrl,
  getCountryLisByIp,
  getComboOffers,
  getAllList,
  getAllProgramList,
  getAllConsultant,
  getComboCourseDetail,
  getCourseDetailById,
  getAllCategory,
  getAllProductById,
  getCourseByCostmerId,
  getPdfByFileName,
  getAllCoursePublicByCategoryId,
  getPublicCourseById,
  getStripeLink,
  checkCourseAllready,
  getAllBanners,
  GetAllWorkshopsHome,
};
