import { NotificationManager } from "react-notifications";
import { Apis } from "../../../config";
import api from "../../ApiConfig";

const getUserLogin = async (data) => {
  try {
    let result = await api.post(Apis.GetUserLogin, data);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    throw error;
  }
};

const getUserProfile = async () => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.get(Apis.GetCurrentUser, { headers });
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getNewSubscriber = async (data) => {
  try {
    let result = await api.post(Apis.GetNewSubscriber, data);
    console.log(result);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    if (result?.status == 201 || result?.status == 200) return true;
    return result.data;
  } catch (error) {
    return null;
  }
};

const getBlogById = async (data) => {
  try {
    let result = await api.post(Apis.GetBlogById + data);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getUserByEmail = async (email) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  setTimeout(function () {
    sessionStorage.removeItem("token");
  }, 1000 * 60 * 60);
  try {
    let result = await api.get(Apis.GetUserByEmail + email, {
      headers: headers,
    });
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getUserUpdate = async (data) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.patch(Apis.GetUpdateUser, data, {
      headers: headers,
    });
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getUpdatePassword = async (data) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") !== "undefined"
  ) {
    headers["Authorization"] = `Bearer ${sessionStorage.getItem("token")}`;
  }
  try {
    let result = await api.patch(Apis.GetUpdatePassword, data, {
      headers: headers,
    });
    NotificationManager.success("Successfully Update", "Password");

    return result.data;
  } catch (error) {
    NotificationManager.error(error?.response?.data?.message);
    return null;
  }
};

const getUserRegister = async (data) => {
  try {
    let result = await api.post(Apis.GetUserRegsiter, data);
    if (result.data.error) {
      NotificationManager.error(result.data.error);
      return null;
    }
    return result.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const authenticate = async (data, options = { reload: true }) => {
  if (typeof window !== "undefined") {
    sessionStorage.setItem("token", data.token);
    localStorage.setItem("email", data.email);
    localStorage.setItem("firstName", data.firstName);
    localStorage.setItem("lastName", data.lastName);
    localStorage.setItem("decentrizationId", data.decentrizationId);
    if (options.reload) window.location.reload();
    // window.location.href="/";
  } else {
    NotificationManager.error("Please check your login", "Input Error");
  }
};

const authenticateByCart = async (data, next) => {
  if (typeof window !== "undefined") {
    sessionStorage.setItem("token", data.token);
    localStorage.setItem("email", data.email);
    localStorage.setItem("firstName", data.firstName);
    localStorage.setItem("lastName", data.lastName);
    localStorage.setItem("decentrizationId", data.decentrizationId);
    window.location.href = "/cart";
  } else {
    NotificationManager.error("Please check your login", "Input Error");
  }
};

const logout = async (next) => {
  if (typeof window !== "undefined") {
    sessionStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("decentrizationId");
    window.location.href = "/";
    // next();
  }
};

const isAuthenticated = () => {
  if (typeof window === "undefined") {
    return false;
  }
  return sessionStorage.getItem("token");
};

const getUserResetPassword = async (data) => {
  try {
    let result = await api.post(Apis.GetUserResetPassword, data);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

const getUserUpdatePassword = async (data) => {
  try {
    let result = await api.patch(Apis.GetUserUpdatePassword, data);
    if (result.error) {
      NotificationManager.error(result.error);
      return null;
    }
    return result.data;
  } catch (error) {
    return null;
  }
};

export default {
  getUserLogin,
  authenticate,
  getUserRegister,
  logout,
  getNewSubscriber,
  getUserUpdate,
  isAuthenticated,
  getUserByEmail,
  getBlogById,
  authenticateByCart,
  getUserResetPassword,
  getUserUpdatePassword,
  getUserProfile,
  getUpdatePassword,
};
