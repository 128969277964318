import React, { Component } from "react";

export default class Email_Sent extends Component {
  render() {
    return (
      <div>
        {/* --------------------- Contact Page Starts --------------------------*/}

        {/* START SECTION BREADCRUMB */}
        <section className="breadcrumb-bg mt-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <div className="page-title">
                  <i class="fa fa-check tick-circle" aria-hidden="true"></i>

                  <h1>Account Created </h1>
                  <p className="col-sm-6 mx-auto">
                    An email has been sent to your registered email id. Please
                    click on the link inside email and verify your account.!{" "}
                  </p>
                  {/* <p className="col-sm-6 mx-auto">Your account has been successfully created with us. Please go to homepage and checkout our courses. </p> */}
                </div>

                <a href="/" class="btn btn-default btn-radius mt-4">
                  Continue Login{" "}
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION BREADCRUMB */}

        {/* START SECTION CONTACT */}
        <section className="pt-5">
          <div className="container">
            <div className="row"></div>
          </div>
        </section>
        {/* END SECTION CONTACT */}

        {/* --------------------- Contact Page Starts --------------------------*/}
      </div>
    );
  }
}
