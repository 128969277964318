import React, { Component } from 'react';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { NotificationManager } from 'react-notifications';
import { Coupon, CartHelper, GetUserLogin } from '../../../../../../services';
import './../IdealBankSection/IdealBankSectionStyles.css';


class CardPay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountholdername: '', user: '', cartList: [], loading: false, locationName: '', totalPrice: '', loading: false,
        }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    async componentDidMount() {

        this.state.locationName = sessionStorage.getItem('locationName')

        let email = localStorage.getItem('email');
        let user = await GetUserLogin.getUserByEmail(email);

        let cart = await CartHelper.getCart();

        if (user && cart) {
            let total = 0;

            if (this.state.locationName === "India") {
                total = cart.reduce((prev, next) => prev + next.indianPrice, 0);
                console.log('total-- ', total)
                this.setState({ totalPrice: total, user: user, cartList: cart })
            } else {
                total = cart.reduce((prev, next) => prev + next.price, 0);
                this.setState({ totalPrice: total, user: user, cartList: cart })
            }
        }
    }

    handleSubmit = async (e) => {

        e.preventDefault();

        let { totalPrice } = this.state;

        let couponPrice = parseInt(sessionStorage.getItem('coupon'))
        let grandtotal = totalPrice
        if (couponPrice) {
            let discount = couponPrice
            grandtotal = (Math.round(totalPrice - discount));
            //this.setState({ totalPrice: grandtotal })
        }

        let ids = [];
        this.state.cartList.map((data) => {
            ids.push(data.id)
        })

        let currency = this.state.locationName === "India" ? "inr" : "eur"

        let data = {
            userId: this.state.user.userId,
            courseIds: ids,
            currency: currency,
            paymentMethod: "card",
            amount: grandtotal * 100
        }

        if (data) {
            let secretkey = await Coupon.getPaymentSecret(data);

            if (secretkey & secretkey === "failed") {
                NotificationManager.error("Something went wrong!, Please try after sometime!", "Payment");
                return;
            }


            const { stripe, elements } = this.props
            const cardElement = elements.getElement(CardElement);

            if (!stripe || !elements) {
                return;
            }
            setTimeout(async () => {
                this.setState({ loading: false })
                const { error, paymentIntent } = await stripe.confirmCardPayment(secretkey, {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            name: this.state.accountholdername,
                        },
                    },
                    return_url: 'https://www.innercord.com/success',
                });

                if (error) {
                    if (error.decline_code && error.decline_code == "transaction_not_allowed") {
                        NotificationManager.error("Please activate international payment on your card", "Payment");
                    } else {
                        NotificationManager.error("Payment is declined", "Payment");
                    }

                    setTimeout(
                        async function () {
                            window.location.href = "/failed"
                        },
                        1000
                    );
                }

                if (paymentIntent && paymentIntent.status == 'succeeded') {
                    NotificationManager.success("Successfully Payment", "Payment");

                    setTimeout(
                        async function () {
                            let redirectUrl = '/success?payment_intent=' + paymentIntent.id + '&payment_intent_client_secret=' +
                                paymentIntent.client_secret + '&redirect_status=' + paymentIntent.status;
                            window.location.href = redirectUrl
                            await CartHelper.emptySuccessCart();
                        },
                        1000
                    );
                }
            }, 3000)
        } else {
            NotificationManager.error("Check Input Field", "Input");
        }
    };

    render() {
        const { stripe } = this.props;
        let { loading } = this.state;

        const IDEAL_ELEMENT_OPTIONS = {
            // Custom styling can be passed to options when creating an Element
            hidePostalCode: true,
            style: {
                base: {
                    padding: '10px 12px',
                    color: '#32325d',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#aab7c4'
                    },
                },
            },
        };
        return (

            <form className="w-100" onSubmit={this.handleSubmit} disabled={loading}>
                <div class="form-row m-0">
                    <label>
                        Name
                        <input
                            value={this.state.name}
                            name="accountholdername"
                            placeholder="Account Holder Name"
                            onChange={(e) => this.handleChange(e)}
                            required
                        />
                    </label>
                </div>
                <div className="form-row w-100 m-0 mt-2">
                    <label>
                        Card Details
                        <CardElement id="card" options={IDEAL_ELEMENT_OPTIONS} />
                    </label>
                </div>
                <button className="btn btn-primary w-100 mt-3" type="submit" disabled={!stripe}>
                    {loading && <i className="fa fa-refresh fa-spin"></i>}
                    {loading && <span>  Pay Now </span>}
                    {!loading && <span> Pay Now  </span>}
                </button>
            </form>
        )
    }

};

export default function InjectedCardPay() {

    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CardPay stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>

    )

}

