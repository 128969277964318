import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GetCourseDetail } from "../../../../services";
import { Pagination } from "@material-ui/lab";
export default class ProgramsCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programList: [],
      loading: true,
      loading: true,
      isArchived: false,
    };
  }
  async componentDidMount() {
    try {
      let { data: live } = await GetCourseDetail.getAllCoursePublicByCategoryId(
        this.props.id
      );
      this.setState({ programList: live || [], loading: false });
    } catch (error) {}
    this.setState({ loading: false });
  }
  render() {
    const { programList, loading, isArchived } = this.state;
    const updatedProgramList = programList.filter(
      (program) => program.isArchived === isArchived
    );
    return (
      <>
        <div className="d-inline-flex justify-content-center align-items-center w-100">
          <FormControlLabel
            style={{ padding: 0, margin: 0 }}
            control={
              <Switch
                checked={this.state.isArchived}
                color="primary"
                onChange={() =>
                  this.setState({
                    isArchived: !this.state.isArchived,
                  })
                }
                name="checkedA"
              />
            }
            label="Show Archived"
          />
        </div>
        <div className="row">
          {loading ? (
            <div
              className="d-inline-flex w-100 align-items-center justify-content-center"
              style={{ height: "30vh" }}
            >
              <CircularProgress />
            </div>
          ) : updatedProgramList.length > 0 ? (
            <>
              {updatedProgramList.map((row, index) => (
                <div className="col-md-4 col-sm-12" key={index}>
                  <Link
                    to={{
                      pathname: `/course-details/${row.slug}/${row.id}`,
                      state: row,
                    }}
                  >
                    <div className="yg-classes-wrap">
                      {row.isLive && (
                        <span className="category-wrap">
                          <i className="fa fa-dot-circle"></i>&nbsp;
                          {row.isFree && "Free "}
                          Live Session
                        </span>
                      )}
                      {!row.isLive && (
                        <span className="category-wrap">
                          <i class="fa fa-video" aria-hidden="true"></i> &nbsp;
                          {row.isFree && "Free "}
                          Recorded
                        </span>
                      )}

                      <span className="date-wrap">
                        <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                        {moment(row.dateTime).format("LL")}
                      </span>
                      <div className="consult-wrap">
                        <img
                          src={row.thumbnail}
                          className="img-fluid"
                          alt="thumbnail"
                        />
                      </div>
                      <div className="yg-classes-bottom-content">
                        <div className="yg-classes-title">
                          <h2>{row.title}</h2>
                        </div>
                        <p>{row.description}</p>
                        <p className="rd-txt">
                          {" "}
                          Reserve My Spot{" "}
                          <i
                            class="fa fa-angle-double-right"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </>
          ) : (
            <h6>No Data Avalaible</h6>
          )}
        </div>
      </>
    );
  }
}
