import moment from "moment";
import React, { Component } from "react";
import {
  GeCommentDetails,
  GetCourseDetail,
  GetUserLogin,
} from "../../../../services";
import YouTube from "react-youtube";
import { CircularProgress } from "@material-ui/core";

export default class Course_View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      question: "",
      questionList: "",
      courseList: [],
      videoUrl: "",
      isloaded: true,
      currentUrlIdx: 0,
      toggle: "",
      curriculumList: [],
      questionLoaded: true,
    };
    this.handleEnded = this.handleEnded.bind(this);
  }
  handleBack(e) {
    this.props.history.goBack();
  }
  handleToggleButton = (id) => (event) => {
    this.setState({ toggle: id });
  };
  handlechangeQuestion(e) {
    this.setState({ question: e.target.value });
  }
  async getAllQeustionList() {
    this.setState({ isloaded: true });
    //get commentlist
    var url = window.location.href.replace(
      /\/$/,
      ""
    ); /* remove optional end / */
    var lastSeg = url.substr(url.lastIndexOf("/") + 1);

    let list = await GeCommentDetails.getAllCommentByUser(lastSeg);
    if (list) {
      this.setState({ questionList: list, isloaded: false });
    }
    //end
  }
  async componentDidMount() {
    // question list
    this.getAllQeustionList();
    //end
    var url = window.location.href.replace(
      /\/$/,
      ""
    ); /* remove optional end / */
    var lastSeg = url.substr(url.lastIndexOf("/") + 1);

    let id = this.props.location.query;
    if (id) {
      let list = await GetCourseDetail.getCourseById(id);
      if (list) {
        this.setState({ courseList: list });
      }
    } else {
      let list = await GetCourseDetail.getCourseById(lastSeg);
      if (list) {
        this.setState({ courseList: list });
      }
    }
    let curriculumList = await GetCourseDetail.getCourseByCostmerId(
      id || lastSeg
    );
    this.setState({ curriculumList, isloaded: false, questionLoaded: false });
  }
  async handleChangeVidQuestioneo(video) {
    const nextUrlIdx = (this.state.currentUrlIdx + 1) % video.length;
    if (video[nextUrlIdx].type.toString() === "PDF") {
      const link = await GetCourseDetail.getPdfByFileName(
        video[nextUrlIdx].fileName
      );
      video[nextUrlIdx].videoLink = link.url;
    }
    this.setState({ currentUrlIdx: nextUrlIdx });

    this.setState({ videoUrl: video });
  }
  handleEnded(e) {
    const nextUrlIdx =
      (this.state.currentUrlIdx + 1) % this.state.videoUrl.length;
    this.setState({ currentUrlIdx: nextUrlIdx });
  }

  handleSubmitComment = async (event) => {
    this.setState({ questionLoaded: true });

    event.preventDefault();
    var url = window.location.href.replace(
      /\/$/,
      ""
    ); /* remove optional end / */
    var lastSeg = url.substr(url.lastIndexOf("/") + 1);
    let id = this.props.location.query;
    let list = await GeCommentDetails.createCommentByUser(lastSeg, {
      message: this.state.question,
    });
    this.getAllQeustionList();
    this.setState({ question: "", questionLoaded: false });

    // let { user, question } = this.state;
    // let data = { userId: user.userId, courseId: lastSeg, question: question };
    // if (id) {
    //   let list = await GeCommentDetails.createCommentByUser(data);
    //   if (list) {
    //     this.getAllQeustionList();
    //   }
    // } else {
    //   let list1 = await GeCommentDetails.createCommentByUser(data);
    //   if (list1) {
    //     this.getAllQeustionList();
    //   }
    // }
  };
  render() {
    let {
      courseList,
      videoUrl,
      question,
      questionList,
      isloaded,
      curriculumList,
    } = this.state;
    return (
      <div>
        <section className="course-view-wrap">
          <nav className="navbar navbar-light">
            <a href="/" classname="navbar-brand">
              <img
                className="logo_nav"
                src="/assets/images/logo/logo_dark.png"
                alt="logo"
              />
            </a>

            {/* <div class="tab-style2">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="meeting-tab"
                    data-toggle="tab"
                    href="#meeting"
                    role="tab"
                    aria-controls="meeting"
                    aria-selected="true"
                  >
                    Meeting Info
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="video-tab"
                    data-toggle="tab"
                    href="#video"
                    role="tab"
                    aria-controls="video"
                    aria-selected="false"
                  >
                    Watch Course
                  </a>
                </li>
              </ul>
            </div> */}

            <a href="/my-courses" className="btn btn-dark">
              <i className="fa fa-long-arrow-left" aria-hidden="true" /> Go Back
            </a>
          </nav>

          {/* <div class="tab-content shop_info_tab">
            <div
              class="tab-pane fade active show"
              id="meeting"
              role="tabpanel"
              aria-labelledby="meeting-tab"
            >
              <div className="meeting-link-wrap mt-3">
                <div className="container text-center">
                  <div className="page-title">
                    <h1>Meeting Information</h1>
                    <p className="col-sm-6 mx-auto">
                      Please find below the meeting details for live session
                    </p>
                  </div>

                  <div className=" col-sm-8 mx-auto shadow p-3 mb-5 bg-white rounded mt-5">
                    <table class="table table-borderless text-left">
                      <tbody>
                        <tr>
                          <td>
                            <b>Course</b>
                          </td>
                          <td> {courseList.title}</td>
                        </tr>

                        <tr>
                          <td>
                            <b>Zoom Meeting Link</b>
                          </td>
                          <td> {courseList.meetingLink}</td>
                        </tr>

                        <tr>
                          <td>
                            <b>Zoom Meeting Password</b>
                          </td>
                          <td> {courseList.meetingPassword}</td>
                        </tr>

                        <tr>
                          <td>
                            <b>Date</b>
                          </td>
                          <td>
                            {" "}
                            {courseList.dateTime
                              ? moment(courseList.dateTime).format("LL")
                              : ""}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <b>Time</b>
                          </td>
                          <td>
                            {courseList.dateTime
                              ? moment(courseList.dateTime).format("hh:mm:ss a")
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <a href="/my-courses" class="btn btn-default btn-radius mt-4">
                    Go to my courses
                  </a>
                </div>
              </div>
            </div>


            <div
              class="tab-pane fade"
              id="video"
              role="tabpanel"
              aria-labelledby="video-tab"
            >
              {curriculumList.curriculum ? (
                <div id="course-preview">
                  <div className="row">
                    <div className="left-sidebar">
                      <div id="accordion">
                        <div className="row left-sidebar-header">
                          <div className="col-sm-10 d-flex align-items-center">
                            <h6 className="mb-0">
                              {curriculumList.course.title}
                            </h6>
                          </div>
                          <div className="col-sm-2 text-right">
                            <span onClick={this.hideSideBar}>
                              <i className="fas fa-bars" />
                            </span>
                          </div>
                        </div>
                        {curriculumList.curriculum.length > 0
                          ? curriculumList.curriculum.map((row, index) => (
                              <div className="card" key={index}>
                                <a onClick={this.handleToggleButton(row.id)}>
                                  <div className="card-header" id="heading2">
                                    <h5 className="mb-0">{row.title}</h5>
                                  </div>
                                </a>
                                <div
                                  className={
                                    row.id === this.state.toggle
                                      ? "collapse show"
                                      : "collapse"
                                  }
                                >
                                  {row.curriculumSubs.map((prop, index) => (
                                    <ul
                                      className="list-group list-group-flush"
                                      key={prop.id}
                                    >
                                      <li
                                        className="list-group-item"
                                        onClick={() =>
                                          this.handleChangeVidQuestioneo(
                                            row.curriculumSubs
                                          )
                                        }
                                      >
                                        {prop.title}
                                      </li>
                                    </ul>
                                  ))}
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    </div>
                    <div className="right-content">
                      {videoUrl ? (
                        <React.Fragment
                          key={videoUrl[this.state.currentUrlIdx].id}
                        >
                          <div className="video-play">
                            {videoUrl[this.state.currentUrlIdx].type ==
                            "PDF" ? (
                              <embed
                                src={
                                  videoUrl[this.state.currentUrlIdx].videoLink
                                }
                                width="100%"
                                height="450"
                              ></embed>
                            ) : (
                              <>
                                <iframe
                                  id="my-video"
                                  className="video-js"
                                  src={`https://www.youtube.com/embed/${
                                    videoUrl[
                                      this.state.currentUrlIdx
                                    ].videoLink.split("v=")[1]
                                  }`}
                                  width="100%"
                                  height="450"
                                  frameBorder="0"
                                  allow="autoplay; fullscreen"
                                  allowFullScreen
                                ></iframe>
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}

                      <div className="commentbox_wrapper cw1">
                        <h6>Questions/Comments :</h6>
                        <form>
                          <textarea
                            class="form-control"
                            rows="2"
                            placeholder="Enter your message here"
                            value={question}
                            onChange={(e) => this.handlechangeQuestion(e)}
                          ></textarea>
                          <button
                            type="submit"
                            class="btn btn-default mt-2 "
                            onClick={this.handleSubmitComment}
                          >
                            Submit
                          </button>
                        </form>

                        {isloaded
                          ? questionList.map((row, index) => {
                              return (
                                <div className="commnt-answer" key={index}>
                                  <div className="incoming_msg">
                                    <div className="incoming_msg_img">
                                      {" "}
                                      <img
                                        src="https://ptetutorials.com/images/user-profile.png"
                                        alt="sunil"
                                      />{" "}
                                    </div>
                                    <div className="received_msg">
                                      <div className="received_withd_msg">
                                        <p>{row.question} ?</p>
                                        <span className="time_date">
                                          {moment(row.commentDate).format("LL")}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="outgoing_msg"
                                    style={
                                      row.answer
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    <div className="sent_msg">
                                      <p>{row.answer}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>

                    <div className="course-suggest">
                      <div className="yg-classes-wrap">
                        <a href="/course-details/vedic-astrology/4">
                          <img
                            src="https://s3.us-east-1.amazonaws.com/innercordbucket/1606120942735-Vedic_Astrology-I.png"
                            className="img-fluid"
                            alt
                          />
                        </a>

                        <div className="yg-classes-bottom-content">
                          <div className="yg-classes-title">
                            <h2>
                              <a href="/course-details/vedic-astrology/4">
                                Vedic Astrology
                              </a>
                            </h2>
                          </div>
                          <p>
                            Vedic Astrology finds its roots in the Vedas and
                            dates back to 3000BC! This noble science practiced
                            by many bases its guidance on the celestial bodies
                            found at the time of birth, place and date. It is
                            the oldest system of astrology known to mankind and
                            helps you reflect on your journey of life. In simple
                            words, Astrology is an ancient Vedic Science which
                            works like a GPS. It’s a map of life. It shows the
                            potential of a person’s life, helps us to learn
                            things about our personality, our talents and our
                            possibilities! Having knowledge about this gives
                            direction in life, helps us to know the cause behind
                            the happenings and also has the potential to predict
                            possibilities in the future and help us to improve
                            our actions through remedies.{" "}
                          </p>
                        </div>
                      </div>

                      <div className="yg-classes-wrap">
                        <a href="/course-details/ayurvedic-gut-healing/7">
                          <img
                            src="https://s3.us-east-1.amazonaws.com/innercordbucket/1606120969299-Vedic_Gut_Healing.png"
                            className="img-fluid"
                            alt
                          />
                        </a>
                        <div className="yg-classes-bottom-content">
                          <div className="yg-classes-title">
                            <h2>
                              <a href="/course-details/ayurvedic-gut-healing/7">
                                Ayurvedic Gut Healing
                              </a>
                            </h2>
                          </div>
                          <p>
                            Explore the Ayurveda way of living and understand
                            how to keep your gut healthy as we unravel the
                            manifold benefits of Ayurveda with our informative
                            workshop. It is true that Ayurveda can improve your
                            quality of living, right from your breathing,
                            immunity, digestive system to your mental state and
                            we strive to make you understand just that!{" "}
                          </p>
                        </div>
                      </div>

                      <div className="yg-classes-wrap">
                        <a href="/course-details-free/vedic-astrology-free-masterclass/13">
                          <img
                            src="https://s3.us-east-1.amazonaws.com/innercordbucket/1606120905237-FreeMaster_vedic.png"
                            className="img-fluid animate-box fadeInUp animated"
                            data-animate-effect="fadeInUp"
                            alt
                          />
                        </a>
                        <div
                          className="yg-classes-bottom-content animate-box fadeInUp animated"
                          data-animate-effect="fadeInUp"
                        >
                          <div className="yg-classes-title">
                            <h2>
                              <a href="/course-details-free/vedic-astrology-free-masterclass/13">
                                Vedic Astrology Free Masterclass
                              </a>
                            </h2>{" "}
                          </div>
                          <p>
                            Find the planetary roadmap to your life for free!
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="commentbox_wrapper cw2">
                      <h6>Questions/Comments :</h6>
                      <form>
                        <textarea
                          class="form-control"
                          rows="2"
                          placeholder="Enter your message here"
                          value={question}
                          onChange={(e) => this.handlechangeQuestion(e)}
                        ></textarea>
                        <button
                          type="submit"
                          class="btn btn-default mt-2 "
                          onClick={this.handleSubmitComment}
                        >
                          Submit
                        </button>
                      </form>

                      {isloaded
                        ? questionList.map((row, index) => {
                            return (
                              <div className="commnt-answer" key={index}>
                                <div className="incoming_msg">
                                  <div className="incoming_msg_img">
                                    {" "}
                                    <img
                                      src="https://ptetutorials.com/images/user-profile.png"
                                      alt="sunil"
                                    />{" "}
                                  </div>
                                  <div className="received_msg">
                                    <div className="received_withd_msg">
                                      <p>{row.question} ?</p>
                                      <span className="time_date">
                                        {moment(row.commentDate).format("LL")}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="outgoing_msg"
                                  style={
                                    row.answer
                                      ? { display: "block" }
                                      : { display: "none" }
                                  }
                                >
                                  <div className="sent_msg">
                                    <p>{row.answer}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>

                  </div>
                </div>
              ) : (
                <div class="meeting-link-wrap mt-3">
                  <div className="container text-center">
                    <div class="page-title">
                      <h1>Watch Course</h1>
                      <p class="col-sm-6 mx-auto">No video uploaded yet !</p>
                    </div>
                  </div>
                </div>
              )}
            </div>

          </div> */}

          {isloaded ? (
            <div
              className="d-inline-flex align-items-center w-100 justify-content-center"
              style={{ minHeight: "90vh" }}
            >
              <CircularProgress />
            </div>
          ) : curriculumList.curriculum ? (
            <div id="course-preview">
              <div className="row">
                <div className="left-sidebar">
                  <div id="accordion">
                    <div className="row left-sidebar-header">
                      <div className="col-sm-10 d-flex align-items-center">
                        <h6 className="mb-0">{curriculumList.course.title}</h6>
                      </div>
                      <div className="col-sm-2 text-right">
                        <span onClick={this.hideSideBar}>
                          <i className="fas fa-bars" />
                        </span>
                      </div>
                    </div>
                    {curriculumList.curriculum.length > 0
                      ? curriculumList.curriculum.map((row, index) => (
                          <div className="card" key={index}>
                            <a onClick={this.handleToggleButton(row.id)}>
                              <div className="card-header" id="heading2">
                                <h5 className="mb-0">{row.title}</h5>
                              </div>
                            </a>
                            <div
                              className={
                                row.id === this.state.toggle
                                  ? "collapse show"
                                  : "collapse"
                              }
                            >
                              {row.curriculumSubs.map((prop, index) => (
                                <ul
                                  className="list-group list-group-flush"
                                  key={prop.id}
                                >
                                  <li
                                    className="list-group-item"
                                    onClick={() =>
                                      this.handleChangeVidQuestioneo(
                                        row.curriculumSubs
                                      )
                                    }
                                  >
                                    {prop.title}
                                  </li>
                                </ul>
                              ))}
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
                <div className="right-content">
                  {videoUrl ? (
                    <React.Fragment key={videoUrl[this.state.currentUrlIdx].id}>
                      <div className="video-play">
                        {videoUrl[this.state.currentUrlIdx].type == "PDF" ? (
                          <embed
                            src={videoUrl[this.state.currentUrlIdx].videoLink}
                            width="100%"
                            height="450"
                          ></embed>
                        ) : (
                          <>
                            <iframe
                              id="my-video"
                              className="video-js"
                              src={`https://www.youtube.com/embed/${
                                videoUrl[
                                  this.state.currentUrlIdx
                                ].videoLink.split("v=")[1]
                              }`}
                              width="100%"
                              height="450"
                              frameBorder="0"
                              allow="autoplay; fullscreen"
                              allowFullScreen
                            ></iframe>
                          </>
                        )}
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}

                  <div
                    style={{
                      padding: "2em",
                      maxHeight: "90vh",
                      overflow: "scroll",
                    }}
                  >
                    <h6>Questions/Comments :</h6>
                    <form>
                      <textarea
                        class="form-control"
                        rows="2"
                        placeholder="Enter your message here"
                        value={question}
                        onChange={(e) => this.handlechangeQuestion(e)}
                      ></textarea>
                      <button
                        type="submit"
                        class="btn btn-default mt-2 "
                        onClick={this.handleSubmitComment}
                      >
                        Submit
                      </button>
                    </form>

                    {!this.state.questionLoaded
                      ? questionList.map((row, index) => {
                          return (
                            <div className="commnt-answer" key={index}>
                              <div className="incoming_msg">
                                <div className="incoming_msg_img">
                                  {" "}
                                  <img
                                    src="https://ptetutorials.com/images/user-profile.png"
                                    alt="sunil"
                                  />{" "}
                                </div>
                                <div className="received_msg">
                                  <div className="received_withd_msg">
                                    <p>{row.comment} ?</p>
                                    <div className="d-inline-flex w-100 justify-content-between align-items-center">
                                      <span className="time_date">
                                        {moment(row.createdAt).format("LL")}
                                      </span>
                                      <span className="time_date">{`${row?.user?.email}`}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Admin Comment */}
                              {/* <div
                                className="outgoing_msg"
                                style={{ display: "block" }}
                              >
                                <div className="sent_msg">
                                  <p>{row.comment}</p>
                                </div>
                              </div> */}
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>

                <div className="course-suggest">
                  <div className="yg-classes-wrap">
                    <a href="/course-details/vedic-astrology/4">
                      <img
                        src="https://s3.us-east-1.amazonaws.com/innercordbucket/1606120942735-Vedic_Astrology-I.png"
                        className="img-fluid"
                        alt
                      />
                    </a>

                    <div className="yg-classes-bottom-content">
                      <div className="yg-classes-title">
                        <h2>
                          <a href="/course-details/vedic-astrology/4">
                            Vedic Astrology
                          </a>
                        </h2>
                      </div>
                      <p>
                        Vedic Astrology finds its roots in the Vedas and dates
                        back to 3000BC! This noble science practiced by many
                        bases its guidance on the celestial bodies found at the
                        time of birth, place and date. It is the oldest system
                        of astrology known to mankind and helps you reflect on
                        your journey of life. In simple words, Astrology is an
                        ancient Vedic Science which works like a GPS. It’s a map
                        of life. It shows the potential of a person’s life,
                        helps us to learn things about our personality, our
                        talents and our possibilities! Having knowledge about
                        this gives direction in life, helps us to know the cause
                        behind the happenings and also has the potential to
                        predict possibilities in the future and help us to
                        improve our actions through remedies.{" "}
                      </p>
                    </div>
                  </div>

                  <div className="yg-classes-wrap">
                    <a href="/course-details/ayurvedic-gut-healing/7">
                      <img
                        src="https://s3.us-east-1.amazonaws.com/innercordbucket/1606120969299-Vedic_Gut_Healing.png"
                        className="img-fluid"
                        alt
                      />
                    </a>
                    <div className="yg-classes-bottom-content">
                      <div className="yg-classes-title">
                        <h2>
                          <a href="/course-details/ayurvedic-gut-healing/7">
                            Ayurvedic Gut Healing
                          </a>
                        </h2>
                      </div>
                      <p>
                        Explore the Ayurveda way of living and understand how to
                        keep your gut healthy as we unravel the manifold
                        benefits of Ayurveda with our informative workshop. It
                        is true that Ayurveda can improve your quality of
                        living, right from your breathing, immunity, digestive
                        system to your mental state and we strive to make you
                        understand just that!{" "}
                      </p>
                    </div>
                  </div>

                  <div className="yg-classes-wrap">
                    <a href="/course-details-free/vedic-astrology-free-masterclass/13">
                      <img
                        src="https://s3.us-east-1.amazonaws.com/innercordbucket/1606120905237-FreeMaster_vedic.png"
                        className="img-fluid animate-box fadeInUp animated"
                        data-animate-effect="fadeInUp"
                        alt
                      />
                    </a>
                    <div
                      className="yg-classes-bottom-content animate-box fadeInUp animated"
                      data-animate-effect="fadeInUp"
                    >
                      <div className="yg-classes-title">
                        <h2>
                          <a href="/course-details-free/vedic-astrology-free-masterclass/13">
                            Vedic Astrology Free Masterclass
                          </a>
                        </h2>{" "}
                      </div>
                      <p>Find the planetary roadmap to your life for free!</p>
                    </div>
                  </div>

                  {/* <a href="/" class="btn btn-default btn-radius mt-2 w-100">View More</a> */}
                </div>

                {/*  */}

                {/* Comment Box */}

                <div className="commentbox_wrapper cw2">
                  <h6>Questions/Comments :</h6>
                  <form>
                    <textarea
                      class="form-control"
                      rows="2"
                      placeholder="Enter your message here"
                      value={question}
                      onChange={(e) => this.handlechangeQuestion(e)}
                    ></textarea>
                    <button
                      type="submit"
                      class="btn btn-default mt-2 "
                      onClick={this.handleSubmitComment}
                    >
                      Submit
                    </button>
                  </form>

                  {isloaded
                    ? questionList.map((row, index) => {
                        return (
                          <div className="commnt-answer" key={index}>
                            {/* User Comment */}
                            <div className="incoming_msg">
                              <div className="incoming_msg_img">
                                {" "}
                                <img
                                  src="https://ptetutorials.com/images/user-profile.png"
                                  alt="sunil"
                                />{" "}
                              </div>
                              <div className="received_msg">
                                <div className="received_withd_msg">
                                  <p>{row.question} ?</p>
                                  <span className="time_date">
                                    {moment(row.commentDate).format("LL")}
                                  </span>
                                </div>
                              </div>
                            </div>

                            {/* Admin Comment */}
                            <div
                              className="outgoing_msg"
                              style={
                                row.answer
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >
                              <div className="sent_msg">
                                <p>{row.answer}</p>
                                {/* <span className="time_date">{moment(row.createdAt).format('LL')}</span>  */}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>

                {/* Comment Box */}
              </div>
            </div>
          ) : (
            <div class="meeting-link-wrap mt-3">
              <div className="container text-center">
                <div class="page-title">
                  <h1>Watch Course</h1>
                  <p class="col-sm-6 mx-auto">No video uploaded yet !</p>
                </div>
              </div>
            </div>
          )}

          {/* --------------------- Course View Ends --------------------------*/}
        </section>
      </div>
    );
  }
}
