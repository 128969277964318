import React, { Component } from 'react'

export default class Successfully extends Component {
    render() {
        return (
            <div>
               


               {/* --------------------- Contact Page Starts --------------------------*/}

                    {/* START SECTION BREADCRUMB */}
                    <section className="breadcrumb-bg mt-5">
                    <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12 text-center">
                        <div className="page-title">

                        <i class="fa fa-times cross-circle" aria-hidden="true"></i>

                            <h1>Transaction Failed</h1>
                            <p className="col-sm-6 mx-auto">Oops! Your payment has been declined. Please try again later</p>
                        </div>
                        
                        </div>
                    </div>
                    </div>
                </section>
                {/* END SECTION BREADCRUMB */}


                {/* START SECTION CONTACT */}
                <section  className="pt-5">
                    <div className="container">
                    <div className="row">
                      
                    </div>
                    </div>
                </section>
                {/* END SECTION CONTACT */}
              

                {/* --------------------- Contact Page Starts --------------------------*/}


  
            </div>
        )
    }
}
